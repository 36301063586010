import {AppActionTypes} from '../actions/action.types'

const initialState: AccountState = {
  allbillingaddress: [],
  withdrawalMethods: [],
  paymentuphold: '',
  paymentadvcash: '',
  paymentusdc: '',
  resultBilling: '',
  getReadAllBilling: '',
  resultWithdrawal: '',
  newteamemail: 0,
  newteamphone: 0,
  newrankemail: 0,
  newrankphone: 0,
  expirefiveemail: 0,
  expirefivephone: 0,
  expireemail: 0,
  expirephone: 0,
  renewalemail: 0,
  renewalphone: 0,
  receiveemail: 0,
  receivephone: 0,
  reminderemail: 0,
  reminderphone: 0,
  resultNotification: '',
  logsessions: [],
  totallogsessions: 0,
  resultLogSession: '',
  loghistory: [],
  totalloghistory: 0,
  resultLogHistory: '',
  resultdownloaduserinfor: '',
  languages: [],
}

export interface AccountState {
  withdrawalMethods: any
  allbillingaddress: Array<any>
  paymentuphold: string
  paymentadvcash: string
  paymentusdc: string
  resultBilling: string
  getReadAllBilling: string
  resultWithdrawal: string
  newteamemail: number
  newteamphone: number
  newrankemail: number
  newrankphone: number
  expirefiveemail: number
  expirefivephone: number
  expireemail: number
  expirephone: number
  renewalemail: number
  renewalphone: number
  receiveemail: number
  receivephone: number
  reminderemail: number
  reminderphone: number
  resultNotification: string
  logsessions: Array<any>
  totallogsessions: number
  resultLogSession: string
  loghistory: Array<any>
  totalloghistory: number
  resultLogHistory: string
  resultdownloaduserinfor: string
  languages: Array<any>
}

const accountReducer = (
  state: AccountState = initialState,
  action: AppActionTypes
): AccountState => {
  switch (action.type) {
    case 'GET_ALL_BILLING_ADDRESS':
      return {
        ...state,
        allbillingaddress: action.payload.allBillingData,
        getReadAllBilling: action.payload.result,
      }

    case 'GET_ALL_BILLING_ADDRESS_ERROR':
      return {
        ...state,
        getReadAllBilling: action.payload.result,
      }

    case 'ADD_BILLING_ADDRESS':
      return {
        ...state,
        allbillingaddress: action.payload.allBillingData,
        resultBilling: action.payload.result,
      }

    case 'ADD_BILLING_ADDRESS_ERROR':
      return {
        ...state,
        resultBilling: action.payload.result,
      }

    case 'INIT_RESULT_BILLING':
      return {
        ...state,
        resultBilling: action.payload.result,
      }

    case 'EDIT_BILLING_ADDRESS':
      return {
        ...state,
        allbillingaddress: action.payload.allBillingData,
        resultBilling: action.payload.result,
      }

    case 'EDIT_BILLING_ADDRESS_ERROR':
      return {
        ...state,
        resultBilling: action.payload.result,
      }

    case 'DELETE_BILLING_ADDRESS':
      return {
        ...state,
        allbillingaddress: action.payload.allBillingData,
        resultBilling: action.payload.result,
      }

    case 'DELETE_BILLING_ADDRESS_ERROR':
      return {
        ...state,
        resultBilling: action.payload.result,
      }

    case 'SET_WITHDRAWAL':
      return {
        ...state,
        withdrawalMethods: action.payload.withdrawalMethods,
        // paymentuphold: action.payload.paymentuphold,
        // paymentadvcash: action.payload.paymentadvcash,
        // paymentusdc: action.payload.paymentusdc,
        resultWithdrawal: action.payload.result,
      }

    case 'SET_WITHDRAWAL_ERROR':
      return {
        ...state,
        resultWithdrawal: action.payload.result,
      }

    case 'INIT_RESULT_WITHDRAWAL':
      return {
        ...state,
        resultWithdrawal: action.payload.result,
      }

    case 'SET_NOTIFICATION':
      return {
        ...state,
        newteamemail: action.payload.newTeamEmail,
        newteamphone: action.payload.newTeamPhone,
        newrankemail: action.payload.newRankEmail,
        newrankphone: action.payload.newRankPhone,
        expirefiveemail: action.payload.expireFiveEmail,
        expirefivephone: action.payload.expireFivePhone,
        expireemail: action.payload.expireEmail,
        expirephone: action.payload.expirePhone,
        renewalemail: action.payload.renewalEmail,
        renewalphone: action.payload.renewalPhone,
        receiveemail: action.payload.receiveEmail,
        receivephone: action.payload.receivePhone,
        reminderemail: action.payload.renimderEmail,
        reminderphone: action.payload.renimderPhone,
        resultNotification: action.payload.result,
      }

    case 'SET_NOTIFICATION_ERROR':
      return {
        ...state,
        resultNotification: action.payload.result,
      }

    case 'INIT_RESULT_NOTIFICATION':
      return {
        ...state,
        resultNotification: action.payload.result,
      }

    case 'GET_LOG_SESSIONS':
      return {
        ...state,
        logsessions: action.payload.logsessions,
        totallogsessions: action.payload.totallogsessions,
        resultLogSession: action.payload.result,
      }

    case 'GET_LOG_SESSIONS_ERROR':
      return {
        ...state,
        resultLogSession: action.payload.result,
      }

    case 'GET_LOG_HISTORY':
      return {
        ...state,
        loghistory: action.payload.loghistory,
        totalloghistory: action.payload.totalloghistory,
        resultLogHistory: action.payload.result,
      }

    case 'GET_LOG_HISTORY_ERROR':
      return {
        ...state,
        resultLogHistory: action.payload.result,
      }

    case 'DOWNLOAD_USER_INFORMATION':
      return {
        ...state,
        resultdownloaduserinfor: action.payload.result,
      }

    case 'DOWNLOAD_USER_INFORMATION_ERROR':
      return {
        ...state,
        resultdownloaduserinfor: action.payload.result,
      }

    case 'SAVE_LANGUAGE':
      return {
        ...state,
        languages: action.payload.result,
      }

    default:
      return state
  }
}

export default accountReducer
