import axios from 'axios'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {BACKEND_API_V2} from '../../../../config/config'
import {useTranslation} from 'react-i18next'

export type CouponObject = {
  coupon: string
  plan_id: number
}

export default function CouponComponent({
  coupons,
  setCoupons,
  buyAsCoupon = false,
  setBuyAsCoupon = () => {},
  sign_up = false,
}: {
  coupons: CouponObject[]
  setCoupons: Dispatch<SetStateAction<CouponObject[]>>
  sign_up?: boolean
  buyAsCoupon?: boolean
  setBuyAsCoupon?: Dispatch<SetStateAction<boolean>>
}) {
  const {t} = useTranslation()

  const [couponCode, setCouponCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  const handleBuyAsGift = () => {
    setBuyAsCoupon((v) => !v)
    setCouponCode('')
    setErrorMessage('')
    setSuccessMessage('')
    setCoupons([])
  }

  const handleRedeem = async () => {
    setLoading(true)
    setErrorMessage('')
    setSuccessMessage('')
    try {
      if (!couponCode) {
        setErrorMessage(t('WEBSHOP_COUPON_CONTENT_1'))
        setLoading(false)
        return
      }
      if (coupons.find((coupon) => coupon.coupon === couponCode)) {
        setErrorMessage(t('WEBSHOP_COUPON_CONTENT_2'))
        setLoading(false)
        return
      }
      const response = await axios
        .get<{coupon: string; plan_id: number} | {error: string}>(
          `${BACKEND_API_V2}/webshop/coupon?c=${couponCode}`
        )
        .then((res) => res.data)
        .catch((error) => {
          return error.response.data
        })
      if (response.error) {
        setErrorMessage(response.error)
        return
      }
      const {coupon, plan_id} = response
      setCoupons((v) => [...v, {coupon, plan_id}])
      // If successful
      setSuccessMessage(t('WEBSHOP_COUPON_CONTENT_3'))
    } catch (error) {
      // If there's an error
      setErrorMessage(t('WEBSHOP_COUPON_CONTENT_4'))
    } finally {
      setLoading(false)
      setCouponCode('')
    }
  }

  return (
    <div className='coupon-component'>
      <input
        type='text'
        className={`form-control ${errorMessage ? 'is-invalid' : ''} ${
          successMessage ? 'is-valid' : ''
        }`}
        placeholder={t('WEBSHOP_COUPON_CONTENT_5')}
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value)}
        disabled={buyAsCoupon}
      />
      {errorMessage && <div className='invalid-feedback'>{errorMessage}</div>}
      {successMessage && <div className='valid-feedback'>{successMessage}</div>}
      {!errorMessage && !successMessage && <div style={{height: '17px', marginTop: '6px'}} />}
      <div>
        {!sign_up ? (
          <button className='btn btn-secondary mt-2' onClick={handleBuyAsGift}>
            {buyAsCoupon ? t('WEBSHOP_COUPON_CONTENT_6') : t('WEBSHOP_COUPON_CONTENT_7')}
          </button>
        ) : (
          <div />
        )}
        <button
          className='btn btn-primary mt-2'
          onClick={handleRedeem}
          disabled={loading || buyAsCoupon}
        >
          {loading ? `${t('WEBSHOP_COUPON_CONTENT_8')}...` : t('WEBSHOP_COUPON_CONTENT_9')}
        </button>
      </div>
      <style>{`
      .coupon-component {
          max-width: 300px;
          margin-top: 10px;
          margin-left: auto;
          flex-direction: column;
          display: flex;
          justify-content: start;
        }
      .coupon-component div {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        }
    .coupon-component div button, .coupon-component input {
        font-size: 12px;
        font-weight: 600;
        }
      `}</style>
    </div>
  )
}
