import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import './App.scss'
import '../i18n'
import {Routes} from './routing/Routes'
import AuthInit from './modules/auth/redux/AuthInit'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import SubscriptionsContext from '../context/SubscriptionsContext'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <AuthInit>
            <SubscriptionsContext>
              <Routes />
            </SubscriptionsContext>
          </AuthInit>
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
