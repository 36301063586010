import axios from 'axios'

import {
  GetBasePlans,
  GetBasePlansError,
  AddBasePlan,
  AddBasePlanError,
  EditBasePlan,
  EditBasePlanError,
  DeleteBasePlan,
  DeleteBasePlanError,
  GetBaseServices,
  GetBaseServicesError,
  GetAddonPlans,
  GetAddonPlansError,
  AddAddonPlan,
  AddAddonPlanError,
  EditAddonPlan,
  EditAddonPlanError,
  DeleteAddonPlan,
  DeleteAddonPlanError,
  GetAffiliatePlans,
  GetAffiliatePlansError,
  AddAffiliatePlan,
  AddAffiliatePlanError,
  EditAffiliatePlan,
  EditAffiliatePlanError,
  DeleteAffiliatePlan,
  DeleteAffiliatePlanError,
  SetCurrentBasePlanPeriod,
  SetWithoutPlanStatus,
  SetInitShoppingcart,
  AddShoppingBasePlan,
  AddShoppingBaseAndAddonPlan,
  AddShoppingAddonPlan,
  AddShoppingAddonAddonPlan,
  AddShoppingAffiliatePlan,
  SetUserInformation,
  SetSponserUserName,
  GetSubscriptions,
  GetSubscriptionsError,
  GetInvoices,
  GetInvoicesError,
  GetPayments,
  GetPaymentsError,
  GetRefunds,
  GetRefundsError,
  GetStaffMembers,
  GetStaffMembersError,
  FilterStaffMembers,
  FilterStaffMembersError,
  DeleteStaffMember,
  DeleteStaffMemberError,
  DeleteStaffMembers,
  DeleteStaffMembersError,
  ChangeStaffMemberEditStatus,
  GetTeamUserLists,
  GetTeamUserListsError,
  GetTeamSubscriptions,
  GetTeamSubscriptionsError,
  GetTeamInvoices,
  GetTeamInvoicesError,
  GetTeamPayments,
  GetTeamPaymentsError,
  GetTeamRefunds,
  GetTeamRefundsError,
} from './action.types'
import {Dispatch} from 'redux'
import swal from 'sweetalert'
import {BACKEND_API_URL, removeAllSession} from '../../../config/config'

// Get Base Plan  Information
export const getBasePlans =
  (page: number) => async (dispatch: Dispatch<GetBasePlans | GetBasePlansError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/getBaseProducts`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_BASEPLANS',
          payload: {
            basePlans: token.results,
            totalBasePlans: token.totalcount,
            result: result,
          },
        } as GetBasePlans)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_BASEPLANS_ERROR',
        payload: {
          result: 'Get BasePlan process failed',
        },
      } as GetBasePlansError)
    }
  }

// Add Base Plan  Information
export const addBasePlan =
  (data: object) => async (dispatch: Dispatch<AddBasePlan | AddBasePlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const response = await axios.post<any>(`${BACKEND_API_URL}/addBaseProduct`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Base Plan',
          text: 'Base plan has been successfully added.',
          icon: 'success',
        })

        dispatch({
          type: 'ADD_BASEPLAN',
          payload: {
            basePlans: token.result,
            totalBasePlans: token.totalcount,
            result: result,
          },
        } as AddBasePlan)
      } else {
        swal({
          title: 'Base Plan',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Base Plan',
        text: 'Add Base Plan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'ADD_BASEPLAN_ERROR',
        payload: {
          result: 'Add BasePlan process failed',
        },
      } as AddBasePlanError)
    }
  }

// Edit Base Plan  Information
export const editBasePlan =
  (data: object, permissionVal: number, viewStatus: number) =>
  async (dispatch: Dispatch<EditBasePlan | EditBasePlanError>) => {
    try {
      let apiURL = 'editBaseProduct'
      let tokenValue = localStorage.getItem('token')

      if (permissionVal === 1 && viewStatus === 0) apiURL = 'editBaseProduct'
      else if (permissionVal === 1 && viewStatus === 1) apiURL = 'editBaseDescription'
      else if (permissionVal === 1 && viewStatus === 2) apiURL = 'editBaseShopPricing'

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiURL}`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Base Plan',
          text: 'Base plan has been successfully changed.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Base Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'EDIT_BASEPLAN',
        payload: {
          basePlans: token.result,
          totalBasePlans: token.totalcount,
          result: result,
        },
      } as EditBasePlan)
    } catch (error: any) {
      swal({
        title: 'Base Plan',
        text: 'Edit BasePlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'EDIT_BASEPLAN_ERROR',
        payload: {
          result: 'Edit BasePlan process failed',
        },
      } as EditBasePlanError)
    }
  }

// Delete Base Plan  Information
export const deleteBasePlan =
  (id: any) => async (dispatch: Dispatch<DeleteBasePlan | DeleteBasePlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        id: id,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/deleteBaseProduct`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Base Plan',
          text: 'Base plan has been successfully deleted.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Base Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'DELETE_BASEPLAN',
        payload: {
          basePlans: token.result,
          totalBasePlans: token.totalcount,
          result: result,
        },
      } as DeleteBasePlan)
    } catch (error: any) {
      swal({
        title: 'Base Plan',
        text: 'Delete BasePlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'DELETE_BASEPLAN_ERROR',
        payload: {
          result: 'Delete BasePlan process failed',
        },
      } as DeleteBasePlanError)
    }
  }

// Get Products List Information
export const getBaseServices =
  () => async (dispatch: Dispatch<GetBaseServices | GetBaseServicesError>) => {
    try {
      let tokenValue = localStorage.getItem('token')

      const response = await axios.post<any>(
        `${BACKEND_API_URL}/getBaseServices`,
        {},
        {
          headers: {'x-access-token': `${tokenValue}`},
        }
      )
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        dispatch({
          type: 'GET_BASESERVICES',
          payload: {
            baseServices: token.split(';'),
            result: result,
          },
        } as GetBaseServices)
      } else {
        swal({
          title: 'Get Services',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Get Services',
        text: 'Get BaseServices process failed',
        icon: 'error',
      })

      dispatch({
        type: 'GET_BASESERVICES_ERROR',
        payload: {
          result: 'Get BaseServices process failed',
        },
      } as GetBaseServicesError)
    }
  }

// Get Addon Plan  Information
export const getAddonPlans =
  (page: number) => async (dispatch: Dispatch<GetAddonPlans | GetAddonPlansError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/getAddonProducts `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_ADDONPLANS',
          payload: {
            addonPlans: token.results,
            totalAddonPlans: token.totalcount,
            result: result,
          },
        } as GetAddonPlans)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_ADDONPLANS_ERROR',
        payload: {
          result: 'Get AddonPlan process failed',
        },
      } as GetAddonPlansError)
    }
  }

// Add Addon Plan  Information
export const addAddonPlan =
  (data: object) => async (dispatch: Dispatch<AddAddonPlan | AddAddonPlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const response = await axios.post<any>(`${BACKEND_API_URL}/addAddonProduct`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Addon Plan',
          text: 'Addon plan has been successfully added.',
          icon: 'success',
        })

        dispatch({
          type: 'ADD_ADDONPLAN',
          payload: {
            addonPlans: token.result,
            totalAddonPlans: token.totalcount,
            result: result,
          },
        } as AddAddonPlan)
      } else {
        swal({
          title: 'Addon Plan',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Addon Plan',
        text: 'Add Addon Plan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'ADD_ADDONPLAN_ERROR',
        payload: {
          result: 'Add AddonPlan process failed',
        },
      } as AddAddonPlanError)
    }
  }

// Edit Addon Plan  Information
export const editAddonPlan =
  (data: object, permissionVal: number, viewStatus: number) =>
  async (dispatch: Dispatch<EditAddonPlan | EditAddonPlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      let apiURL = 'editAddonProduct'

      if (permissionVal === 1 && viewStatus === 0) apiURL = 'editAddonProduct'
      else if (permissionVal === 1 && viewStatus === 1) apiURL = 'editAddonDescription'
      else if (permissionVal === 1 && viewStatus === 2) apiURL = 'editAddonShopPricing'

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiURL}`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Addon Plan',
          text: 'Addon plan has been successfully changed.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Addon Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'EDIT_ADDONPLAN',
        payload: {
          addonPlans: token.result,
          totalAddonPlans: token.totalcount,
          result: result,
        },
      } as EditAddonPlan)
    } catch (error: any) {
      swal({
        title: 'Addon Plan',
        text: 'Edit AddonPlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'EDIT_ADDONPLAN_ERROR',
        payload: {
          result: 'Edit AddonPlan process failed',
        },
      } as EditAddonPlanError)
    }
  }

// Delete Addon Plan  Information
export const deleteAddonPlan =
  (id: any) => async (dispatch: Dispatch<DeleteAddonPlan | DeleteAddonPlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        id: id,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/deleteAddonProduct`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Addon Plan',
          text: 'Addon plan has been successfully deleted.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Addon Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'DELETE_ADDONPLAN',
        payload: {
          addonPlans: token.result,
          totalAddonPlans: token.totalcount,
          result: result,
        },
      } as DeleteAddonPlan)
    } catch (error: any) {
      swal({
        title: 'Addon Plan',
        text: 'Delete AddonPlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'DELETE_ADDONPLAN_ERROR',
        payload: {
          result: 'Delete AddonPlan process failed',
        },
      } as DeleteAddonPlanError)
    }
  }

// Get Affiliate Plan  Information
export const getAffiliatePlans =
  (page: number) => async (dispatch: Dispatch<GetAffiliatePlans | GetAffiliatePlansError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/getAffiliateProducts  `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else
        dispatch({
          type: 'GET_AFFILIATEPLANS',
          payload: {
            affiliatePlans: token.results,
            totalAffiliatePlans: token.totalcount,
            result: result,
          },
        } as GetAffiliatePlans)
    } catch (error: any) {
      dispatch({
        type: 'GET_AFFILIATEPLANS_ERROR',
        payload: {
          result: 'Get AffiliatePlan process failed',
        },
      } as GetAffiliatePlansError)
    }
  }

// Add Affiliate Plan  Information
export const addAffiliatePlan =
  (data: object) => async (dispatch: Dispatch<AddAffiliatePlan | AddAffiliatePlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const response = await axios.post<any>(`${BACKEND_API_URL}/addAffiliateProduct`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Affiliate Plan',
          text: 'Affiliate plan has been successfully added.',
          icon: 'success',
        })

        dispatch({
          type: 'ADD_AFFILIATEPLAN',
          payload: {
            affiliatePlans: token.result,
            totalAffiliatePlans: token.totalcount,
            result: result,
          },
        } as AddAffiliatePlan)
      } else {
        swal({
          title: 'Affiliate Plan',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Affiliate Plan',
        text: 'Add Affiliate Plan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'ADD_AFFILIATEPLAN_ERROR',
        payload: {
          result: 'Add AffiliatePlan process failed',
        },
      } as AddAffiliatePlanError)
    }
  }

// Edit Affiliate Plan  Information
export const editAffiliatePlan =
  (data: object, permissionVal: number, viewStatus: number) =>
  async (dispatch: Dispatch<EditAffiliatePlan | EditAffiliatePlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      let apiURL = 'editAffiliateProduct'

      if (permissionVal === 1 && viewStatus === 0) apiURL = 'editAffiliateProduct'
      else if (permissionVal === 1 && viewStatus === 1) apiURL = 'editAffiliateDescription'
      else if (permissionVal === 1 && viewStatus === 2) apiURL = 'editAffiliateShopPricing'

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiURL}`, data, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Affiliate Plan',
          text: 'Affiliate plan has been successfully changed.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Affiliate Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'EDIT_AFFILIATEPLAN',
        payload: {
          affiliatePlans: token.result,
          totalAffiliatePlans: token.totalcount,
          result: result,
        },
      } as EditAffiliatePlan)
    } catch (error: any) {
      swal({
        title: 'Affiliate Plan',
        text: 'Edit AffiliatePlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'EDIT_AFFILIATEPLAN_ERROR',
        payload: {
          result: 'Edit AffiliatePlan process failed',
        },
      } as EditAffiliatePlanError)
    }
  }

// Delete Affiliate Plan  Information
export const deleteAffiliatePlan =
  (id: any) => async (dispatch: Dispatch<DeleteAffiliatePlan | DeleteAffiliatePlanError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        id: id,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/deleteAffiliateProduct`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Affiliate Plan',
          text: 'Affiliate plan has been successfully deleted.',
          icon: 'success',
        })
      } else {
        swal({
          title: 'Affiliate Plan',
          text: result,
          icon: 'warning',
        })
      }
      dispatch({
        type: 'DELETE_AFFILIATEPLAN',
        payload: {
          affiliatePlans: token.result,
          totalAffiliatePlans: token.totalcount,
          result: result,
        },
      } as DeleteAffiliatePlan)
    } catch (error: any) {
      swal({
        title: 'Affiliate Plan',
        text: 'Delete AffiliatePlan process failed',
        icon: 'error',
      })

      dispatch({
        type: 'DELETE_AFFILIATEPLAN_ERROR',
        payload: {
          result: 'Delete AffiliatePlan process failed',
        },
      } as DeleteAffiliatePlanError)
    }
  }

// Set Base Plan Periods
export const setBasePlanPeriod =
  (periods: Array<number>) => async (dispatch: Dispatch<SetCurrentBasePlanPeriod>) => {
    dispatch({
      type: 'SET_CURRENT_BASEPLAN_PERIOD',
      payload: {
        curAddonPeriods: periods,
      },
    } as SetCurrentBasePlanPeriod)
  }

// Set withoutplan flag 0
export const setWithoutPlanStatus =
  (withoutPlan: number) => async (dispatch: Dispatch<SetWithoutPlanStatus>) => {
    dispatch({
      type: 'SET_WITHOUTPLAN_STATUS',
      payload: {
        withoutPlan: withoutPlan,
      },
    } as SetWithoutPlanStatus)
  }

// Set empty value to the shopping cart
export const InitShoppingcart = () => async (dispatch: Dispatch<SetInitShoppingcart>) => {
  dispatch({
    type: 'SET_INIT_SHOPPINGCART',
  } as SetInitShoppingcart)
}

// Add baseplan to shopping cart
export const addShoppingBasePlan =
  (addBasePlan: object) => async (dispatch: Dispatch<AddShoppingBasePlan>) => {
    dispatch({
      type: 'ADD_SHOPPING_BASEPLAN',
      payload: {
        addBasePlan: addBasePlan,
      },
    } as AddShoppingBasePlan)
  }

// Add baseplan and addonplan to shopping cart (baseplan and addonplan are empty)
export const addShoppingBaseAndAddonPlan =
  (addBasePlan: object, addAddonPlan: Array<any>) =>
  async (dispatch: Dispatch<AddShoppingBaseAndAddonPlan>) => {
    dispatch({
      type: 'ADD_SHOPPING_BASE_AND_ADDON_PLAN',
      payload: {
        addBasePlan: addBasePlan,
        addAddonPlan: addAddonPlan,
      },
    } as AddShoppingBaseAndAddonPlan)
  }

// Add addonplan to shopping cart
export const addShoppingAddonPlan =
  (addAddonPlan: Array<any>) => async (dispatch: Dispatch<AddShoppingAddonPlan>) => {
    dispatch({
      type: 'ADD_SHOPPING_ADDONPLAN',
      payload: {
        addAddonPlan: addAddonPlan,
      },
    } as AddShoppingAddonPlan)
  }

// Add addonplan to shopping cart
export const addShoppingAddonAddonPlan =
  (addAddonAddonPlan: Array<any>) => async (dispatch: Dispatch<AddShoppingAddonAddonPlan>) => {
    dispatch({
      type: 'ADD_SHOPPING_ADDON_ADDONPLAN',
      payload: {
        addAddonAddonPlan: addAddonAddonPlan,
      },
    } as AddShoppingAddonAddonPlan)
  }

// Add affiliateplan to shopping cart
export const addShoppingAffiliatePlan =
  (addAffiliatePlan: object) => async (dispatch: Dispatch<AddShoppingAffiliatePlan>) => {
    dispatch({
      type: 'ADD_SHOPPING_AFFILIATEPLAN',
      payload: {
        addAffiliatePlan: addAffiliatePlan,
      },
    } as AddShoppingAffiliatePlan)
  }

// Set User Information
export const setUserInformation =
  (userinfo: object) => async (dispatch: Dispatch<SetUserInformation>) => {
    dispatch({
      type: 'SET_USER_INFORMATION',
      payload: {
        curUserInfo: userinfo,
      },
    } as SetUserInformation)
  }

// Set Sponser User Name
export const setUserSponserUserName =
  (sponserUserName: string, id: number, firstName: string, lastName: string) =>
  async (dispatch: Dispatch<SetSponserUserName>) => {
    dispatch({
      type: 'SET_SPONSER_USERNAME',
      payload: {
        sponserUserName: sponserUserName,
        sponsorId: id,
        firstName: firstName,
        lastName: lastName,
      },
    } as SetSponserUserName)
  }

// Get Subscriptions Information
export const getSubscriptions =
  (
    page: number,
    inspectorId: any,
    insepctorMode: any,
    status: string,
    renewalFlag: string,
    curStatusShowLast: boolean,
    createStartDate: string,
    createEndDate: string,
    expireStartDate: string,
    expireEndDate: string,
    search: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetSubscriptions | GetSubscriptionsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        auto_renewal_flag: renewalFlag,
        showOnlyLatestSubs: curStatusShowLast,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        expire_at_start: expireStartDate,
        expire_at_end: expireEndDate,
        sortType: sortType,
      }

      const response = await axios.post(`${BACKEND_API_URL}/filterSubscriptions `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_SUBSCRIPTIONS',
          payload: {
            subscriptions: token.results,
            totalSubscriptions: token.totalcount,
            result: result,
          },
        } as GetSubscriptions)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_SUBSCRIPTIONS_ERROR',
        payload: {
          result: 'Get Subscriptions process failed',
        },
      } as GetSubscriptionsError)
    }
  }

// Get Invoices Information
export const getInvoices =
  (
    page: number,
    inspectorId: any,
    insepctorMode: any,
    status: string,
    refundedFlag: string,
    createStartDate: string,
    createEndDate: string,
    search: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetInvoices | GetInvoicesError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        refunded_flag: refundedFlag,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/filterInvoices `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_INVOICES',
          payload: {
            invoices: token.results,
            totalInvoices: token.totalcount,
            result: result,
          },
        } as GetInvoices)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_INVOICES_ERROR',
        payload: {
          result: 'Get Invoice process failed',
        },
      } as GetInvoicesError)
    }
  }

// Get Payments Information
export const getPayments =
  (
    page: number,
    inspectorId: any,
    insepctorMode: any,
    status: string,
    refundedFlag: string,
    paymentMethod: string,
    createStartDate: string,
    createEndDate: string,
    search: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetPayments | GetPaymentsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        refunded_flag: refundedFlag,
        payment_method: paymentMethod,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/filterPayments `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_PAYMENTS',
          payload: {
            payments: token.results,
            totalPayments: token.totalcount,
            result: result,
          },
        } as GetPayments)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_PAYMENTS_ERROR',
        payload: {
          result: 'Get Payment process failed',
        },
      } as GetPaymentsError)
    }
  }

// Get Refunds Information
export const getRefunds =
  (
    page: number,
    inspectorId: any,
    insepctorMode: any,
    status: string,
    paymentMethod: string,
    createStartDate: string,
    createEndDate: string,
    search: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetRefunds | GetRefundsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        payment_method: paymentMethod,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/filterRefunds `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_REFUNDS',
          payload: {
            refunds: token.results,
            totalRefunds: token.totalcount,
            result: result,
          },
        } as GetRefunds)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_REFUNDS_ERROR',
        payload: {
          result: 'Get Refunds process failed',
        },
      } as GetRefundsError)
    }
  }

// Get StaffMembers Information
export const getStaffs =
  (page: number) => async (dispatch: Dispatch<GetStaffMembers | GetStaffMembersError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/staff/getStaffs `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_STAFFMEMBERS',
          payload: {
            staffMembers: token.results,
            totalstaffMembers: token.totalcount,
            result: result,
          },
        } as GetStaffMembers)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_STAFFMEMBERS_ERROR',
        payload: {
          result: 'Get StaffMembers process failed',
        },
      } as GetStaffMembersError)
    }
  }

// Search and Filter StaffMembers Information
export const searchandfilterStaffs =
  (
    lastLoginStartDate: string,
    lastLoginEndDate: string,
    joinStartDate: string,
    joinEndDate: string,
    page: number,
    search: string
  ) =>
  async (dispatch: Dispatch<FilterStaffMembers | FilterStaffMembersError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        search_text: search,
        login_start_date: lastLoginStartDate,
        login_end_date: lastLoginEndDate,
        join_start_date: joinStartDate,
        join_end_date: joinEndDate,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/staff/filterStaffs `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'SEARCH_FILTER_STAFFMEMBERS',
          payload: {
            staffMembers: token.results,
            totalstaffMembers: token.totalcount,
            result: result,
          },
        } as FilterStaffMembers)
      }
    } catch (error: any) {
      dispatch({
        type: 'SEARCH_FILTER_STAFFMEMBERS_ERROR',
        payload: {
          result: 'Search and Filter StaffMembers process failed',
        },
      } as FilterStaffMembersError)
    }
  }
// Delete StaffMember  Information
export const deleteStaff =
  (id: any) => async (dispatch: Dispatch<DeleteStaffMember | DeleteStaffMemberError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        id: id,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/staff/deleteStaff`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Staff Member',
          text: 'Staff Member has been successfully deleted.',
          icon: 'success',
        })

        dispatch({
          type: 'DELETE_STAFFMEMBER',
          payload: {
            staffMembers: token.results,
            totalstaffMembers: token.totalcount,
            result: result,
          },
        } as DeleteStaffMember)
      } else {
        swal({
          title: 'Staff Member',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Staff Member',
        text: 'Delete Staff Member process failed',
        icon: 'error',
      })

      dispatch({
        type: 'DELETE_STAFFMEMBER_ERROR',
        payload: {
          result: 'Delete Staff Member process failed',
        },
      } as DeleteStaffMemberError)
    }
  }
// Delete Multi StaffMembers  Information
export const deleteStaffs =
  (staff_ids: any) => async (dispatch: Dispatch<DeleteStaffMembers | DeleteStaffMembersError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        staff_ids: staff_ids,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/staff/deleteStaffs`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else if (result === 'success') {
        swal({
          title: 'Staff Member',
          text: 'Staff Members has been successfully deleted.',
          icon: 'success',
        })

        dispatch({
          type: 'DELETE_STAFFMEMBERS',
          payload: {
            staffMembers: token.results,
            totalstaffMembers: token.totalcount,
            result: result,
          },
        } as DeleteStaffMembers)
      } else {
        swal({
          title: 'Staff Member',
          text: result,
          icon: 'warning',
        })
      }
    } catch (error: any) {
      swal({
        title: 'Staff Member',
        text: 'Delete Staff Members process failed',
        icon: 'error',
      })

      dispatch({
        type: 'DELETE_STAFFMEMBERS_ERROR',
        payload: {
          result: 'Delete Staff Member process failed',
        },
      } as DeleteStaffMembersError)
    }
  }
// Change StaffMember Edit Profile Status
export const changeStaffMemberStatus =
  (status: boolean) => async (dispatch: Dispatch<ChangeStaffMemberEditStatus>) => {
    dispatch({
      type: 'CHANGE_STAFFMEMBER_EDIT_STATUS',
      payload: {
        staffEditProfileStatus: status,
      },
    } as ChangeStaffMemberEditStatus)
  }

// Get TealList -> UserLists Information
export const getTeamUserLists =
  (
    page: number,
    apiUrl: string,
    inspectorId: any,
    insepctorMode: any,
    search: string,
    status: string,
    place: string,
    affiliate: string,
    structure: string,
    joinStartDate: string,
    joinEndDate: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetTeamUserLists | GetTeamUserListsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        placed: place,
        affiliate: affiliate,
        structure: structure,
        join_from_date: joinStartDate,
        join_to_date: joinEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiUrl} `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_TEAM_USERLISTS',
          payload: {
            teamUserlists: token.result,
            totalTeamUserlists: token.totalcount,
            result: result,
          },
        } as GetTeamUserLists)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_TEAM_USERLISTS_ERROR',
        payload: {
          result: 'Get Userlists process failed',
        },
      } as GetTeamUserListsError)
    }
  }

// Get Team -> Subscriptions Information
export const getTeamSubscriptions =
  (
    page: number,
    apiUrl: string,
    inspectorId: any,
    insepctorMode: any,
    search: string,
    status: string,
    renewalFlag: string,
    structure: string,
    statusShowLast: boolean,
    createStartDate: string,
    createEndDate: string,
    expireStartDate: string,
    expireEndDate: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetTeamSubscriptions | GetTeamSubscriptionsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        auto_renewal_flag: renewalFlag,
        structure: structure,
        showOnlyLatestSubs: statusShowLast,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        expire_at_start: expireStartDate,
        expire_at_end: expireEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiUrl} `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_TEAM_SUBSCRIPTIONS',
          payload: {
            teamSubscriptions: token.results,
            totalTeamSubscriptions: token.totalcount,
            result: result,
          },
        } as GetTeamSubscriptions)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_TEAM_SUBSCRIPTIONS_ERROR',
        payload: {
          result: 'Get Subscriptions process failed',
        },
      } as GetTeamSubscriptionsError)
    }
  }

// Get Team -> Invoices Information
export const getTeamInvoices =
  (
    page: number,
    apiUrl: string,
    inspectorId: any,
    insepctorMode: any,
    search: string,
    status: string,
    refundedFlag: string,
    structure: string,
    createStartDate: string,
    createEndDate: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetTeamInvoices | GetTeamInvoicesError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        refunded_flag: refundedFlag,
        structure: structure,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiUrl} `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_TEAM_INVOICES',
          payload: {
            teamInvoices: token.results,
            totalTeamInvoices: token.totalcount,
            result: result,
          },
        } as GetTeamInvoices)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_TEAM_INVOICES_ERROR',
        payload: {
          result: 'Get Invoice process failed',
        },
      } as GetTeamInvoicesError)
    }
  }

// Get Team -> Payments Information
export const getTeamPayments =
  (
    page: number,
    apiUrl: string,
    inspectorId: any,
    insepctorMode: any,
    search: string,
    status: string,
    refundedFlag: string,
    paymentMethod: string,
    structure: string,
    createStartDate: string,
    createEndDate: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetTeamPayments | GetTeamPaymentsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        refunded_flag: refundedFlag,
        structure: structure,
        payment_method: paymentMethod,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiUrl} `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_TEAM_PAYMENTS',
          payload: {
            teamPayments: token.results,
            totalTeamPayments: token.totalcount,
            result: result,
          },
        } as GetTeamPayments)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_TEAM_PAYMENTS_ERROR',
        payload: {
          result: 'Get Payment process failed',
        },
      } as GetTeamPaymentsError)
    }
  }

// Get Team -> Refunds Information
export const getTeamRefunds =
  (
    page: number,
    apiUrl: string,
    inspectorId: any,
    insepctorMode: any,
    search: string,
    status: string,
    paymentMethod: string,
    structure: string,
    createStartDate: string,
    createEndDate: string,
    sortType: number
  ) =>
  async (dispatch: Dispatch<GetTeamRefunds | GetTeamRefundsError>) => {
    try {
      let tokenValue = localStorage.getItem('token')
      const req = {
        index: page,
        inspectorMode: insepctorMode,
        inspectorId: inspectorId,
        search_text: search,
        status: status,
        payment_method: paymentMethod,
        structure: structure,
        created_at_start: createStartDate,
        created_at_end: createEndDate,
        sortType: sortType,
      }

      const response = await axios.post<any>(`${BACKEND_API_URL}/${apiUrl} `, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {token, result, auth} = response.data

      if (auth === false) {
        removeAllSession()
        window.open('/auth/login', '_self')
      } else {
        dispatch({
          type: 'GET_TEAM_REFUNDS',
          payload: {
            teamRefunds: token.results,
            totalTeamRefunds: token.totalcount,
            result: result,
          },
        } as GetTeamRefunds)
      }
    } catch (error: any) {
      dispatch({
        type: 'GET_TEAM_REFUNDS_ERROR',
        payload: {
          result: 'Get Refunds process failed',
        },
      } as GetTeamRefundsError)
    }
  }
