import swal from 'sweetalert'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import * as authUser from '../../redux/AuthRedux'
import {compareStaffSecureCode} from '../../redux/AuthCRUD'

export default function Secure() {
  const dispatch = useDispatch()
  const [curSecureCode, setSecureCode] = useState('')

  const handleConfirm = () => {
    if (curSecureCode.length !== 6) {
      swal({
        title: 'Secure',
        text: 'Please input correct secure code',
        icon: 'warning',
      })
    } else {
      compareStaffSecureCode(curSecureCode)
        .then((data: any) => {
          if (data?.data?.result === 'success') {
            localStorage.setItem('token', data?.data?.token['token'])
            localStorage.setItem('security_flag', '0')
            localStorage.setItem('restriction_flag', data?.data?.token['restriction_flag'])
            localStorage.setItem('confirm_flag', data?.data?.token['confirm_flag'])

            dispatch(
              authUser.actions.updateUser(
                data?.data?.token['user_name'],
                data?.data?.token['id'],
                data?.data?.token['email'],
                data?.data?.token['first_name'],
                data?.data?.token['last_name'],
                data?.data?.token['birth'],
                data?.data?.token['streetaddress'],
                data?.data?.token['suite'],
                data?.data?.token['postalcode'],
                data?.data?.token['cityaddress'],
                data?.data?.token['country'],
                data?.data?.token['timezone'],
                data?.data?.token['photo_url'],
                data?.data?.token['security_flag'],
                data?.data?.token['restriction_flag'],
                data?.data?.token['confirm_flag'],
                data?.data?.token['cryptowallet'],
                '0',
                0
              )
            )
          } else {
            swal({
              title: 'Secure',
              text: data?.data?.result,
              icon: 'warning',
            })
          }
        })
        .catch(() => {
          swal({
            title: 'Secure',
            text: 'Secure process has broken',
            icon: 'error',
          })
        })
    }
  }

  const handleLogin = () => {
    localStorage.setItem('restriction_flag', '1')
    window.location.href = '/staff/login'
  }

  return (
    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
      <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Staff Member Secure ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Please check you mailbox.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Secure Code</label>
          <input
            type='email'
            placeholder='Please input your secure code'
            className='form-control form-control-lg form-control-solid'
            onChange={(event) => setSecureCode(event.currentTarget.value as string)}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={handleConfirm}
          >
            Confirm
          </button>
          {/* <Link to='/auth/login'> */}
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            onClick={handleLogin}
          >
            Login
          </button>
          {/* </Link> */}
        </div>
        {/* end::Form group */}
      </div>
    </div>
  )
}
