import {AppActionTypes} from '../actions/action.types'

const initialState: WebshopState = {
  basePlans: [],
  baseServices: [],
  totalBasePlans: 0,
  resultBasePlans: '',
  addonPlans: [],
  totalAddonPlans: 0,
  resultAddonPlans: '',
  affiliatePlans: [],
  totalAffiliatePlans: 0,
  resultAffiliatePlans: '',
  curAddonPeriods: [],
  withoutPlan: 0,
  shoppingCart: [],
  curUserInfo: {},
  sponserUserName: '',
  sponsorId: 0,
  sponsorFirstName: '',
  sponsorSecondName: '',
  subscriptions: [],
  totalSubscriptions: 0,
  resultSubscriptions: '',
  invoices: [],
  totalInvoices: 0,
  resultInvoices: '',
  payments: [],
  totalPayments: 0,
  resultPayments: '',
  refunds: [],
  totalRefunds: 0,
  resultRefunds: '',
  staffMembers: [],
  totalstaffMembers: 0,
  resultstaffMembers: '',
  staffEditProfileStatus: false,
  teamUserlists: [],
  totalTeamUserlists: 0,
  resultTeamUserlists: '',
  teamSubscriptions: [],
  totalTeamSubscriptions: 0,
  resultTeamSubscriptions: '',
  teamInvoices: [],
  totalTeamInvoices: 0,
  resultTeamInvoices: '',
  teamPayments: [],
  totalTeamPayments: 0,
  resultTeamPayments: '',
  teamRefunds: [],
  totalTeamRefunds: 0,
  resultTeamRefunds: '',
}

export interface WebshopState {
  basePlans: Array<any>
  baseServices: Array<string>
  totalBasePlans: number
  resultBasePlans: string
  addonPlans: Array<any>
  totalAddonPlans: number
  resultAddonPlans: string
  affiliatePlans: Array<any>
  totalAffiliatePlans: number
  resultAffiliatePlans: string
  curAddonPeriods: number[]
  withoutPlan: number
  shoppingCart: Array<any>
  curUserInfo: object
  sponserUserName: string
  sponsorId: number
  sponsorFirstName: string
  sponsorSecondName: string
  subscriptions: Array<any>
  totalSubscriptions: number
  resultSubscriptions: string
  invoices: Array<any>
  totalInvoices: number
  resultInvoices: string
  payments: Array<any>
  totalPayments: number
  resultPayments: string
  refunds: Array<any>
  totalRefunds: number
  resultRefunds: string
  staffMembers: Array<any>
  totalstaffMembers: number
  resultstaffMembers: string
  staffEditProfileStatus: boolean
  teamUserlists: Array<any>
  totalTeamUserlists: number
  resultTeamUserlists: string
  teamSubscriptions: Array<any>
  totalTeamSubscriptions: number
  resultTeamSubscriptions: string
  teamInvoices: Array<any>
  totalTeamInvoices: number
  resultTeamInvoices: string
  teamPayments: Array<any>
  totalTeamPayments: number
  resultTeamPayments: string
  teamRefunds: Array<any>
  totalTeamRefunds: number
  resultTeamRefunds: string
}

const accountReducer = (
  state: WebshopState = initialState,
  action: AppActionTypes
): WebshopState => {
  switch (action.type) {
    case 'GET_BASEPLANS':
      return {
        ...state,
        basePlans: action.payload.basePlans,
        totalBasePlans: action.payload.totalBasePlans,
        resultBasePlans: action.payload.result,
      }

    case 'GET_BASEPLANS_ERROR':
      return {
        ...state,
        resultBasePlans: action.payload.result,
      }

    case 'ADD_BASEPLAN':
      return {
        ...state,
        basePlans: action.payload.basePlans,
        totalBasePlans: action.payload.totalBasePlans,
        resultBasePlans: action.payload.result,
      }

    case 'ADD_BASEPLAN_ERROR':
      return {
        ...state,
        resultBasePlans: action.payload.result,
      }

    case 'EDIT_BASEPLAN':
      return {
        ...state,
        basePlans: action.payload.basePlans,
        totalBasePlans: action.payload.totalBasePlans,
        resultBasePlans: action.payload.result,
      }

    case 'EDIT_BASEPLAN_ERROR':
      return {
        ...state,
        resultBasePlans: action.payload.result,
      }

    case 'DELETE_BASEPLAN':
      return {
        ...state,
        basePlans: action.payload.basePlans,
        totalBasePlans: action.payload.totalBasePlans,
        resultBasePlans: action.payload.result,
      }

    case 'DELETE_BASEPLAN_ERROR':
      return {
        ...state,
        resultBasePlans: action.payload.result,
      }

    case 'GET_BASESERVICES':
      return {
        ...state,
        baseServices: action.payload.baseServices,
        resultBasePlans: action.payload.result,
      }

    case 'GET_BASESERVICES_ERROR':
      return {
        ...state,
        resultBasePlans: action.payload.result,
      }

    case 'GET_ADDONPLANS':
      return {
        ...state,
        addonPlans: action.payload.addonPlans,
        totalAddonPlans: action.payload.totalAddonPlans,
        resultAddonPlans: action.payload.result,
      }

    case 'GET_ADDONPLANS_ERROR':
      return {
        ...state,
        resultAddonPlans: action.payload.result,
      }

    case 'ADD_ADDONPLAN':
      return {
        ...state,
        addonPlans: action.payload.addonPlans,
        totalAddonPlans: action.payload.totalAddonPlans,
        resultAddonPlans: action.payload.result,
      }

    case 'ADD_ADDONPLAN_ERROR':
      return {
        ...state,
        resultAddonPlans: action.payload.result,
      }

    case 'EDIT_ADDONPLAN':
      return {
        ...state,
        addonPlans: action.payload.addonPlans,
        totalAddonPlans: action.payload.totalAddonPlans,
        resultAddonPlans: action.payload.result,
      }

    case 'EDIT_ADDONPLAN_ERROR':
      return {
        ...state,
        resultAddonPlans: action.payload.result,
      }

    case 'DELETE_ADDONPLAN':
      return {
        ...state,
        addonPlans: action.payload.addonPlans,
        totalAddonPlans: action.payload.totalAddonPlans,
        resultAddonPlans: action.payload.result,
      }

    case 'DELETE_ADDONPLAN_ERROR':
      return {
        ...state,
        resultAddonPlans: action.payload.result,
      }

    case 'GET_AFFILIATEPLANS':
      return {
        ...state,
        affiliatePlans: action.payload.affiliatePlans,
        totalAffiliatePlans: action.payload.totalAffiliatePlans,
        resultAffiliatePlans: action.payload.result,
      }

    case 'GET_AFFILIATEPLANS_ERROR':
      return {
        ...state,
        resultAffiliatePlans: action.payload.result,
      }

    case 'ADD_AFFILIATEPLAN':
      return {
        ...state,
        affiliatePlans: action.payload.affiliatePlans,
        totalAffiliatePlans: action.payload.totalAffiliatePlans,
        resultAffiliatePlans: action.payload.result,
      }

    case 'ADD_AFFILIATEPLAN_ERROR':
      return {
        ...state,
        resultAffiliatePlans: action.payload.result,
      }

    case 'EDIT_AFFILIATEPLAN':
      return {
        ...state,
        affiliatePlans: action.payload.affiliatePlans,
        totalAffiliatePlans: action.payload.totalAffiliatePlans,
        resultAffiliatePlans: action.payload.result,
      }

    case 'EDIT_AFFILIATEPLAN_ERROR':
      return {
        ...state,
        resultAffiliatePlans: action.payload.result,
      }

    case 'DELETE_AFFILIATEPLAN':
      return {
        ...state,
        affiliatePlans: action.payload.affiliatePlans,
        totalAffiliatePlans: action.payload.totalAffiliatePlans,
        resultAffiliatePlans: action.payload.result,
      }

    case 'DELETE_AFFILIATEPLAN_ERROR':
      return {
        ...state,
        resultAffiliatePlans: action.payload.result,
      }

    case 'SET_CURRENT_BASEPLAN_PERIOD':
      return {
        ...state,
        curAddonPeriods: action.payload.curAddonPeriods,
      }

    case 'SET_WITHOUTPLAN_STATUS':
      return {
        ...state,
        withoutPlan: action.payload.withoutPlan,
      }

    case 'SET_INIT_SHOPPINGCART':
      return {
        ...state,
        shoppingCart: [],
      }

    case 'ADD_SHOPPING_BASEPLAN':
      return {
        ...state,
        shoppingCart: [...state.shoppingCart, action.payload.addBasePlan],
      }

    case 'ADD_SHOPPING_BASE_AND_ADDON_PLAN':
      return {
        ...state,
        shoppingCart: [
          ...state.shoppingCart,
          action.payload.addBasePlan,
          action.payload.addAddonPlan,
        ],
      }

    case 'ADD_SHOPPING_ADDONPLAN':
      return {
        ...state,
        shoppingCart: [...state.shoppingCart, action.payload.addAddonPlan],
      }

    case 'ADD_SHOPPING_ADDON_ADDONPLAN':
      return {
        ...state,
        shoppingCart: [...state.shoppingCart, action.payload.addAddonAddonPlan],
      }

    case 'ADD_SHOPPING_AFFILIATEPLAN':
      return {
        ...state,
        shoppingCart: [...state.shoppingCart, action.payload.addAffiliatePlan],
      }

    case 'SET_USER_INFORMATION':
      return {
        ...state,
        curUserInfo: action.payload.curUserInfo,
      }

    case 'SET_SPONSER_USERNAME':
      return {
        ...state,
        sponserUserName: action.payload.sponserUserName,
        sponsorId: action.payload.sponsorId,
        sponsorFirstName: action.payload.firstName,
        sponsorSecondName: action.payload.lastName,
      }

    case 'GET_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
        totalSubscriptions: action.payload.totalSubscriptions,
        resultSubscriptions: action.payload.result,
      }
    case 'GET_SUBSCRIPTIONS_ERROR':
      return {
        ...state,
        resultSubscriptions: action.payload.result,
      }

    case 'GET_INVOICES':
      return {
        ...state,
        invoices: action.payload.invoices,
        totalInvoices: action.payload.totalInvoices,
        resultInvoices: action.payload.result,
      }
    case 'GET_INVOICES_ERROR':
      return {
        ...state,
        resultInvoices: action.payload.result,
      }

    case 'GET_PAYMENTS':
      return {
        ...state,
        payments: action.payload.payments,
        totalPayments: action.payload.totalPayments,
        resultPayments: action.payload.result,
      }
    case 'GET_PAYMENTS_ERROR':
      return {
        ...state,
        resultPayments: action.payload.result,
      }

    case 'GET_REFUNDS':
      return {
        ...state,
        refunds: action.payload.refunds,
        totalRefunds: action.payload.totalRefunds,
        resultRefunds: action.payload.result,
      }
    case 'GET_REFUNDS_ERROR':
      return {
        ...state,
        resultRefunds: action.payload.result,
      }

    case 'GET_STAFFMEMBERS':
      return {
        ...state,
        staffMembers: action.payload.staffMembers,
        totalstaffMembers: action.payload.totalstaffMembers,
        resultstaffMembers: action.payload.result,
      }
    case 'GET_STAFFMEMBERS_ERROR':
      return {
        ...state,
        resultstaffMembers: action.payload.result,
      }
    case 'SEARCH_FILTER_STAFFMEMBERS':
      return {
        ...state,
        staffMembers: action.payload.staffMembers,
        totalstaffMembers: action.payload.totalstaffMembers,
        resultstaffMembers: action.payload.result,
      }
    case 'SEARCH_FILTER_STAFFMEMBERS_ERROR':
      return {
        ...state,
        resultstaffMembers: action.payload.result,
      }
    case 'DELETE_STAFFMEMBER':
      return {
        ...state,
        staffMembers: action.payload.staffMembers,
        totalstaffMembers: action.payload.totalstaffMembers,
        resultstaffMembers: action.payload.result,
      }
    case 'DELETE_STAFFMEMBER_ERROR':
      return {
        ...state,
        resultstaffMembers: action.payload.result,
      }
    case 'DELETE_STAFFMEMBERS':
      return {
        ...state,
        staffMembers: action.payload.staffMembers,
        totalstaffMembers: action.payload.totalstaffMembers,
        resultstaffMembers: action.payload.result,
      }
    case 'DELETE_STAFFMEMBERS_ERROR':
      return {
        ...state,
        resultstaffMembers: action.payload.result,
      }
    case 'CHANGE_STAFFMEMBER_EDIT_STATUS':
      return {
        ...state,
        staffEditProfileStatus: action.payload.staffEditProfileStatus,
      }

    case 'GET_TEAM_USERLISTS':
      return {
        ...state,
        teamUserlists: action.payload.teamUserlists,
        totalTeamUserlists: action.payload.totalTeamUserlists,
        resultTeamUserlists: action.payload.result,
      }
    case 'GET_TEAM_USERLISTS_ERROR':
      return {
        ...state,
        resultTeamUserlists: action.payload.result,
      }

    case 'GET_TEAM_SUBSCRIPTIONS':
      return {
        ...state,
        teamSubscriptions: action.payload.teamSubscriptions,
        totalTeamSubscriptions: action.payload.totalTeamSubscriptions,
        resultTeamSubscriptions: action.payload.result,
      }
    case 'GET_TEAM_SUBSCRIPTIONS_ERROR':
      return {
        ...state,
        resultTeamSubscriptions: action.payload.result,
      }

    case 'GET_TEAM_INVOICES':
      return {
        ...state,
        teamInvoices: action.payload.teamInvoices,
        totalTeamInvoices: action.payload.totalTeamInvoices,
        resultTeamInvoices: action.payload.result,
      }
    case 'GET_TEAM_INVOICES_ERROR':
      return {
        ...state,
        resultTeamInvoices: action.payload.result,
      }

    case 'GET_TEAM_PAYMENTS':
      return {
        ...state,
        teamPayments: action.payload.teamPayments,
        totalTeamPayments: action.payload.totalTeamPayments,
        resultTeamPayments: action.payload.result,
      }
    case 'GET_TEAM_PAYMENTS_ERROR':
      return {
        ...state,
        resultTeamPayments: action.payload.result,
      }

    case 'GET_TEAM_REFUNDS':
      return {
        ...state,
        teamRefunds: action.payload.teamRefunds,
        totalTeamRefunds: action.payload.totalTeamRefunds,
        resultTeamRefunds: action.payload.result,
      }
    case 'GET_TEAM_REFUNDS_ERROR':
      return {
        ...state,
        resultTeamRefunds: action.payload.result,
      }

    default:
      return state
  }
}

export default accountReducer
