/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import Slider from 'react-slick'
import {useHistory} from 'react-router'
import {connect, useDispatch} from 'react-redux'
import React, {useState, useEffect} from 'react'

import '../webshop/webshop.css'
import {KTSVG} from '../../../_metronic/helpers'
import {AppState} from '../../../setup/redux/Store'
import {addShoppingAffiliatePlan} from '../../../setup/redux/actions/webshop'
import {
  BACKEND_API_URL,
  BACKEND_UPLOAD_URL,
  removeAllSession,
  DEFAULTTOKEN,
  BACKEND_API_V2,
} from '../../../config/config'
import {useTranslation} from 'react-i18next'

type NextProps = {
  nextSlider: () => void
}

type BeforeProps = {
  prevSlider: () => void
}

function SampleNextArrow({nextSlider}: NextProps) {
  return (
    <div onClick={nextSlider} className='slideNextBtn'>
      <img src='/media/icons/duotune/arrows/arr071.svg' alt='next' />
    </div>
  )
}

function SamplePrevArrow({prevSlider}: BeforeProps) {
  return (
    <div onClick={prevSlider} className='slidePrevBtn'>
      <img src='/media/icons/duotune/arrows/arr074.svg' alt='prev' />
    </div>
  )
}

interface Props {
  setViewStatus: React.Dispatch<React.SetStateAction<number>>
  withoutPlan: number
  state: {
    countrieSettings: any[]
    paymentSettings: any[]
    basePlans: any[]
    addons: any[]
    affiliatePlans: any[]
  }
}

function Affiliateplan({setViewStatus, withoutPlan, state}: Props) {
  let slider: any

  const {t} = useTranslation()

  const history = useHistory()
  const dispatch = useDispatch()
  const [backStyle, setBackStyle] = useState('')
  const [editStyle, setEditStyle] = useState('modal')
  const [curAffiliatePlans, setAffiliatePlans] = useState<Array<any>>([])
  const [curAffiliatePlan, setCurAffiliatePlan] = useState<any>()
  const [curIndex, setCurIndex] = useState(0)
  const [editDetailStyle, setEditDetailStyle] = useState('modal')
  const [curDlgStatus1, setCurDlgStatus1] = useState(false)
  const [curDlgStatus2, setCurDlgStatus2] = useState(false)
  const [curDlgStatus3, setCurDlgStatus3] = useState(false)

  const nextSlider = () => {
    slider.slickNext()
  }
  const prevSlider = () => {
    slider.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow nextSlider={nextSlider} />,
    prevArrow: <SamplePrevArrow prevSlider={prevSlider} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const getData = async () => {
    const affiliate = state.affiliatePlans
    if (!affiliate) return

    let tempDatas = affiliate.sort((a: any, b: any) => {
      return b.price - a.price
    })
    for (let i = 0; i < tempDatas.length; i++) {
      let tempCheckData = tempDatas[i]
      let tempChecked = tempCheckData.checked_attributes.split(';')
      if (tempChecked[tempChecked.length - 1] === '') {
        tempChecked.pop()
      }
      let tempUnCheckData = tempDatas[i]
      let tempUnChecked = tempUnCheckData.unchecked_attributes.split(';')
      if (tempUnChecked[tempUnChecked.length - 1] === '') {
        tempUnChecked.pop()
      }

      tempDatas[i].checked_attributes = tempChecked
      tempDatas[i].unchecked_attributes = tempUnChecked
    }

    setAffiliatePlans(tempDatas)
  }

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.affiliatePlans])

  const handleSelect = (index: number) => {
    setCurIndex(index)

    setBackStyle('modal-backdrop show ')
    setEditStyle('modal show billing-address-modal-show')
  }

  const handleModalClose = () => {
    setBackStyle('')
    setEditStyle('modal')
  }

  const handleModalContinue = () => {
    setViewStatus(3)
  }

  const handleModalBecome = () => {
    dispatch(addShoppingAffiliatePlan(curAffiliatePlans[curIndex]))
    setViewStatus(3)
  }

  const handleDetail = (index: number) => {
    setBackStyle('modal-backdrop show ')
    setEditDetailStyle('modal show billing-address-modal-show')

    let tempData = curAffiliatePlans[index]

    let tempCheckedArray = tempData.checked_attributes
    let tempChecked = ''
    for (let i = 0; i < tempCheckedArray.length; i++) {
      tempChecked += tempCheckedArray[i]
      if (i !== tempCheckedArray.length - 1) tempChecked += ';'
    }

    let tempUnCheckedArray = tempData.unchecked_attributes
    let tempUnChecked = ''
    for (let i = 0; i < tempUnCheckedArray.length; i++) {
      tempUnChecked += tempUnCheckedArray[i]
      if (i !== tempUnCheckedArray.length - 1) tempUnChecked += ';'
    }
    let tempPeriod = tempData.subscription_length
    tempPeriod += ' months'
    let tempProduct = tempData.products
    let tempProducts = tempProduct.split(';')

    let temp: any = {}
    temp.product_image = tempData.product_image
    temp.status = tempData.status
    temp.scheduled_time = tempData.scheduled_time
    temp.card_name = tempData.card_name
    temp.card_description = tempData.card_description
    temp.long_description = tempData.long_description
    temp.checked = tempChecked
    temp.unChecked = tempUnChecked
    temp.price = tempData.price
    temp.discount_flag = tempData.discount_flag
    temp.discount = tempData.discount
    temp.subscription_length = tempPeriod
    temp.quantity = tempData.quantity
    temp.quantity_ignore_flag = tempData.quantity_ignore_flag
    temp.product_volume = tempData.product_volume
    temp.rank_volume = tempData.rank_volume
    temp.hpp_units = tempData.hpp_units
    temp.hpp_coupons = tempData.hpp_coupons
    temp.products = tempProducts
    temp.ea_flag = tempData.ea_flag
    temp.ea_license_id = tempData.ea_license_id
    temp.vps_image_name = tempData.vps_image_name
    setCurAffiliatePlan(temp)
  }
  const handleDetailClose = () => {
    setBackStyle('')
    setEditDetailStyle('modal')
  }

  const handleAlert1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurDlgStatus1(e.target.checked)
  }
  const handleAlert2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurDlgStatus2(e.target.checked)
  }
  const handleAlert3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurDlgStatus3(e.target.checked)
  }

  const handleContinue = () => {
    dispatch(addShoppingAffiliatePlan({}))
    setViewStatus(3)
  }

  return (
    <>
      <div className='card p-lg-5 shadow-sm'>
        <div className='d-flex flex-column'>
          <div className='mb-13 text-center' style={{marginTop: '80px'}}>
            <img
              alt='Logo'
              src='/media/logos/logo.svg'
              className='h-60px plan-avatar'
              style={{top: '2%'}}
            />
            <h1 className='fs-2hx fw-bolder mb-5'>{t('SHOPPINGCART_CONTENT_1')}</h1>
            <div className='basic-plan-desc fw-bold fs-5'>
              {t('WEBSHOP_BASEPLAN_CONTENT1')}&nbsp;
              <a
                className='menu-link without-sub'
                href='https://be-infinity.com/pricing_policy'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                <span className='link-primary fw-bolder'>{t('WEBSHOP_BASEPLAN_CONTENT2')}</span>.
              </a>
            </div>
          </div>
          <div className='row g-10'>
            <Slider ref={(c) => (slider = c)} {...settings}>
              {curAffiliatePlans && curAffiliatePlans.length > 0 ? (
                curAffiliatePlans.map((item, key) => (
                  <div className='slide-item' key={key}>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex align-items-center position-relative flex-column rounded-3 bg-light bg-opacity-75 plan-item'>
                        <button
                          className='card-detail'
                          style={{background: 'transparent'}}
                          onClick={() => handleDetail(key)}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <KTSVG path={'/media/icons/duotune/general/gen046.svg'} />
                          </span>
                        </button>
                        <div className='mb-7 text-center webshop-header'>
                          <h1 className='text-dark mb-5 h-50px d-flex justify-content-center align-items-center fw-boldest'>
                            {item.card_name}
                          </h1>
                          <div className='text-type1 fw-bold'>{item.card_description}</div>
                          {item.discount_flag === 1 ? (
                            <div className='plan-item-price'>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span
                                  className='fs-2x fw-bolder text-primary'
                                  style={{textDecoration: 'line-through'}}
                                >
                                  {parseFloat(item.price).toFixed(2)}
                                </span>
                              </div>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span className='fs-3x fw-bolder text-primary'>
                                  {parseFloat(item.discount).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className='text-center plan-item-discount'>
                              <span className='mb-2 text-primary'>$</span>
                              <span className='fs-3x fw-bolder text-primary'>
                                {parseFloat(item.price).toFixed(2)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='w-100 mb-10 webshop-content'>
                          {item.checked_attributes.map((itemCheck: any, i: number) => (
                            <div className='d-flex align-items-center' key={i}>
                              <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                {itemCheck}
                              </span>
                              <span className='svg-icon svg-icon-1 svg-icon-success'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='10'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            </div>
                          ))}
                          {item.unchecked_attributes.map((itemUnCheck: any, i: number) => (
                            <div className='d-flex align-items-center' key={i}>
                              <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                {itemUnCheck}
                              </span>
                              <span className='svg-icon svg-icon-1'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='10'
                                    className='unchecked-style'
                                  />
                                  <rect
                                    x='7'
                                    y='15.3137'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(-45 7 15.3137)'
                                    className='unchecked-style'
                                  />
                                  <rect
                                    x='8.41422'
                                    y='7'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(45 8.41422 7)'
                                    className='unchecked-style'
                                  />
                                </svg>
                              </span>
                            </div>
                          ))}
                        </div>
                        <button
                          className='btn btn-sm btn-primary'
                          onClick={() => handleSelect(key)}
                        >
                          {t('BTN_SELECT')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
            <div
              className={withoutPlan === 1 ? 'continue-basic-without-baseplan' : 'continue-basic'}
            >
              {withoutPlan !== 1 ? (
                <button
                  className='btn btn-primary me-3 continue-basic-btn'
                  onClick={handleContinue}
                >
                  {t('BTN_CONTINUE_CUSTOMER')}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={editStyle} tabIndex={-1} id='kt_modal_new_address' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_new_address_header'>
              <div className='affiliate-modal-title'>
                <h2>{t('WEBSHOP_AFFILIATE_TITLE2')}</h2>
              </div>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={handleModalClose}
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' />
                </span>
              </div>
            </div>
            <div className='modal-body py-10 px-lg-17'>
              <div
                className='scroll-y me-n7 pe-7'
                id='kt_modal_new_address_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_new_address_header'
                data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
                data-kt-scroll-offset='300px'
              >
                <div>
                  <h1 className='basic-plan-desc fw-bold fs-5 mb-5'>
                    {t('WEBSHOP_AFFILIATE_CONTENT1')}
                  </h1>
                  <h1 className='basic-plan-desc fw-bold fs-5 mb-5'>
                    {t('WEBSHOP_AFFILIATE_CONTENT2')}
                  </h1>
                </div>
                <div className='form-check form-check-solid fv-row'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    style={{border: '1px solid #0e0e122e'}}
                    onChange={handleAlert1}
                  />
                  <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                    {t('WEBSHOP_AFFILIATE_CONTENT3')}
                  </label>
                </div>
                <div className='form-check form-check-solid fv-row'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    style={{border: '1px solid #0e0e122e'}}
                    onChange={handleAlert2}
                  />
                  <label
                    className='form-check-label fw-bold ps-2 fs-6'
                    htmlFor='deactivate'
                    dangerouslySetInnerHTML={{__html: t('WEBSHOP_AFFILIATE_CONTENT4')}}
                  />
                </div>
                <div className='form-check form-check-solid fv-row'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    style={{border: '1px solid #0e0e122e'}}
                    onChange={handleAlert3}
                  />
                  <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                    {t('WEBSHOP_AFFILIATE_CONTENT5')}
                  </label>
                </div>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              {withoutPlan !== 1 ? (
                <button
                  id='kt_modal_new_address_submit'
                  className='btn btn-light me-3'
                  onClick={handleModalContinue}
                >
                  <span className='indicator-label'>{t('BTN_CONTINUE_CUSTOMER')}</span>
                </button>
              ) : (
                <></>
              )}
              <button
                id='kt_modal_new_address_cancel'
                className='btn btn-primary'
                disabled={
                  curDlgStatus1 === false || curDlgStatus2 === false || curDlgStatus3 === false
                }
                onClick={handleModalBecome}
              >
                {t('WEBSHOP_AFFILIATE_TITLE1')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={editDetailStyle} tabIndex={-1} id='kt_modal_edit_user' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='fw-bolder m-0'>{t('WEBSHOP_BASEPLAN_TITLE2')}</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={handleDetailClose}
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' />
                </span>
              </div>
            </div>
            <div className='modal-body py-10 px-lg-17'>
              <div
                className='scroll-y me-n7 pe-7'
                id='kt_modal_new_address_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_new_address_header'
                data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
                data-kt-scroll-offset='300px'
              >
                {/* Avatar */}
                <div className='row mb-6 mt-6'>
                  <div className='col-lg-4 image-avatar d-flex align-items-center justify-content-center '>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <div className='image-input-wrapper w-125px h-125px'>
                        <img
                          src={
                            curAffiliatePlan?.product_image &&
                            curAffiliatePlan?.product_image.length > 0
                              ? BACKEND_UPLOAD_URL + curAffiliatePlan?.product_image
                              : '/media/files/blank-image.svg'
                          }
                          alt='avatar'
                          style={{width: '100%', height: '100%'}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8 fv-row'>
                    <span className='form-control border-0' style={{fontSize: '20px'}}>
                      {curAffiliatePlan?.card_name}
                    </span>
                    <span className='form-control border-0'>
                      {curAffiliatePlan?.card_description}
                    </span>
                  </div>
                </div>
                {/* Long Description */}
                <div className='row mb-6'>
                  <div className='col-lg-11 fv-row'>
                    <span
                      className='form-control border-0'
                      dangerouslySetInnerHTML={{__html: curAffiliatePlan?.long_description}}
                    />
                  </div>
                </div>
                {/* Period */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('WEBSHOP_BASEPLAN_TITLE3')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('WEBSHOP_ADDONPLAN_TITLE5')}
                      defaultValue={curAffiliatePlan?.subscription_length}
                      readOnly
                    />
                  </div>
                </div>
                {/* Quantity */}
                {curAffiliatePlan?.quantity_ignore_flag === 0 ? (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      {t('WEBSHOP_BASEPLAN_TITLE4')}
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder={t('WEBSHOP_BASEPLAN_TITLE4')}
                        defaultValue={curAffiliatePlan?.quantity}
                        readOnly
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* Product Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_2')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_2')}
                      defaultValue={curAffiliatePlan?.product_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Rank Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_3')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_3')}
                      defaultValue={curAffiliatePlan?.rank_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Units */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>{t('TEXT_UNITS')}</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('TEXT_UNITS')}
                      defaultValue={curAffiliatePlan?.hpp_units}
                      readOnly
                    />
                  </div>
                </div>
                {/* High Performance Pool Coupons */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('WEBSHOP_BASEPLAN_TITLE5')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('WEBSHOP_BASEPLAN_TITLE5')}
                      defaultValue={curAffiliatePlan?.hpp_coupons}
                      readOnly
                    />
                  </div>
                </div>
                {/* Products */}
                <div className='row mb-6 justify-content-end'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('SIDBAR_PRODUCTS_TITLE')}
                  </label>
                  {curAffiliatePlan?.products && curAffiliatePlan?.products.length > 0 ? (
                    curAffiliatePlan?.products.map((product: string, i: number) => (
                      <div className='col-lg-8 fv-row mb-5' key={i}>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder={t('SIDBAR_PRODUCTS_TITLE')}
                          defaultValue={product}
                          readOnly
                        />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                id='kt_modal_new_address_cancel'
                className='btn btn-primary'
                onClick={handleDetailClose}
              >
                {t('BTN_TEXT_CLOSE')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={backStyle}></div>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  withoutPlan: state.webshop.withoutPlan,
})

export default connect(mapStateToProps, '')(Affiliateplan)
