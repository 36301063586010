/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import swal from 'sweetalert'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
// import {browserName} from 'react-device-detect'
import SweetAlert from 'react-bootstrap-sweetalert'

import '../style.css'
import * as authUser from '../../redux/AuthRedux'
import {staffLogin} from '../../redux/AuthCRUD'
import {encryptPermissionStatus} from '../../../../../config/utils'
import {useSubscriptionsContext} from '../../../../../context/SubscriptionsContext'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('User Name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

export function StaffLogin() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [curStatusAlert, setCurStatusAlert] = useState(false)

  // useEffect(() => {
  //   if (browserName === 'Safari') {
  //     setCurStatusAlert(true)
  //   }
  // }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        staffLogin(values.username, values.password)
          .then(
            ({
              data: {
                token,
                user,
                id,
                email,
                first_name,
                second_name,
                timezone,
                photo_url,
                security_flag,
                restriction_flag,
                confirm_flag,
                result,
              },
            }) => {
              setLoading(false)

              if (user) {
                let encryptVal = encryptPermissionStatus(1)
                localStorage.setItem('data', encryptVal)

                if (security_flag === 0) {
                  localStorage.setItem('token', token)
                  localStorage.setItem('security_flag', security_flag)
                  localStorage.setItem('restriction_flag', restriction_flag)
                  localStorage.setItem('confirm_flag', confirm_flag)
                }

                dispatch(
                  authUser.actions.login(
                    0,
                    token,
                    user,
                    id,
                    email,
                    first_name,
                    second_name,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    timezone,
                    photo_url,
                    '',
                    security_flag,
                    restriction_flag,
                    confirm_flag
                  )
                )
              } else {
                swal({
                  title: 'Login',
                  text: result,
                  icon: 'warning',
                })
              }
            }
          )
          .catch(() => {
            setLoading(false)
            setSubmitting(false)

            swal({
              title: 'Log in',
              text: 'Login process has broken',
              icon: 'error',
            })
          })
      }, 1000)
    },
  })

  const handleConfirm = () => {
    setCurStatusAlert(false)
  }

  return (
    <>
      <SweetAlert
        show={curStatusAlert}
        warning
        confirmBtnText={t('LOGIN_ALERT_BTN_TEXT')}
        confirmBtnBsStyle='warning'
        title={t('LOGIN_ALERT_TITLE')}
        onConfirm={handleConfirm}
        closeOnClickOutside={true}
      >
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT1')}</span>
        <br />
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT2')}</span>
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT3')}</span>
      </SweetAlert>
      <div>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Welcome to Infinity</h1>
          </div>
          {/* begin::Heading */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Staff Name</label>
            <input
              placeholder='User Name'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                {/* end::Label */}
                {/* begin::Link */}
                <Link
                  to='/staff/forgotpassword'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Forgot Password ?
                </Link>
                {/* end::Link */}
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                style={{marginRight: '10px'}}
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <i
                className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                id='togglePassword'
                style={{cursor: 'pointer'}}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              // disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </>
  )
}
