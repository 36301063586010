import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {FallbackView} from '../pages/FallbackView'

export function PrivateRoutes() {
  const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'))
  const Products = lazy(() => import('../modules/products/ProductPage'))
  const NetworkPage = lazy(() => import('../modules/network/NetworkPage'))
  const EwalletPage = lazy(() => import('../modules/ewallet/EwalletPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const SystemPage = lazy(() => import('../modules/system/SystemPage'))
  const WebshopPage = lazy(() => import('../modules/webshop/WebshopPage'))
  const PaymentPage = lazy(() => import('../modules/personalorder/PaymentPage'))
  const StaffMembersPage = lazy(() => import('../modules/staffmember/StaffMemberPage'))
  const CommissionPage = lazy(() => import('../modules/commission/CommissionPage'))
  const TeamListPage = lazy(() => import('../modules/teamlist/TeamListPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/products' component={Products} />
        <Route path='/network' component={NetworkPage} />
        <Route path='/ewallet' component={EwalletPage} />
        <Route path='/account' component={AccountPage} />
        <Route path='/system' component={SystemPage} />
        <Route path='/webshop' component={WebshopPage} />
        <Route path='/personalorder' component={PaymentPage} />
        <Route path='/commission' component={CommissionPage} />
        <Route path='/staffmembers/' component={StaffMembersPage} />
        <Route path='/teamlist/' component={TeamListPage} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect from='/staff' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />

        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
