import React, {useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import swal from 'sweetalert'

import {RootState} from '../../setup'
import * as authUser from './auth/redux/AuthRedux'
import {compareSecureCode} from './auth/redux/AuthCRUD'
import {removeAllSession} from '../../config/config'
import {useTranslation} from 'react-i18next'

export default function Secure() {
  const {t} = useTranslation()

  const dispatch = useDispatch()
  const [curSecureCode, setSecureCode] = useState('')
  let userId = useSelector<RootState>(({auth}) => auth.id, shallowEqual) as string
  if (userId === undefined && localStorage.getItem('id'))
    userId = localStorage.getItem('id') as string

  const handleConfirm = () => {
    if (curSecureCode.length !== 6) {
      swal({
        title: t('TEXT_SECURE'),
        text: t('SECURE_CONTENT_1'),
        icon: 'warning',
      })
    } else {
      compareSecureCode(curSecureCode)
        .then((data: any) => {
          if (data?.data?.result === 'success') {
            localStorage.setItem('restriction_flag', '1')
            localStorage.setItem('confirm_flag', '1')
            localStorage.setItem('security_flag', '0')

            dispatch(
              authUser.actions.updateUser(
                data?.data?.token['user_name'],
                data?.data?.token['id'],
                data?.data?.token['email'],
                data?.data?.token['first_name'],
                data?.data?.token['second_name'],
                data?.data?.token['birth'],
                data?.data?.token['streetaddress'],
                data?.data?.token['suite'],
                data?.data?.token['postalcode'],
                data?.data?.token['cityaddress'],
                data?.data?.token['country'],
                data?.data?.token['timezone'],
                data?.data?.token['photo_url'],
                data?.data?.token['security_flag'],
                data?.data?.token['restriction_flag'],
                data?.data?.token['confirm_flag'],
                data?.data?.token['cryptowallet'],
                '0',
                0
              )
            )
          } else {
            swal({
              title: t('TEXT_SECURE'),
              text: data?.data?.result,
              icon: 'warning',
            })
          }
        })
        .catch(() => {
          swal({
            title: t('TEXT_SECURE'),
            text: t('SECURE_CONTENT_2'),
            icon: 'error',
          })
        })
    }
  }

  const handleLogin = () => {
    removeAllSession()
    window.location.href = '/auth/login'
  }

  return (
    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
      <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>{t('TEXT_SECURE')} ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>{t('SECURE_CONTENT_3')}</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('SECURE_CONTENT_4')}</label>
          <input
            type='email'
            placeholder={t('SECURE_CONTENT_5')}
            className='form-control form-control-lg form-control-solid'
            onChange={(event) => setSecureCode(event.currentTarget.value as string)}
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={handleConfirm}
          >
            {t('BTN_TEXT_CONFIRM')}
          </button>
          {/* <Link to='/auth/login'> */}
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            onClick={handleLogin}
          >
            {t('BTN_TEXT_LOGIN')}
          </button>
          {/* </Link> */}
        </div>
        {/* end::Form group */}
      </div>
    </div>
  )
}
