import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store from './setup/redux/Store'

// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import {App} from './app/App'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'

const {PUBLIC_URL} = process.env
_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <App basename={PUBLIC_URL} />
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
