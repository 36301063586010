import clsx from 'clsx'
import axios from 'axios'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import React, {useState, useEffect} from 'react'
import {shallowEqual, useSelector, connect} from 'react-redux'

import './header.css'
import {RootState} from '../../../../setup'
import HeaderUserMenu from './HeaderUserMenu'
import {AppState} from '../../../../../src/setup/redux/Store'
import {decryptPermissionStatus} from '../../../../config/utils'
import {
  BACKEND_API_URL,
  BACKEND_API_V2,
  BACKEND_UPLOAD_URL,
  removeAllSession,
} from '../../../../config/config'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

interface TopbarProps {
  curInspectorMode: boolean
  languages: any
  curInsepctorId: any
  curUserPhotoUrl: any
}

const Topbar: React.FC<TopbarProps> = ({
  curInspectorMode,
  languages,
  curInsepctorId,
  curUserPhotoUrl,
}) => {
  let inspectorPhotoUrl = useSelector<RootState>(
    ({auth}) => auth.inspector_photo_url,
    shallowEqual
  ) as string

  let permissionVal = 0
  let permissionStatus = localStorage.getItem('data') as string
  if (permissionStatus) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }
  let tokenValue = localStorage.getItem('token')

  const history = useHistory()
  const {i18n} = useTranslation()
  const [showToggleMenu, setShowToggleMenu] = useState(false)
  const [curLanguages, setCurLanguages] = useState<Array<any>>([])
  const [curViewLanguageBar, setCurViewLanguageBar] = useState(false)
  const [curPhotoURL, steCurPhotoURL] = useState('')

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value as string) {
      case 'en':
        i18n.changeLanguage('en')
        break
      case 'ge':
        i18n.changeLanguage('ge')
        break
      case 'es':
        i18n.changeLanguage('es')
        break
      case 'pl':
        i18n.changeLanguage('pl')
        break
      case 'ru':
        i18n.changeLanguage('ru')
        break

      case 'ct':
        i18n.changeLanguage('ct')
        break
    }
  }

  const hnadleUserToggle = () => {
    setShowToggleMenu(!showToggleMenu)
  }

  const handleClickAway = () => {
    if (showToggleMenu === true) setShowToggleMenu(false)
  }

  useEffect(() => {
    getStatusShowLanguage()
    // getLanguage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curInspectorMode])

  useEffect(() => {
    getInitInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curUserPhotoUrl, curInspectorMode])

  // useEffect(() => {
  //   getLanguage()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [languages])

  const getInitInformation = async () => {
    if (permissionVal === 1) {
      const userData = await axios.get(`${BACKEND_API_URL}/getStaff`, {
        headers: {'x-access-token': `${tokenValue}`},
      })

      const {auth} = userData?.data
      if (auth === false) {
        removeAllSession()
        history.push('/staff/login')
      } else if (userData?.data !== undefined && userData?.data !== null) {
        steCurPhotoURL(userData?.data?.photo_url)
      }
    } else {
      const userData = await axios.get(
        `${BACKEND_API_URL}/getUser?inspectorId=${curInsepctorId}&inspectorMode=${curInspectorMode}`,
        {
          headers: {'x-access-token': `${tokenValue}`},
        }
      )

      const {auth} = userData?.data
      if (auth === false) {
        removeAllSession()
        history.push('/auth/login')
      } else if (userData?.data !== undefined && userData?.data !== null) {
        steCurPhotoURL(userData?.data?.photo_url)
      }
    }
  }

  const getLanguage = async () => {
    if (languages?.length === 0) {
      const response = await axios.get<any>(`${BACKEND_API_URL}/getLanguageSetting`, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {result, token, auth} = response?.data

      if (auth === false) {
        removeAllSession()
        history.push('/auth/login')
      } else if (result === 'success' && token?.length > 0) {
        let tempDatas = []
        for (let i = 0; i < token?.length; i++) {
          if (token[i]?.status === 1) tempDatas.push(token[i])
        }
        setCurLanguages(tempDatas)
      }
    } else {
      setCurLanguages(languages)
    }
  }

  const getStatusShowLanguage = async () => {
    if (permissionVal === 1) {
      setCurViewLanguageBar(true)
      setLanguageData()
    } else {
      await axios
        .get(`${BACKEND_API_V2}/user/languages`, {
          headers: {'x-access-token': `${tokenValue}`},
        })
        .then(({data}): void => {
          if (data?.multiLanguage) {
            setCurViewLanguageBar(true)
            setLanguageData(Boolean(data?.enable_clear_text))
          } else {
            setCurViewLanguageBar(false)
            setCurLanguages([])
          }
        })
        .catch(() => {
          setCurViewLanguageBar(false)
          setCurLanguages([])
        })
    }
  }

  const setLanguageData = (enableClearText?: boolean) => {
    let tempDatas = []

    let engData = {
      name: 'English',
      status: 1,
      symbol: 'en',
    }
    let esData = {
      name: 'Spanish',
      status: 1,
      symbol: 'es',
    }
    let deData = {
      name: 'German',
      status: 1,
      symbol: 'ge',
    }
    let plData = {
      name: 'Polish',
      status: 1,
      symbol: 'pl',
    }
    let ruData = {
      name: 'Russian',
      status: 1,
      symbol: 'ru',
    }
    let clearTextData = {
      name: 'Clear Text',
      status: 1,
      symbol: 'ct',
    }

    tempDatas.push(engData)
    tempDatas.push(esData)
    tempDatas.push(deData)
    tempDatas.push(plData)
    tempDatas.push(ruData)

    if (enableClearText) tempDatas.push(clearTextData)

    setCurLanguages(tempDatas)
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Language */}
      {curViewLanguageBar === true && (
        <select
          className='form-select form-select-solid fw-bolder me-1'
          onChange={handleChangeLanguage}
          value={i18n.language}
        >
          {curLanguages.map((item, key) => (
            <option value={item.symbol} key={key}>
              {item.name}
            </option>
          ))}
        </select>
      )}
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigge='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={hnadleUserToggle}
        >
          <img
            src={
              curInspectorMode === true && inspectorPhotoUrl !== undefined
                ? ((BACKEND_UPLOAD_URL + inspectorPhotoUrl) as string)
                : curInspectorMode === false && curPhotoURL !== undefined
                ? ((BACKEND_UPLOAD_URL + curPhotoURL) as string)
                : ''
            }
            alt='avatar'
          />
          {/* <img src={(BACKEND_UPLOAD_URL + photo_url) as string} alt='avatar' /> */}
        </div>
        <HeaderUserMenu handleClickAway={handleClickAway} showToggleMenu={showToggleMenu} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  languages: state.accout.languages,
  curInsepctorId: state.auth.inspector_id,
  curUserPhotoUrl: state.auth.photo_url,
})

export default connect(mapStateToProps)(Topbar)
