/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import axios from 'axios'
import swal from 'sweetalert'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import React, {useEffect, useState} from 'react'
import {useDispatch, connect} from 'react-redux'

import './header.css'
import Topbar from './Topbar'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AppState} from '../../../../setup/redux/Store'
import {decryptPermissionStatus} from '../../../../config/utils'
import * as loginUser from '../../../../app/modules/auth/redux/AuthRedux'
import {BACKEND_UPLOAD_URL, BACKEND_API_URL, removeAllSession} from '../../../../config/config'
import {useSubscriptionsContext} from '../../../../context/SubscriptionsContext'

interface Props {
  insepctorMode: any
  insepctorUserName: any
}

function HeaderWrapper({insepctorMode, insepctorUserName}: Props) {
  const subscriptions = useSubscriptionsContext()

  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {classes, attributes} = useLayout()
  const [curInspectorMode, setCurInspectorMode] = useState(false)
  const [curInspectorUsername, setCurInspectorUsername] = useState('')

  let permissionVal = 0
  let permissionStatus = localStorage.getItem('data') as string
  if (permissionStatus) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }
  let tokenValue = localStorage.getItem('token')

  const history = useHistory()
  const [curSelUsername, setCurSelUsername] = useState('username')
  const [curSearch, setCurSearch] = useState('')
  const [curUserLists, setCurUserLists] = useState<Array<any>>([])

  // View Inspector User List Modal States
  const [backStyle, setBackStyle] = useState('')
  const [inspectorModeStyle, setInspectorView] = useState('modal')

  const inspectorStyle = {
    height: '80px',
    backgroundColor: '#0d6efd',
  }
  const unInspectorStyle = {
    height: '80px',
    backgroundColor: 'white',
  }

  useEffect(() => {
    if (insepctorMode && insepctorMode === 1) {
      setCurInspectorMode(true)
      setCurInspectorUsername(insepctorUserName)
    }
  }, [insepctorMode])

  const setInspectorMode = (status: boolean) => {
    setCurInspectorMode(status)
  }

  const setInspectorUsername = (selUsername: string) => {
    setCurInspectorUsername(selUsername)
  }

  const handleDisableInspector = () => {
    setCurInspectorUsername('')
    setCurInspectorMode(false)

    dispatch(loginUser.actions.insepctorDisable(0))
  }

  const handleSearchUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurSearch(event.target.value)

    let req = {}
    if (permissionVal === 0)
      req = {
        search_text: event.target.value,
      }
    else
      req = {
        search_text: event.target.value,
      }

    let apiUrl = 'inspector/filterUserList'
    if (permissionVal === 1) apiUrl = 'staff/inspector/filterUserList'

    const permissionData = await axios.post(`${BACKEND_API_URL}/${apiUrl}`, req, {
      headers: {'x-access-token': `${tokenValue}`},
    })
    const {result, token, auth} = permissionData.data
    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (result === 'success') {
      if (token?.length > 0) {
        let tempDatas = []
        for (let i = 0; i < token?.length; i++) {
          let tempData = {
            index: i,
            id: token[i].id,
            username: token[i].user_name,
            firstname: token[i].first_name,
            secondname: token[i].second_name,
            email: token[i].email,
            photoUrl: token[i].photo_url,
            checked: false,
          }

          tempDatas.push(tempData)
        }
        setCurUserLists(tempDatas)
      } else {
        setCurUserLists([])
      }
    }
  }

  const handleViewInspector = async () => {
    setCurSearch('')

    let apiUrl = 'inspector/getUserList'
    if (permissionVal === 1) apiUrl = 'staff/inspector/getUserList'

    const permissionData = await axios.post(
      `${BACKEND_API_URL}/${apiUrl}`,
      {},
      {
        headers: {'x-access-token': `${tokenValue}`},
      }
    )
    const {result, token, auth} = permissionData.data
    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (result === 'success') {
      if (token?.length > 0) {
        let tempDatas = []
        for (let i = 0; i < token?.length; i++) {
          let tempData = {
            index: i,
            id: token[i].id,
            username: token[i].user_name,
            firstname: token[i].first_name,
            secondname: token[i].second_name,
            email: token[i].email,
            photoUrl: token[i].photo_url,
            checked: false,
          }

          tempDatas.push(tempData)
        }
        setCurUserLists(tempDatas)
      } else {
        setCurUserLists([])
      }
    }

    setCurSelUsername('username')
    setBackStyle('modal-backdrop show ')
    setInspectorView('modal show billing-address-modal-show')
  }
  const handleModalClose = () => {
    setBackStyle('')
    setInspectorView('modal')
  }

  const handleSelUser = (index: number) => {
    let tempDatas = []

    for (let i = 0; i < curUserLists.length; i++) {
      let tempData = {
        index: i,
        id: curUserLists[i].id,
        username: curUserLists[i].username,
        firstname: curUserLists[i].firstname,
        secondname: curUserLists[i].secondname,
        email: curUserLists[i].email,
        photoUrl: curUserLists[i].photoUrl,
        checked: false,
      }

      if (i === index) {
        tempData.checked = true
        setCurSelUsername(curUserLists[i].username)
      }

      tempDatas.push(tempData)
    }
    setCurUserLists(tempDatas)
  }

  const handleModalSelect = async () => {
    let selUserId = -1
    let selUserName = ''

    for (let i = 0; i < curUserLists.length; i++) {
      if (curUserLists[i].checked === true) {
        selUserId = curUserLists[i].id
        selUserName = curUserLists[i].username
        break
      }
    }

    if (selUserId === -1) {
      swal({
        title: t('TOP_INSPECTORMODE_BTN_TEXT'),
        text: t('TEXT_SELECT_USER'),
        icon: 'error',
      })
    } else {
      setInspectorMode(true)

      setBackStyle('')
      setInspectorView('modal')
      setInspectorUsername(selUserName)

      const req = {
        userId: selUserId,
      }
      const permissionData = await axios.post(`${BACKEND_API_URL}/inspector/getUserInfo`, req, {
        headers: {'x-access-token': `${tokenValue}`},
      })
      const {result, token, auth} = permissionData.data

      if (auth === false) {
        removeAllSession()
        history.push('/auth/login')
      } else if (result === 'success') {
        dispatch(
          loginUser.actions.inspectorUser(
            1,
            token?.user_name,
            token?.id,
            token?.email,
            token?.first_name,
            token?.second_name,
            token?.birth,
            token?.streetaddress,
            token?.suite,
            token?.postalcode,
            token?.cityaddress,
            token?.country_name,
            token?.country_id,
            token?.timezone,
            token?.photo_url,
            token?.cryptowallet === null || token?.cryptowallet === undefined
              ? ''
              : token?.cryptowallet,
            token?.tv_username === null || token?.tv_username === undefined
              ? ''
              : token?.tv_username,
            token?.whatsapp,
            token?.telegram
          )
        )
      }
    }
  }
  return (
    <>
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
        {...attributes.headerMenu}
        style={curInspectorMode === true ? inspectorStyle : unInspectorStyle}
      >
        <div
          className={clsx(
            classes.headerContainer.join(' '),
            'd-flex align-items-stretch justify-content-between'
          )}
        >
          {/* begin::Aside mobile toggle */}
          <div className='d-flex align-items-center ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-hover-back show-sidebar w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              {curInspectorMode === false ? (
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className='svg-icon-2x mt-1'
                />
              ) : (
                <img
                  src='/media/icons/duotune/abstract/abs015_dark.png'
                  alt='sidebar_avatar'
                  className='w-30px h-30px w-md-40px h-md-40px'
                />
              )}
            </div>
            {subscriptions.affiliate &&
              (curInspectorUsername.length > 0 ? (
                <div className='ms-3 leave-inspectormode align-items-center'>
                  <button
                    className='btn btn-light-primary inspector-btn align-self-center me-3 p-3'
                    onClick={handleDisableInspector}
                  >
                    {t('TOP_UNINSPECTORMODE_BTN_TEXT')}
                  </button>
                  <div className='d-flex justify-content-center'>
                    <span className='fs-5 fw-bolder text-light text-hover-primary text-center'>
                      {t('TOP_INSPECTORMODE_TEXT1')}&nbsp;{curInspectorUsername}
                    </span>
                  </div>
                </div>
              ) : (
                <button
                  className='btn btn-primary inspector-btn align-self-center'
                  onClick={handleViewInspector}
                >
                  {t('TOP_INSPECTORMODE_BTN_TEXT')}
                </button>
              ))}
          </div>

          {/* begin::Wrapper */}
          <div
            className='d-flex align-items-stretch justify-content-between'
            style={{position: 'fixed', top: '18px', right: '10px'}}
          >
            <div className='d-flex align-items-stretch'>
              <Topbar curInspectorMode={curInspectorMode} />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>

      {/* Select Inspector Mode Users */}
      <div className={inspectorModeStyle} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <div className='modal-header pb-0 border-0 justify-content-end'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={handleModalClose}
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' />
                </span>
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0'>
              <div className='text-center mb-13'>
                <h1 className='mb-3'>{t('TOP_INSPECTORMODE_BTN_TEXT')}</h1>
                <div className='text-muted fw-bold fs-5'>{t('TOP_INSPECTORMODEDLG_DESC')}</div>
              </div>
              <div>
                <div className='w-100 position-relative mb-5'>
                  <span className='svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid px-15'
                    name='search'
                    placeholder={t('TOP_INSPECTORMODEDLG_SEARCH_PLACEHOLADER')}
                    value={curSearch}
                    onChange={handleSearchUser}
                  />
                </div>
                <div className='py-5'>
                  <div className='mh-375px scroll-y me-n7 pe-7'>
                    {curUserLists.map((item, key) => (
                      <div key={key}>
                        <div
                          className='rounded d-flex flex-stack bg-active-lighten p-4 cursor-pointer'
                          onClick={() => handleSelUser(item.index)}
                        >
                          <div className='d-flex align-items-center'>
                            <label className='form-check form-check-custom form-check-solid me-5'>
                              <input
                                className='form-check-input'
                                type='radio'
                                checked={item.checked}
                                onChange={() => handleSelUser(item.index)}
                              />
                            </label>
                            <div className='symbol symbol-35px symbol-circle'>
                              {/* <img alt='Pic' src={BACKEND_UPLOAD_URL + item.photoUrl} /> */}
                              <img alt='Pic' src={BACKEND_UPLOAD_URL + 'nophoto.jpg'} />
                            </div>
                            <div className='ms-5 min-w-200px'>
                              <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                {item.firstname + ' ' + item.secondname}
                              </span>
                              <div className='fw-bold text-muted'>{item.email}</div>
                            </div>
                          </div>
                          <div className='ms-2 w-100px'>
                            <span className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                              {item.username}
                            </span>
                          </div>
                        </div>
                        <div className='border-bottom border-gray-300 border-bottom-dashed'></div>
                      </div>
                    ))}
                  </div>
                  <div className='d-flex flex-center mt-15'>
                    <button className='btn btn-active-light me-3' onClick={handleModalClose}>
                      {t('BTN_TEXT_CANCEL')}
                    </button>
                    <button className='btn btn-primary' onClick={handleModalSelect}>
                      {t('TOP_INSPECTORMODE_TEXT1')}&nbsp;{curSelUsername}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={backStyle}></div>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  insepctorMode: state.auth.inspectorMode,
  insepctorUserName: state.auth.inspector_user,
})

export default connect(mapStateToProps, {})(HeaderWrapper)
