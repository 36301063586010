import {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import Slider from 'react-slick'
import {BACKEND_UPLOAD_URL} from '../../../../../config/config'
import {
  addShoppingAddonPlan,
  setWithoutPlanStatus,
} from '../../../../../setup/redux/actions/webshop'
import {AppState} from '../../../../../setup/redux/Store'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'

interface Props {
  setViewStatus: React.Dispatch<React.SetStateAction<number>>
  addons: {
    id: number
    type: string
    product_image: string
    status: string
    card_name: string
    card_description: string
    long_description: string
    checked_attributes: string
    unchecked_attributes: string
    price: number
    discount_flag: number
    discount: number
    subscription_length: number
    max_length: number
    quantity: string
    quantity_ignore_flag: number
    product_volume: number
    rank_volume: number
    hpp_units: number
    hpp_coupons: number
    products: string
    lifebelt_flag: number
    min_base_id: string
  }[]
  addons_addons: {
    min_base_id: string
  }[]
  maxLength?: number
  shoppingCart: any
  plan_id?: number
}

function AddonsPageNew({
  setViewStatus,
  addons,
  addons_addons,
  maxLength = 0,
  shoppingCart,
  plan_id,
}: Props) {
  const {t} = useTranslation()

  const [slideIndex, setSlideIndex] = useState(0)
  const [infoIndex, setInfoIndex] = useState(-1)
  const [selected, setSelected] = useState<number[]>([])
  const dispatch = useDispatch()

  const base_plan_ids = [shoppingCart[0].id]
  if (plan_id && plan_id > 0) {
    base_plan_ids.push(plan_id)
  }

  // get addons_addons ids in array
  let addons_addons_ids: number[] = []
  addons_addons?.forEach((item) => {
    if (item.min_base_id?.length === 0) return
    addons_addons_ids.push(JSON.parse(item.min_base_id))
  })
  // let max_plan_id = 0
  // if (shoppingCart && shoppingCart[0] && shoppingCart[0].id) {
  //   max_plan_id = shoppingCart[0].id
  // }
  // if (plan_id && plan_id > max_plan_id) {
  //   max_plan_id = plan_id
  // }
  let maxLengthAdded = maxLength + (shoppingCart[0]?.subscription_length || 0)
  // filter addons
  addons = addons.filter((item) => {
    if (item.min_base_id?.length === 0 || item.min_base_id === '0') return false
    if (JSON.parse(item.min_base_id).some((num: any) => base_plan_ids?.includes(num))) return true
    return false
  }) // filter addons by requried base plan id
  useEffect(() => {
    if (maxLengthAdded === 0 || addons.length === 0) {
      dispatch(addShoppingAddonPlan([]))
      setViewStatus(1.1)
    }
  }, [maxLengthAdded])
  let slider: any
  const nextSlider = () => {
    slider.slickNext()
  }
  const prevSlider = () => {
    slider.slickPrev()
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow nextSlider={nextSlider} />,
    prevArrow: <SamplePrevArrow prevSlider={prevSlider} />,
    beforeChange: (current: any, next: any) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  const handleSelect = (index: number) => {
    if (selected.includes(index)) {
      setSelected((v) => v.filter((item) => item !== index))
    } else {
      setSelected((v) => [...v, index])
    }
  }
  const handleContinue = () => {
    const data: any[] = []
    const addon_ids: number[] = []
    if (selected.length > 0) {
      selected.forEach((item) => {
        addon_ids.push(addons[item].id)
        if (!addons[0].card_name) return
        data.push(addons[item])
      })
      if (addons_addons_ids?.flat().some((num) => addon_ids.includes(num))) {
        setViewStatus(1.1)
      }
      // else if (addons_addons_ids.flat().includes(base_plan_ids)) {
      //   setViewStatus(1.1)
      // }
      else if (addons_addons_ids?.some((num) => base_plan_ids.includes(num))) {
        setViewStatus(1.1)
      } else {
        setViewStatus(1.1)
      }
      // if (!addons_addons_ids.flat().some(num => addon_ids.includes(num))) {
      //   setViewStatus(2)
      // }
      dispatch(setWithoutPlanStatus(0))
    }
    if (selected.length === 0) {
      setViewStatus(1.1)
    }
    dispatch(addShoppingAddonPlan(data))
  }
  return (
    <>
      <div className='card p-lg-5 shadow-sm'>
        <div className='d-flex flex-column'>
          <div className='d-flex position-relative'>
            <div className='mb-13 w-100 text-center' style={{marginTop: '80px'}}>
              <img alt='Logo' src='/media/logos/logo.svg' className='h-60px plan-avatar' />
              <h1 className='fs-2hx fw-bolder mb-5'>{t('PERSONALORDER_CONTENT_23')}</h1>
              <div className='basic-plan-desc fw-bold fs-5'>
                {t('WEBSHOP_BASEPLAN_CONTENT1')}&nbsp;
                <a
                  className='menu-link without-sub'
                  href='https://be-infinity.com/pricing_policy'
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  <span className='link-primary fw-bolder'>{t('WEBSHOP_BASEPLAN_CONTENT2')}</span>.
                </a>
              </div>
            </div>
          </div>
          <div className='row g-10'>
            <Slider ref={(c) => (slider = c)} {...settings}>
              {addons && addons.length > 0 ? (
                addons.map((item, key) => (
                  <div className='slide-item' key={key}>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex align-items-center position-relative flex-column rounded-3 bg-light bg-opacity-75 plan-item'>
                        <button
                          className='card-detail'
                          style={{background: 'transparent'}}
                          onClick={() => setInfoIndex(key)}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <KTSVG path={'/media/icons/duotune/general/gen046.svg'} />
                          </span>
                        </button>
                        <div className='mb-7 text-center webshop-header'>
                          <h1 className='text-dark mb-5 h-50px d-flex justify-content-center align-items-center fw-boldest'>
                            {item?.card_name}
                          </h1>
                          <div className='text-type1 fw-bold'>{item?.card_description}</div>
                          {item?.discount_flag === 1 ? (
                            <div className='plan-item-price'>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span
                                  className='fs-2x fw-bolder text-primary'
                                  style={{textDecoration: 'line-through'}}
                                >
                                  {item?.price}
                                </span>
                              </div>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span className='fs-3x fw-bolder text-primary'>
                                  {item?.discount}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className='text-center plan-item-discount'>
                              <span className='mb-2 text-primary'>$</span>
                              <span className='fs-3x fw-bolder text-primary'>{item?.price}</span>
                            </div>
                          )}
                        </div>
                        <div className='w-100 mb-10 webshop-content'>
                          {item?.subscription_length > 0 && item.max_length !== 0 && (
                            <select
                              className='form-select form-select-solid basic-addon-customselect mb-5'
                              data-control='select2'
                              data-placeholder={t('TEXT_SELECT_OPTION')}
                              data-hide-search='true'
                              value={item.subscription_length}
                              onChange={(e) =>
                                (item.subscription_length = parseInt(e.target.value))
                              }
                            >
                              {[
                                ...Array.from(
                                  Array(
                                    maxLengthAdded > item.max_length
                                      ? item.max_length
                                      : maxLengthAdded
                                  ).keys()
                                ),
                              ].map((period: number, k: number) => (
                                <option value={k} key={k}>
                                  {period} {t('TEXT_MONTH')}
                                  {period > 1 ? 's' : ''}
                                </option>
                              ))}
                            </select>
                          )}
                          {item?.subscription_length === 0 && (
                            <input
                              type='text'
                              className='form-control'
                              placeholder={t('WEBSHOP_BASEPLAN_TITLE4')}
                              defaultValue='Lifetime'
                              disabled
                            />
                          )}
                          {item?.max_length === 0 && item?.subscription_length > 0 && (
                            <input
                              type='text'
                              className='form-control'
                              placeholder={t('WEBSHOP_BASEPLAN_TITLE4')}
                              defaultValue={`${item.subscription_length} Month${
                                item.subscription_length > 1 ? `s` : ``
                              }`}
                              disabled
                            />
                          )}
                        </div>
                        <div className='w-100 mb-10 webshop-content'>
                          {item?.checked_attributes.length > 0 &&
                            item?.checked_attributes.split(';').map((itemCheck: any, i: number) => (
                              <div className='d-flex align-items-center' key={i}>
                                <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                  {itemCheck}
                                </span>
                                <span className='svg-icon svg-icon-1 svg-icon-success'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                  >
                                    <rect
                                      opacity='0.3'
                                      x='2'
                                      y='2'
                                      width='20'
                                      height='20'
                                      rx='10'
                                      fill='currentColor'
                                    />
                                    <path
                                      d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                      fill='currentColor'
                                    />
                                  </svg>
                                </span>
                              </div>
                            ))}
                          {item?.unchecked_attributes.length > 0 &&
                            item?.unchecked_attributes
                              .split(';')
                              .map((itemUnCheck: any, i: number) => (
                                <div className='d-flex align-items-center' key={i}>
                                  <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                    {itemUnCheck}
                                  </span>
                                  <span className='svg-icon svg-icon-1'>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                    >
                                      <rect
                                        opacity='0.3'
                                        x='2'
                                        y='2'
                                        width='20'
                                        height='20'
                                        rx='10'
                                        className='unchecked-style'
                                      />
                                      <rect
                                        x='7'
                                        y='15.3137'
                                        width='12'
                                        height='2'
                                        rx='1'
                                        transform='rotate(-45 7 15.3137)'
                                        className='unchecked-style'
                                      />
                                      <rect
                                        x='8.41422'
                                        y='7'
                                        width='12'
                                        height='2'
                                        rx='1'
                                        transform='rotate(45 8.41422 7)'
                                        className='unchecked-style'
                                      />
                                    </svg>
                                  </span>
                                </div>
                              ))}
                        </div>
                        <button
                          className={`btn btn-sm  ${
                            !selected.includes(key) ? 'btn-primary' : 'btn-secondary'
                          }`}
                          onClick={() => handleSelect(key)}
                        >
                          Add{selected.includes(key) ? 'ed' : ''}
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
            <div className='continue-basic'>
              <button
                className={`btn btn-light me-3 continue-basic-btn ${
                  selected.length > 0 ? 'btn-primary' : 'btn-secondary'
                }`}
                onClick={() => handleContinue()}
              >
                {t('BTN_TEXT_CONTINUE')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {infoIndex !== -1 && <MoreInfo addon={addons[infoIndex]} setState={setInfoIndex} />}
    </>
  )
}

function MoreInfo(props: any) {
  const {t} = useTranslation()
  const {addon, setState} = props
  if (!addon) return <></>
  return (
    <>
      <div
        className='modal show billing-address-modal-show position-fixed'
        tabIndex={-1}
        id='kt_modal_edit_user'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='fw-bolder m-0'>{t('WEBSHOP_BASEPLAN_TITLE2')}</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={() => setState(-1)}
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' />
                </span>
              </div>
            </div>
            <div className='modal-body py-10 px-lg-17'>
              <div
                className='scroll-y me-n7 pe-7'
                id='kt_modal_new_address_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_new_address_header'
                data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
                data-kt-scroll-offset='300px'
              >
                {/* Avatar */}
                <div className='row mb-6 mt-6'>
                  <div className='col-lg-4 image-avatar d-flex align-items-center justify-content-center '>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <div className='image-input-wrapper w-125px h-125px'>
                        <img
                          src={
                            BACKEND_UPLOAD_URL +
                            (addon?.product_image || 'media/files/blank-image.svg')
                          }
                          alt='avatar'
                          style={{width: '100%', height: '100%'}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8 fv-row'>
                    <span className='form-control border-0' style={{fontSize: '20px'}}>
                      {addon?.card_name}
                    </span>
                    <span className='form-control border-0'>{addon?.card_description}</span>
                  </div>
                </div>
                {/* Long Description */}
                <div className='row mb-6'>
                  <div className='col-lg-11 fv-row'>
                    <span
                      className='form-control border-0'
                      dangerouslySetInnerHTML={{__html: addon?.long_description}}
                    />
                  </div>
                </div>
                {/* Period */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('WEBSHOP_BASEPLAN_TITLE3')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('WEBSHOP_ADDONPLAN_TITLE5')}
                      defaultValue={
                        addon?.subscription_length === 0
                          ? 'Lifetime'
                          : addon?.subscription_length === 1
                          ? addon?.subscription_length + ` ${t('TEXT_MONTH')}`
                          : addon?.subscription_length + ` ${t('TEXT_MONTHS')}`
                      }
                      readOnly
                    />
                  </div>
                </div>
                {/* Quantity */}
                {addon?.quantity_ignore_flag === 0 ? (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      {t('WEBSHOP_BASEPLAN_TITLE4')}
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder={t('WEBSHOP_BASEPLAN_TITLE4')}
                        defaultValue={addon?.quantity}
                        readOnly
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* Product Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_2')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_2')}
                      defaultValue={addon?.product_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Rank Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_3')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('PERSONAL_SUBSCRIPTION_CUSTOM_SUBTITLE1_3')}
                      defaultValue={addon?.rank_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Units */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>{t('TEXT_UNITS')}</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('TEXT_UNITS')}
                      defaultValue={addon?.hpp_units}
                      readOnly
                    />
                  </div>
                </div>
                {/* High Performance Pool Coupons */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    {t('WEBSHOP_BASEPLAN_TITLE5')}
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder={t('WEBSHOP_BASEPLAN_TITLE5')}
                      defaultValue={addon?.hpp_coupons}
                      readOnly
                    />
                  </div>
                </div>
                {/* Products */}
                {/* <div className='row mb-6 justify-content-end'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Products</label>
                  {addon?.products && addon?.products.length > 0 ? (
                    addon?.products.map((product: string, i: number) => (
                      <div className='col-lg-8 fv-row mb-5' key={i}>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Products'
                          defaultValue={product}
                          readOnly
                        />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div> */}
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                id='kt_modal_new_address_cancel'
                className='btn btn-primary'
                onClick={() => setState(-1)}
              >
                {t('BTN_TEXT_CLOSE')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type NextProps = {
  nextSlider: () => void
}

type BeforeProps = {
  prevSlider: () => void
}

function SampleNextArrow({nextSlider}: NextProps) {
  return (
    <div onClick={nextSlider} className='slideNextBtn'>
      <img src='/media/icons/duotune/arrows/arr071.svg' alt='next' />
    </div>
  )
}

function SamplePrevArrow({prevSlider}: BeforeProps) {
  return (
    <div onClick={prevSlider} className='slidePrevBtn'>
      <img src='/media/icons/duotune/arrows/arr074.svg' alt='prev' />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  shoppingCart: state.webshop.shoppingCart,
  curUserInfo: state.webshop.curUserInfo,
  sponserUserName: state.webshop.sponserUserName,
  sponsorId: state.webshop.sponsorId,
  sponsorFirstName: state.webshop.sponsorFirstName,
  sponsorSecondName: state.webshop.sponsorSecondName,
})

export default connect(mapStateToProps, {})(AddonsPageNew)
