import {AppActionTypes} from '../actions/action.types'

const initialState: CommissionState = {
  holdingtanks: [],
  totalHoldingtank: 0,
  resultHoldingtank: '',
  firstMember: 0,
  firstVol: 0,
  totalMember: 0,
  totalVol: 0,
  placments: [],
  totalPlacement: 0,
  resultPlacement: '',
  hpCustomers: [],
  totalHPCustomer: 0,
  resultHPCustomer: '',
  rankTeamleads: [],
  totalRankTeamleads: 0,
  resultRankTeamleads: '',
  rankInvoices: [],
  totalRankInvoices: 0,
  resultRankInvoices: '',
  rankTeamInvoices: [],
  totalRankTeamInvoices: 0,
  resultRankTeamInvoices: '',
  unilevelInvoices: [],
  totalUnilevelInvoices: 0,
  resultUnilevelInvoices: '',
  matchingUsers: [],
  totalMatchingUsers: 0,
  resultMatchingUsers: '',
  binaryInvoices: [],
  totalBinaryInvoices: 0,
  resultBinaryInvoices: '',
}

export interface CommissionState {
  holdingtanks: Array<any>
  totalHoldingtank: number
  resultHoldingtank: string
  firstMember: number
  firstVol: number
  totalMember: number
  totalVol: number
  placments: Array<any>
  totalPlacement: number
  resultPlacement: string
  hpCustomers: Array<any>
  totalHPCustomer: number
  resultHPCustomer: string
  rankTeamleads: Array<any>
  totalRankTeamleads: number
  resultRankTeamleads: string
  rankInvoices: Array<any>
  totalRankInvoices: number
  resultRankInvoices: string
  rankTeamInvoices: Array<any>
  totalRankTeamInvoices: number
  resultRankTeamInvoices: string
  unilevelInvoices: Array<any>
  totalUnilevelInvoices: number
  resultUnilevelInvoices: string
  matchingUsers: Array<any>
  totalMatchingUsers: number
  resultMatchingUsers: string
  binaryInvoices: Array<any>
  totalBinaryInvoices: number
  resultBinaryInvoices: string
}

const accountReducer = (
  state: CommissionState = initialState,
  action: AppActionTypes
): CommissionState => {
  switch (action.type) {
    case 'GET_HOLDINGTANK':
      return {
        ...state,
        holdingtanks: action.payload.holdingtanks,
        totalHoldingtank: action.payload.totalHoldingtank,
        resultHoldingtank: action.payload.result,
        firstMember: action.payload.firstMember,
        firstVol: action.payload.firstVol,
        totalMember: action.payload.totalMember,
        totalVol: action.payload.totalVol,
      }
    case 'GET_HOLDINGTANK_ERROR':
      return {
        ...state,
        resultHoldingtank: action.payload.result,
      }
    case 'FILTER_HOLDINGTANK':
      return {
        ...state,
        holdingtanks: action.payload.holdingtanks,
        totalHoldingtank: action.payload.totalHoldingtank,
        resultHoldingtank: action.payload.result,
      }
    case 'GET_PLACEMENT':
      return {
        ...state,
        placments: action.payload.placments,
        totalPlacement: action.payload.totalPlacement,
        resultPlacement: action.payload.result,
      }
    case 'GET_PLACEMENT_ERROR':
      return {
        ...state,
        resultPlacement: action.payload.result,
      }
    case 'FILTER_HPCUSTOMER':
      return {
        ...state,
        hpCustomers: action.payload.hpCustomers,
        totalHPCustomer: action.payload.totalHPCustomer,
        resultHPCustomer: action.payload.result,
      }
    case 'FILTER_HPCUSTOMER_ERROR':
      return {
        ...state,
        resultHPCustomer: action.payload.result,
      }
    case 'FILTER_RANK_TEAMLEAD':
      return {
        ...state,
        rankTeamleads: action.payload.rankTeamleads,
        totalRankTeamleads: action.payload.totalRankTeamleads,
        resultRankTeamleads: action.payload.result,
      }
    case 'FILTER_RANK_TEAMLEAD_ERROR':
      return {
        ...state,
        resultRankTeamleads: action.payload.result,
      }
    case 'FILTER_RANK_INVOICE':
      return {
        ...state,
        rankInvoices: action.payload.rankInvoices,
        totalRankInvoices: action.payload.totalRankInvoices,
        resultRankInvoices: action.payload.result,
      }
    case 'FILTER_RANK_INVOICE_ERROR':
      return {
        ...state,
        resultRankInvoices: action.payload.result,
      }
    case 'FILTER_RANK_TEAM_INVOICE':
      return {
        ...state,
        rankTeamInvoices: action.payload.rankTeamInvoices,
        totalRankTeamInvoices: action.payload.totalRankTeamInvoices,
        resultRankTeamInvoices: action.payload.result,
      }
    case 'FILTER_RANK_TEAM_INVOICE_ERROR':
      return {
        ...state,
        resultRankTeamInvoices: action.payload.result,
      }
    case 'FILTER_UNILEVEL_INVOICE':
      return {
        ...state,
        unilevelInvoices: action.payload.unilevelInvoices,
        totalUnilevelInvoices: action.payload.totalUnilevelInvoices,
        resultUnilevelInvoices: action.payload.result,
      }
    case 'FILTER_UNILEVEL_INVOICE_ERROR':
      return {
        ...state,
        resultUnilevelInvoices: action.payload.result,
      }
    case 'FILTER_MATCHING_USERS':
      return {
        ...state,
        matchingUsers: action.payload.matchingUsers,
        totalMatchingUsers: action.payload.totalMatchingUsers,
        resultMatchingUsers: action.payload.result,
      }
    case 'FILTER_MATCHING_USERS_ERROR':
      return {
        ...state,
        resultMatchingUsers: action.payload.result,
      }
    case 'FILTER_BINARY_INVOICES':
      return {
        ...state,
        binaryInvoices: action.payload.binaryInvoices,
        totalBinaryInvoices: action.payload.totalBinaryInvoices,
        resultBinaryInvoices: action.payload.result,
      }
    case 'FILTER_BINARY_INVOICES_ERROR':
      return {
        ...state,
        resultBinaryInvoices: action.payload.result,
      }

    default:
      return state
  }
}

export default accountReducer
