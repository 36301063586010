/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import axios from 'axios'
import swal from 'sweetalert'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import {DatePicker} from 'react-rainbow-components'

import * as AuthLogin from '../redux/AuthRedux'
import {Timezone} from '../../../../config/data'
import {BACKEND_API_URL, removeAllSession} from '../../../../config/config'
import dateFormatter from '../../../../_metronic/helpers/dateFormatter'

export function ResetUserInfo() {
  let tokenValue = localStorage.getItem('token')

  const history = useHistory()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [curCountries, setCurCountries] = useState<Array<any>>([])

  const [curFirstName, setCurFirstName] = useState('')
  const [curLastName, setCurLastName] = useState('')
  const [curPassword, setCurPassword] = useState('')
  const [curPasswordAlert, setCurPasswordAlert] = useState('Password is required')
  const [curConfirmPassword, setCurConfirmPassword] = useState('')
  const [curConfirmPasswordAlert, setCurConfirmPasswordAlert] = useState(
    'Password confirmation is required'
  )
  const [curCountry, setCurCountry] = useState('')
  const [curCountryId, setCurCountryId] = useState(0)
  const [curStreetAddress, setCurStreetAddress] = useState('')
  const [curSuite, setCurSuite] = useState('')
  const [curPostalCode, setCurPostalCode] = useState('')
  const [curCityAddress, setCurCityAddress] = useState('')
  const [curTimezone, setCurTimezone] = useState('')
  const [curBirth, setCurBirth] = useState('')
  const [curBirthAlert, setCurBirthAlert] = useState('')

  // Legal Terms Flags
  const [curTermsStatus, setCurTermsStatus] = useState(false)
  const [curDisclaimerStatus, setCurDisclaimerStatus] = useState(false)
  const [curEmailStatus, setCurEmailStatus] = useState(false)

  useEffect(() => {
    getInitInformation()
    getCountry()
    getCheckBrith()
    getTermFlag()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitInformation = async () => {
    const userData = await axios.get(
      `${BACKEND_API_URL}/getUser?inspectorId=${0}&inspectorMode=${0}`,
      {
        headers: {'x-access-token': `${tokenValue}`},
      }
    )

    const {auth} = userData?.data
    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (userData?.data !== undefined && userData?.data !== null) {
      setCurFirstName(userData?.data?.first_name)
      setCurLastName(userData?.data?.second_name)
      setCurCountry(userData?.data?.country ? userData?.data?.country : '')
      setCurCountryId(userData?.data?.country_id ? userData?.data?.country_id : 0)
      setCurStreetAddress(userData?.data?.streetaddress)
      setCurSuite(userData?.data?.suite)
      setCurPostalCode(userData?.data?.postalcode)
      setCurCityAddress(userData?.data?.cityaddress)
      setCurTimezone(
        userData?.data?.timezone
          ? userData?.data?.timezone
          : '(GMT-11:00) International Date Line West'
      )
      setCurBirth(
        userData?.data?.birth
          ? dateFormatter(userData?.data?.birth)
              .toLocaleString('en', {timeZone: 'America/New_York'})
              .split(' ')[0]
              .replace(/-/g, '/')
          : new Date()
              .toLocaleString('en', {timeZone: 'America/New_York'})
              .split(' ')[0]
              .replace(/-/g, '/')
      )
    }
  }

  const getCountry = async () => {
    const response = await axios.get(`${BACKEND_API_URL}/staff/getAllowedCountrySettings`, {
      headers: {'x-access-token': `${tokenValue}`},
    })
    const {token, result, auth} = response.data

    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (result === 'success') {
      setCurCountries(token)
    }
  }

  const getCheckBrith = () => {
    let selDate = new Date(dateFormatter(curBirth).toLocaleString('sv-SE').replace(/-/g, '/'))
    let curDate = new Date(new Date().toLocaleString('sv-SE').replace(/-/g, '/'))
    if (curDate.getFullYear() - selDate.getFullYear() <= 18) {
      setCurBirthAlert(`${t('ACCOUNT_OVERVIEW_DESC_BIRTH_ALERT')}`)
    } else {
      setCurBirthAlert('')
    }
  }

  const getTermFlag = async () => {
    const resultData = await axios.post(
      `${BACKEND_API_URL}/getTermsflag`,
      {},
      {
        headers: {'x-access-token': `${tokenValue}`},
      }
    )
    const {token, result, auth} = resultData.data

    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (result === 'success') {
      setCurEmailStatus(token[0]?.newletterflag === 1 ? true : false)
    }
  }
  const saveTermFlag = async () => {
    let termFlagReq = {
      newletterflag: curEmailStatus,
    }
    const resultData = await axios.post(`${BACKEND_API_URL}/saveTermsflag/`, termFlagReq, {
      headers: {'x-access-token': `${tokenValue}`},
    })
    let {auth} = resultData?.data
    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    }
  }

  const handlePassword = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurPassword(event.target.value)

    const regex = new RegExp(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]+).*$/)
    if (event.target.value.length === 0) setCurPasswordAlert('Password is required')
    else if (regex.test(event.target.value) === false)
      setCurPasswordAlert(
        "Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~"
      )
    else setCurPasswordAlert('')
  }

  const handleConfirmPassword = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurConfirmPassword(event.target.value)

    const regex = new RegExp(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]+).*$/)

    if (event.target.value.length === 0)
      setCurConfirmPasswordAlert('Password confirmation is required')
    else if (event.target.value !== curPassword)
      setCurConfirmPasswordAlert("Password and Confirm Password didn't match")
    else if (regex.test(event.target.value) === false)
      setCurConfirmPasswordAlert(
        "Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~"
      )
    else setCurConfirmPasswordAlert('')
  }

  const handleBirth = (curSelDate: Date) => {
    let selDate = new Date(new Date(curSelDate).toLocaleString('sv-SE').replace(/-/g, '/'))
    let curDate = new Date(new Date().toLocaleString('sv-SE').replace(/-/g, '/'))
    if (curDate.getFullYear() - selDate.getFullYear() <= 18) {
      setCurBirthAlert(`${t('ACCOUNT_OVERVIEW_DESC_BIRTH_ALERT')}`)
    } else {
      setCurBirthAlert('')
    }

    setCurBirth(curSelDate.toLocaleString('sv-SE').split(' ')[0])
  }

  const handleContinue = async () => {
    const req = {
      first_name: curFirstName,
      second_name: curLastName,
      password: curPassword,
      country: curCountry,
      countryId: curCountryId,
      streetaddress: curStreetAddress,
      suite: curSuite,
      postalcode: curPostalCode,
      cityaddress: curCityAddress,
      timezone: curTimezone,
      birth: dateFormatter(curBirth).toLocaleString('sv-SE'),
    }

    const resultData = await axios.post(`${BACKEND_API_URL}/updateUserProfile`, req, {
      headers: {'x-access-token': `${tokenValue}`},
    })

    const {result, token, auth} = resultData.data

    if (auth === false) {
      removeAllSession()
      history.push('/auth/login')
    } else if (result === 'success') {
      saveTermFlag()

      dispatch(
        AuthLogin.actions.login(
          0,
          token?.token,
          token?.user,
          token?.id,
          token?.email,
          token?.first_name,
          token?.second_name,
          token?.birth,
          token?.streetaddress,
          token?.suite,
          token?.postalcode,
          token?.cityaddress,
          token?.country_name,
          token?.timezone,
          token?.photo_url,
          token?.cryptowallet,
          token?.security_flag,
          token?.restriction_flag,
          token?.confirm_flag
        )
      )
    } else {
      swal({
        title: 'Update User Information',
        text: 'Login process has brokeAn error occurred while updating user information.',
        icon: 'error',
      })
    }
  }

  const handleChangeCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurCountry(e.target.selectedOptions[0].text)
    setCurCountryId(parseInt(e.target.selectedOptions[0].value))
  }

  return (
    <div className='card p-lg-5 shadow-sm'>
      <div className='d-flex flex-column'>
        <div className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
          <div className='modal-header'>
            <h3 className='fw-bolder m-0'>Personal Information</h3>
          </div>
          <div className='modal-body py-10'>
            <div className='scroll-y me-n7 pe-7'>
              {/* Full Name */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Full Name</label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 mb-3 fv-row'>
                      <input
                        placeholder='First name'
                        type='text'
                        value={curFirstName}
                        onChange={(e) => setCurFirstName(e.target.value)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': curFirstName.length === 0,
                          },
                          {
                            'is-valid': curFirstName.length > 0,
                          }
                        )}
                      />
                      {curFirstName.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>First name is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 mb-3 fv-row'>
                      <input
                        placeholder='Last name'
                        type='text'
                        value={curLastName}
                        onChange={(e) => setCurLastName(e.target.value)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': curLastName.length === 0,
                          },
                          {
                            'is-valid': curLastName.length > 0,
                          }
                        )}
                      />
                      {curLastName.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Last name is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Password */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Password</label>
                <div className='col-lg-9 fv-row'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type={showPasswordConfirm ? 'text' : 'password'}
                      placeholder='Password'
                      style={{marginRight: '10px'}}
                      value={curPassword}
                      onChange={handlePassword}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': curPasswordAlert.length > 0,
                        },
                        {
                          'is-valid': curPasswordAlert.length === 0,
                        }
                      )}
                      // data-tip="Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~ "
                    />
                    <i
                      className={showPasswordConfirm ? 'far fa-eye-slash' : 'far fa-eye'}
                      id='togglePassword'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    />
                  </div>
                  {curPasswordAlert.length > 0 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{curPasswordAlert}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Confirm password */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  Confirm Password
                </label>
                <div className='col-lg-9'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password confirmation'
                      style={{marginRight: '10px'}}
                      value={curConfirmPassword}
                      onChange={handleConfirmPassword}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': curConfirmPasswordAlert.length > 0,
                        },
                        {
                          'is-valid': curConfirmPasswordAlert.length === 0,
                        }
                      )}
                    />
                    <i
                      className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                      id='togglePassword'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {curConfirmPasswordAlert.length > 0 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{curConfirmPasswordAlert}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* UserAddress */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  User Address
                </label>
                <div className='col-lg-9 mb-3'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    data-control='select2'
                    data-placeholder='Select Country...'
                    value={curCountryId}
                    onChange={handleChangeCountry}
                  >
                    {curCountries
                      .filter((c) => c.status === 1)
                      .map((item, i) => (
                        <option value={item?.id} key={i}>
                          {item?.country_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='d-flex'>
                  <div className='col-lg-3'></div>
                  <div className='person-street d-flex'>
                    <div className='person-street-item1 mb-3'>
                      <input
                        type='text'
                        placeholder='Street Address'
                        value={curStreetAddress}
                        onChange={(e) => setCurStreetAddress(e.target.value)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': curStreetAddress.length === 0,
                          },
                          {
                            'is-valid': curStreetAddress.length > 0,
                          }
                        )}
                      />
                      {curStreetAddress.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Street Address is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='person-street-item2 mb-3'>
                      <input
                        type='text'
                        placeholder='Apt/Suite'
                        value={curSuite}
                        onChange={(e) => setCurSuite(e.target.value)}
                        className='form-control form-control-lg form-control-solid is-valid'
                        // className={clsx(
                        //   'form-control form-control-lg form-control-solid',
                        //   {
                        //     'is-invalid': curSuite.length === 0,
                        //   },
                        //   {
                        //     'is-valid': curSuite.length > 0,
                        //   }
                        // )}
                      />
                      {/* {curSuite.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>Apt/Suite is required</span>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='col-lg-3'></div>
                  <div className='person-street d-flex'>
                    <div className='person-zip-item1 mb-3'>
                      <input
                        type='text'
                        placeholder='ZIP/Postal code'
                        value={curPostalCode}
                        onChange={(e) => setCurPostalCode(e.target.value)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': curPostalCode.length === 0,
                          },
                          {
                            'is-valid': curPostalCode.length > 0,
                          }
                        )}
                      />
                      {curPostalCode.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>ZIP/Postal code is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='person-zip-item2 mb-3'>
                      <input
                        type='text'
                        placeholder='City'
                        value={curCityAddress}
                        onChange={(e) => setCurCityAddress(e.target.value)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': curCityAddress.length === 0,
                          },
                          {
                            'is-valid': curCityAddress.length > 0,
                          }
                        )}
                      />
                      {curCityAddress.length === 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>City is required</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Time Zone */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Time Zone</label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    data-control='select2'
                    data-placeholder='Select Timezone...'
                    value={curTimezone}
                    onChange={(event) => setCurTimezone(event.target.selectedOptions[0].text)}
                  >
                    {Timezone.map((item, i) => (
                      <option value={Timezone[i]} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Birthday */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Birthday</label>
                <div className='col-lg-9'>
                  <div className='col'>
                    <div className='fv-row mb-9'>
                      <DatePicker
                        formatStyle='large'
                        value={curBirth}
                        onChange={(curSelDate) => handleBirth(curSelDate)}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': curBirthAlert.length > 0},
                          {
                            'is-valid': curBirthAlert.length === 0,
                          }
                        )}
                      />
                      {curBirthAlert.length > 0 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{curBirthAlert}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mb-10 px-10'>
            <h3 className='mb-3'>{t('TEXT_LEGALTERM_TITLE')}</h3>
            <div className='mb-0'>
              <div className='form-check form-check-custom form-check-solid align-items-start mb-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  style={{border: '1px solid #0e0e122e'}}
                  checked={curTermsStatus}
                  onChange={(e) => setCurTermsStatus(e.target.checked)}
                />
                <label
                  className='form-check-label fs-4'
                  dangerouslySetInnerHTML={{__html: t('TEXT_LEGALTERM_CONTENT1')}}
                />
              </div>
              <div className='form-check form-check-custom form-check-solid align-items-start mb-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  style={{border: '1px solid #0e0e122e'}}
                  checked={curDisclaimerStatus}
                  onChange={(e) => setCurDisclaimerStatus(e.target.checked)}
                />
                <label
                  className='form-check-label fs-4'
                  dangerouslySetInnerHTML={{__html: t('TEXT_LEGALTERM_CONTENT2')}}
                />
              </div>
              <div className='form-check form-check-custom form-check-solid align-items-start mb-2'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  style={{border: '1px solid #0e0e122e'}}
                  checked={curEmailStatus}
                  onChange={(e) => setCurEmailStatus(e.target.checked)}
                />
                <label
                  className='form-check-label fs-4'
                  dangerouslySetInnerHTML={{__html: t('TEXT_LEGALTERM_CONTENT3')}}
                />
              </div>
            </div>
          </div>
          <div className='modal-footer flex-center'>
            <button
              type='submit'
              className='btn btn-primary'
              id='kt_account_profile_details_submit'
              disabled={
                curFirstName.length === 0 ||
                curLastName.length === 0 ||
                curPasswordAlert.length > 0 ||
                curConfirmPasswordAlert.length > 0 ||
                curStreetAddress.length === 0 ||
                // curSuite.length === 0 ||
                curPostalCode.length === 0 ||
                curCityAddress.length === 0 ||
                curBirthAlert.length > 0 ||
                curTermsStatus === false ||
                curDisclaimerStatus === false
              }
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
