import {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {BACKEND_API_V2} from '../config/config'

const SC = createContext<Subs>({
  addons: [],
  getSubscriptions: () => new Promise(() => {}),
  addons_addons: [],
} as Subs)

interface Subs {
  affiliate?: number
  base?: number
  addons: Array<number>
  addons_addons: Array<number>
  getSubscriptions: () => Promise<void>
}

export default function SubscriptionsContext({children}: {children: React.ReactNode}) {
  const [subscriptions, setSubscriptions] = useState<Subs>({
    addons: [],
    getSubscriptions,
    addons_addons: [],
  })

  async function getSubscriptions() {
    const token = localStorage.getItem('token')
    if (!token) return
    const res = await fetch(`${BACKEND_API_V2}/user/subscriptions`, {
      headers: {
        'x-access-token': token || '',
      },
    })
    const data = await res.json()
    if (data?.status === 'success') {
      setSubscriptions({...data?.subscriptions, getSubscriptions})
    }
  }

  useEffect(() => {
    getSubscriptions()
  }, [])
  return <SC.Provider value={subscriptions}>{children}</SC.Provider>
}
export function useSubscriptionsContext() {
  return useContext(SC)
}
