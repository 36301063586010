/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useReducer, Dispatch} from 'react'
import axios from 'axios'
import {useParams} from 'react-router'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'

import '../webshop/webshop.css'
import {Baseplan} from './Baseplan'
import BaseAddon from './BaseAddon'
import Affiliateplan from './Affiliateplan'
import {Personinfo} from './personinfo'
import PaymentInfo from './paymentinfo'
import {FinalPage} from './finalpage'
import {ErrorUser} from './erroruser'
import {setUserSponserUserName} from '../../../setup/redux/actions/webshop'
import {
  BACKEND_API_URL,
  removeAllSession,
  DEFAULTTOKEN,
  BACKEND_API_V2,
} from '../../../config/config'
import {FallbackView} from '../../pages/FallbackView'
import {
  getCountries,
  getPaymentSettings,
  getPlans,
} from '../personalorder/components/newComponents/getShopData'
import AddonsPageNew from '../personalorder/components/newComponents/AddonsPage'
import AddonsAddonsPage from '../personalorder/components/newComponents/AddonsAddonsPage'
import {useTranslation} from 'react-i18next'

const defaultState = {
  paymentSettings: [],
  currencies: [],
  countrieSettings: [],
  basePlans: [],
  addons: [],
  addons_addons: [],
  affiliatePlans: [],
  expiration: {expiration_date: 0, extra_months: 0},
}

const reducer = (
  state: any,
  action: {
    payload?: any
    name?: any
    type: any
  }
) => {
  switch (action.type) {
    case 'UPDATE':
      return {...state, [action.name]: action.payload}
    default:
      return state
  }
}

export function ShoppingCart() {
  let {username} = useParams<any>()
  const {i18n} = useTranslation()

  const [state, dispachState] = useReducer(reducer, defaultState)
  const dispatch = useDispatch()
  const history = useHistory()
  const [viewStatus, setViewStatus] = useState(-2)

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value as string) {
      case 'en':
        i18n.changeLanguage('en')
        break
      case 'ge':
        i18n.changeLanguage('ge')
        break
    }
  }

  useEffect(() => {
    i18n.changeLanguage('ge')
    Init(username, setViewStatus, dispatch)
    getPlans('base', 'basePlans')
      .then((data) => {
        dispachState({type: 'UPDATE', name: 'basePlans', payload: data.plans})
      })
      .catch((e) => console.error(e))
    getPlans('affiliate', 'affiliatePlans')
      .then((data) => {
        dispachState({type: 'UPDATE', name: 'affiliatePlans', payload: data.plans})
      })
      .catch((e) => console.error(e))
    getPlans('addons', 'addons')
      .then((data) => {
        dispachState({type: 'UPDATE', name: 'addons', payload: data.plans})
      })
      .catch((e) => console.error(e))
    getPlans('addons_addons', 'addon_addon')
      .then((data) => {
        dispachState({type: 'UPDATE', name: 'addons_addons', payload: data.plans})
      })
      .catch((e) => console.error(e))
    getCountries()
      .then((data) => {
        dispachState({type: 'UPDATE', name: 'countrieSettings', payload: data.operating_countries})
      })
      .catch((e) => console.error(e))
    getPaymentSettings()
      .then((data) => {
        dispachState({
          type: 'UPDATE',
          name: 'paymentSettings',
          payload: data.payment_methods_settings[0],
        })
        dispachState({type: 'UPDATE', name: 'currencies', payload: data.currencies})
      })
      .catch((e) => console.error(e))
  }, [])

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        <div className='ms-auto' style={{paddingRight: '2.5rem', paddingTop: '1rem'}}>
          <select
            className='form-select form-select-solid fw-bolder me-1'
            onChange={handleChangeLanguage}
            value={i18n.language}
          >
            {[
              {symbol: 'ge', name: 'Deutsch'},
              {symbol: 'en', name: 'English'},
            ].map((item, key) => (
              <option value={item.symbol} key={key}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='d-flex justify-content-center align-items-center p-10 h-100'>
        <div className='shopping-main h-100'>
          {/* {state.basePlans.length === 0 && 
              <ReactLoading type='spinningBubbles' color='#3498db' />
            } */}
          {state.basePlans.length === 0 && <FallbackView />}
          {viewStatus === -1 ? (
            <ErrorUser />
          ) : viewStatus === 0 ? (
            <Baseplan setViewStatus={setViewStatus} state={state} />
          ) : viewStatus === 1 ? (
            // <BaseAddon setViewStatus={setViewStatus} state={state} />
            <AddonsPageNew
              setViewStatus={setViewStatus}
              addons={state.addons}
              addons_addons={state.addons_addons}
            />
          ) : viewStatus === 1.1 ? (
            <AddonsAddonsPage setViewStatus={setViewStatus} addons_addons={state.addons_addons} />
          ) : viewStatus === 2 ? (
            <Affiliateplan setViewStatus={setViewStatus} state={state} />
          ) : viewStatus === 3 ? (
            <Personinfo setViewStatus={setViewStatus} state={state} />
          ) : // <PaymentInfo setViewStatus={setViewStatus} state={state} />
          viewStatus === 4 ? (
            <PaymentInfo setViewStatus={setViewStatus} state={state} />
          ) : viewStatus === 5 ? (
            <FinalPage />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

async function Init(
  username: string,
  setViewStatus: React.Dispatch<React.SetStateAction<number>>,
  dispatch: Dispatch<any>
) {
  const res = await fetch(BACKEND_API_V2 + '/webshop/get_sponsor', {
    method: 'POST',
    body: JSON.stringify({username}),
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      console.log(err)
    })
  if (res?.status === 'error') {
    setViewStatus(-1)
    return
  }
  dispatch(setUserSponserUserName(username, res?.id, '', ''))
  setViewStatus(0)
}
