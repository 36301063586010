

export default function dateFormatter(date: string) {
    if (!date) return new Date()
    let parts = date.replace(' ', 'T').split(/[-T:.Z]/);
    if (parts.length < 6) {
        return new Date();
    }
    return new Date(Date.UTC(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[2], 10),
        parseInt(parts[3], 10),
        parseInt(parts[4], 10),
        parseInt(parts[5], 10)
    ));
}