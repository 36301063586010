import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../src/_metronic/helpers'

export function FallbackView() {
  const {t} = useTranslation()

  return (
    <div
      className='splash-screen'
      style={{
        display: 'flex',
        background: 'transparent',
        position: 'relative',
      }}
    >
      <img src={toAbsoluteUrl('/media/logos/logo.svg')} alt='Start logo' style={{margin: '0px'}} />
      <span>{t('TEXT_LOADING')}...</span>
    </div>
  )
}
