import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { UserModel } from '../../../models/UserModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action', // User login action
  Logout: '[Logout] Action', // User logout action
  Register: '[Register] Action', // User register action
  UpdateUser: '[UpdateUser] Action', // User update action
  Secure: '[Secure] Action',
  ResetPassword: '[ResetPassword] Action', // User reset password action
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  AddBillingAddress: '[AddBillingAddress] Action',
  InspectorUser: '[InspectorUser] Action',
  insepctorDisable: '[insepctorDisable] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  token: undefined,
  resetresult: undefined,
  inspectorMode: 0,
  // user: '',
  // token: '',
  // resetresult: '',
  // id: '',
  // email: '',
  // first_name: '',
  // second_name: '',
  // birth: '',
  // streetaddress: '',
  // suite: '',
  // postalcode: '',
  // cityaddress: '',
  // country: '',
  // timezone: '',
  // photo_url: '',
  // security_flag: '',
  // secure_active: '',
  // restriction_flag: '',
  // confirm_flag: '',
  // inspector_user: '',
  // inspector_id: '',
  // inspector_email: '',
  // inspector_first_name: '',
  // inspector_second_name: '',
  // inspector_birth: '',
  // inspector_streetaddress: '',
  // inspector_suite: '',
  // inspector_postalcode: '',
  // inspector_cityaddress: '',
  // inspector_country: '',
  // inspector_timezone: '',
  // inspector_photo_url: '',
}

export interface IAuthState {
  user?: string // User name
  id?: string // User ID
  token?: string // User token information
  email?: string // User email
  first_name?: string // User first name
  second_name?: string // User second name
  birth?: string // User birthday
  streetaddress?: string // User Street address
  suite?: string // User Suite code
  postalcode?: string // User Zip/Postal code
  cityaddress?: string // User city address
  country?: string // User Country
  countryId?: string // User Country Id
  timezone?: string // User timezone
  photo_url?: string // User photo url
  cryptowallet?: string // User Wallet Address
  tvusername?: string // User TradingView Username
  security_flag?: string // User active/deactive flag (1: active, 0: deactive)
  secure_active?: string // Secure page hide or show (1: show, 0: hide)
  restriction_flag?: string // 0: When the secure confirm button is clicked only once. 1: When the secure confirm button is clicked only two.
  confirm_flag?: string // 0: failed to pass secure code, 1: success to pass secure code
  resetresult?: string // User responese text
  inspectorMode?: number // Inspecotr Mode Flag (1: Inspector)
  inspector_user?: string // Inspector User name
  inspector_id?: string // Inspector User ID
  inspector_email?: string // Inspector User email
  inspector_first_name?: string // Inspector User first name
  inspector_second_name?: string // Inspector User second name
  inspector_birth?: string // Inspector User birthday
  inspector_streetaddress?: string // Inspector User Street address
  inspector_suite?: string // Inspector User Suite code
  inspector_postalcode?: string // Inspector User Zip/Postal code
  inspector_cityaddress?: string // Inspector User city address
  inspector_country?: string // Inspector User Country
  inspector_countryId?: string // Inspector User Country Id
  inspector_timezone?: string // Inspector User timezone
  inspector_photo_url?: string // Inspector User photo url
  inspector_cryptowallet?: string // Inspector User Wallet address
  inspector_tvusername?: string // Inspector User TradingView Username
  whatsapp?: string //  User Whatsapp
  telegram?: string //  User Telegram
  inspector_whatsapp?: string // Inspector User Whatsapp
  inspector_telegram?: string // Inspector User Telegram
}

export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['user', 'token'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const inspectorMode = action.payload?.inspectorMode
        const accessToken = action.payload?.token
        const user = action.payload?.user
        const id = action.payload?.id
        const email = action.payload?.email
        const first_name = action.payload?.first_name
        const second_name = action.payload?.second_name
        const birth = action.payload?.birth
        const streetaddress = action.payload?.streetaddress
        const suite = action.payload?.suite
        const postalcode = action.payload?.postalcode
        const cityaddress = action.payload?.cityaddress
        const country = action.payload?.country
        const timezone = action.payload?.timezone
        const photo_url = action.payload?.photo_url
        const cryptowallet = action.payload?.cryptowallet
        const security_flag = action.payload?.security_flag
        const restriction_flag = action.payload?.restriction_flag
        const confirm_flag = action.payload?.confirm_flag
        const whatsapp = action.payload?.whatsapp
        const telegram = action.payload?.telegram
        let secureActive = '0'
        if (security_flag?.toString() === '1') secureActive = '1'

        return {
          inspectorMode,
          accessToken,
          whatsapp,
          telegram,
          user: user,
          id: id,
          email: email,
          first_name: first_name,
          second_name: second_name,
          birth: birth,
          streetaddress: streetaddress,
          suite: suite,
          postalcode: postalcode,
          cityaddress: cityaddress,
          country: country,
          timezone: timezone,
          photo_url: photo_url,
          cryptowallet: cryptowallet,
          security_flag: security_flag,
          restriction_flag: restriction_flag,
          confirm_flag: confirm_flag,
          secure_active: secureActive,
        }
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.token
        return { accessToken, user: undefined }
      }

      case actionTypes.UpdateUser: {
        const user = action.payload?.user
        const id = action.payload?.id
        const email = action.payload?.email
        const first_name = action.payload?.first_name
        const second_name = action.payload?.second_name
        const birth = action.payload?.birth
        const streetaddress = action.payload?.streetaddress
        const suite = action.payload?.suite
        const postalcode = action.payload?.postalcode
        const cityaddress = action.payload?.cityaddress
        const country = action.payload?.country
        const timezone = action.payload?.timezone
        const photo_url = action.payload?.photo_url as string
        const cryptowallet = action.payload?.cryptowallet
        const security_flag = action.payload?.security_flag
        const restriction_flag = action.payload?.restriction_flag
        const confirm_flag = action.payload?.confirm_flag
        const secureActive = action.payload?.secure_active
        const inspectorMode = action.payload?.inspectorMode

        return {
          user: user,
          id: id,
          email: email,
          first_name: first_name,
          second_name: second_name,
          birth: birth,
          streetaddress: streetaddress,
          suite: suite,
          postalcode: postalcode,
          cityaddress: cityaddress,
          country: country,
          timezone: timezone,
          photo_url: photo_url,
          security_flag: security_flag,
          restriction_flag: restriction_flag,
          confirm_flag: confirm_flag,
          cryptowallet: cryptowallet,
          secure_active: secureActive,
          inspectorMode: inspectorMode,
        }
      }

      case actionTypes.ResetPassword: {
        const result = action.payload?.resetresult
        return { result }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined }
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return { ...state, user }
      }

      case actionTypes.InspectorUser: {
        const inspectorMode = action.payload?.inspectorMode
        const user = action.payload?.user
        const id = action.payload?.id
        const email = action.payload?.email
        const first_name = action.payload?.first_name
        const second_name = action.payload?.second_name
        const birth = action.payload?.birth
        const streetaddress = action.payload?.streetaddress
        const suite = action.payload?.suite
        const postalcode = action.payload?.postalcode
        const cityaddress = action.payload?.cityaddress
        const country = action.payload?.country
        const countryId = action.payload?.countryId
        const timezone = action.payload?.timezone
        const photo_url = action.payload?.photo_url
        const cryptowallet = action.payload?.cryptowallet
        const tvusername = action.payload?.tvusername
        const whatsapp = action.payload?.whatsapp
        const telegram = action.payload?.telegram

        return {
          inspectorMode: inspectorMode,
          inspector_user: user,
          inspector_id: id,
          inspector_email: email,
          inspector_first_name: first_name,
          inspector_second_name: second_name,
          inspector_birth: birth,
          inspector_streetaddress: streetaddress,
          inspector_suite: suite,
          inspector_postalcode: postalcode,
          inspector_cityaddress: cityaddress,
          inspector_country: country,
          inspector_countryId: countryId,
          inspector_timezone: timezone,
          inspector_photo_url: photo_url,
          inspector_cryptowallet: cryptowallet,
          inspector_tvusername: tvusername,
          inspector_whatsapp: whatsapp,
          inspector_telegram: telegram,
        }
      }

      case actionTypes.insepctorDisable: {
        const inspectorMode = action.payload?.inspectorMode

        return {
          inspectorMode: inspectorMode,
        }
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (
    inspectorMode: number,
    token: string,
    user: string,
    id: string,
    email: string,
    first_name: string,
    second_name: string,
    birth: string,
    streetaddress: string,
    suite: string,
    postalcode: string,
    cityaddress: string,
    country: string,
    timezone: string,
    photo_url: string,
    cryptowallet: string,
    security_flag: string,
    restriction_flag: string,
    confirm_flag: string
  ) => ({
    type: actionTypes.Login,
    payload: {
      inspectorMode,
      token,
      user,
      id,
      email,
      first_name,
      second_name,
      birth,
      streetaddress,
      suite,
      postalcode,
      cityaddress,
      country,
      timezone,
      photo_url,
      cryptowallet,
      security_flag,
      restriction_flag,
      confirm_flag,
    },
  }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  updateUser: (
    user: string,
    id: string,
    email: string,
    first_name: string,
    second_name: string,
    birth: string,
    streetaddress: string,
    suite: string,
    postalcode: string,
    cityaddress: string,
    country: string,
    timezone: string,
    photo_url: string,
    security_flag: string,
    restriction_flag: string,
    confirm_flag: string,
    cryptowallet: string,
    secure_active: string,
    inspectorMode: number
  ) => ({
    type: actionTypes.UpdateUser,
    payload: {
      user,
      id,
      email,
      first_name,
      second_name,
      birth,
      streetaddress,
      suite,
      postalcode,
      cityaddress,
      country,
      timezone,
      photo_url,
      security_flag,
      restriction_flag,
      confirm_flag,
      cryptowallet,
      secure_active,
      inspectorMode,
    },
  }),
  reset: (resetresult: string) => ({
    type: actionTypes.ResetPassword,
    payload: { resetresult },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user: UserModel) => ({ type: actionTypes.SetUser, payload: { user } }),
  inspectorUser: (
    inspectorMode: number,
    user: string,
    id: string,
    email: string,
    first_name: string,
    second_name: string,
    birth: string,
    streetaddress: string,
    suite: string,
    postalcode: string,
    cityaddress: string,
    country: string,
    countryId: number,
    timezone: string,
    photo_url: string,
    cryptowallet: string,
    tvusername: string,
    whatsapp: string,
    telegram: string,
  ) => ({
    type: actionTypes.InspectorUser,
    payload: {
      inspectorMode,
      user,
      id,
      email,
      first_name,
      second_name,
      birth,
      streetaddress,
      suite,
      postalcode,
      cityaddress,
      country,
      countryId,
      timezone,
      photo_url,
      cryptowallet,
      tvusername,
      whatsapp,
      telegram,
    },
  }),
  insepctorDisable: (inspectorMode: number) => ({
    type: actionTypes.insepctorDisable,
    payload: {
      inspectorMode,
    },
  }),
}

export interface IAccountState {
  allbillingaddress?: Array<Array<string>> // All billing address
}

const initialAccountState: IAccountState = {
  allbillingaddress: undefined,
}

export const accountReducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['allbillingaddress'] },
  (state: IAccountState = initialAccountState, action: ActionWithPayload<IAccountState>) => {
    switch (action.type) {
      case actionTypes.AddBillingAddress: {
        const addBillingAddress = action.payload?.allbillingaddress
        return {
          allbillingaddress: addBillingAddress,
        }
      }

      default:
        return state
    }
  }
)

export const accountActions = {
  addbillingaddress: (newbillingaddress: Array<string>) => ({
    type: actionTypes.AddBillingAddress,
    payload: { newbillingaddress },
  }),
}
