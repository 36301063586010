import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
//language
import {default as EnglishTexts} from './config/translations/en.json'
import {default as GermanyTexts} from './config/translations/de.json'
import {default as SpanishTexts} from './config/translations/es.json'
import {default as PolishTexts} from './config/translations/pl.json'
import {default as RussianTexts} from './config/translations/ru.json'
import {default as ClearTexts} from './config/translations/ct.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: EnglishTexts,
      },
      ge: {
        translation: GermanyTexts,
      },
      es: {
        translation: SpanishTexts,
      },
      pl: {
        translation: PolishTexts,
      },
      ru: {
        translation: RussianTexts,
      },
      ct: {
        translation: ClearTexts,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
