export function isEmptyObject(obj: any) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const numberFormat = (n: any) => {
  if (n) {
    return n.toLocaleString('en-US')
  }
  return 0
}

export const DEFAULTKEY = 1491
export const PRIMARYKEY = 199464

// Encrypt and Decrypt Staff or User Permission (0: User, 1: Staff)
export function encryptPermissionStatus(data: any) {
  let encryptVal = Math.pow((PRIMARYKEY + data) * DEFAULTKEY, 3)

  return encryptVal.toString(16)
}
export function decryptPermissionStatus(curVal: any) {
  let curIntVal = parseInt(curVal, 16)

  let decrypt = Math.round(Math.pow(curIntVal, 1 / 3))
  decrypt = decrypt / DEFAULTKEY - PRIMARYKEY

  return decrypt
}
