import {Link} from 'react-router-dom'
import {useParams} from 'react-router'
import swal from 'sweetalert'

import {deactivateStaffConfirm} from '../../../auth/redux/AuthCRUD'

export function StaffDeactivate() {
  // const location = useLocation()
  // let curURL = location.pathname
  // let curURLArray = curURL.split('/')
  // let curUser = curURLArray.pop() as string
  // curUser = Buffer.from(curUser, 'base64').toString()
  // curUser = curUser.replace('infinity', '')

  let {username} = useParams<any>()
  let curUser = Buffer.from(username, 'base64').toString()
  curUser = curUser.replace('infinity', '')

  const handleConfirm = () => {
    deactivateStaffConfirm(curUser)
      .then(({data: {result}}: any) => {
        if (result === 'success') {
          swal({
            title: 'Deactivate',
            text: 'Your account has been successfully deactivated.',
            icon: 'success',
          })
        } else {
          swal({
            title: 'Deactivate',
            text: result,
            icon: 'warning',
          })
        }
      })
      .catch(() => {
        swal({
          title: 'Deactivate',
          text: 'Deactivate Process Failed',
          icon: 'warning',
        })
      })
  }

  return (
    <div>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Deactivate Account</h1>
        {/* end::Title */}
      </div>

      {/* begin::Form group */}
      <div className='pb-lg-0'>
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Your Account User name</label>
          <input
            type='text'
            readOnly
            value={curUser}
            className='form-control form-control-lg form-control-solid'
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center fv-row'>
          <button
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={handleConfirm}
          >
            <span className='indicator-label'>Confirm</span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
