/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {TFunction, useTranslation} from 'react-i18next'

import {AsideMenuItem} from './AsideMenuItem'
import {AppState} from '../../../../setup/redux/Store'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {decryptPermissionStatus} from '../../../../config/utils'
import {useSubscriptionsContext} from '../../../../context/SubscriptionsContext'
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from 'react-bootstrap-v5'
import {BACKEND_API_V2} from '../../../../config/config'

interface Props {
  insepctorMode: any
}

function AsideMenuMain({insepctorMode}: Props) {
  const subscriptions = useSubscriptionsContext()
  let permissionStatus = localStorage.getItem('data') as string
  let permissionVal = 0
  if (permissionStatus && insepctorMode !== 1) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }

  const {t} = useTranslation()

  useEffect(() => {
    if (insepctorMode === 1) setPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insepctorMode])

  const setPermission = () => {
    permissionVal = 0
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={t('SIDBAR_DASHBOARD')}
        fontIcon='bi-app-indicator'
      />
      {permissionVal === 0 && (
        <AsideMenuItemWithSub
          to='/products'
          title={t('SIDBAR_PRODUCTS_TITLE')}
          icon='/media/icons/duotune/general/gen008.svg'
          fontIcon='bi-person'
        >
          {/* <AsideMenuItem
            to='/products/expertadvisor'
            title={t('SIDBAR_PRODUCTS_EXPERTADVISOR')}
            hasBullet={true}
          /> */}
          <div className='menu-item'>
            <a
              className='menu-link without-sub'
              href='https://infinity-academies.com/'
              target={'_blank'}
            >
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{t('SIDBAR_PRODUCTS_ACADEMY')}</span>
            </a>
          </div>
          <div className='menu-item'>
            <a
              className='menu-link without-sub'
              href='https://aigo.be-infinity.com/'
              target={'_blank'}
            >
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>AiGo by Be Infinity</span>
            </a>
          </div>
        </AsideMenuItemWithSub>
      )}
      <AsideMenuItemWithSub
        to='/account'
        title={t('SIDBAR_ACCOUNT_TITLE')}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        {permissionVal === 0 ? (
          <>
            <AsideMenuItem
              to='/account/overview'
              title={t('SIDBAR_ACCOUNT_OVERVIEW')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/account/security'
              title={t('SIDBAR_ACCOUNT_SECURITY')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/account/billing'
              title={t('SIDBAR_ACCOUNT_BILLING')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/account/payment'
              title={t('SIDBAR_ACCOUNT_PAYMENT')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/account/notification'
              title={t('SIDBAR_ACCOUNT_NOTIFICATION')}
              hasBullet={true}
            />
            <AsideMenuItem to='/account/logs' title={t('SIDBAR_ACCOUNT_LOGS')} hasBullet={true} />
          </>
        ) : (
          <AsideMenuItem
            to='/account/member'
            title={t('SIDBAR_ACCOUNT_USERINFORMATION')}
            hasBullet={true}
          />
        )}
      </AsideMenuItemWithSub>
      {permissionVal === 0 && (
        <AsideMenuItemWithSub
          to='/personalorder'
          title={t('SIDBAR_PERSONALORDER_TITLE')}
          icon='/media/icons/duotune/ecommerce/ecm007.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/personalorder/customsubscription'
            title={t('SIDBAR_PERSONALORDER_SUBSCRIPTION')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/personalorder/custominvoice'
            // title={t('SIDBAR_PERSONALORDER_INVOICE')}
            title={t('SIDBAR_ACCOUNT_PAYMENT')}
            hasBullet={true}
          />
          {/* <AsideMenuItem
            to='/personalorder/custompayment'
            title={t('SIDBAR_ACCOUNT_PAYMENT')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/personalorder/customrefund'
            title={t('SIDBAR_PERSONALORDER_REFUND')}
            hasBullet={true}
          /> */}
        </AsideMenuItemWithSub>
      )}
      <AsideMenuItemWithSub
        to='/ewallet'
        title={t('SIDBAR_EWALLET_TITLE')}
        icon='/media/icons/duotune/finance/fin006.svg'
        fontIcon='bi-person'
      >
        {permissionVal === 0 || insepctorMode === 1 ? (
          <>
            {subscriptions.affiliate && (
              <AsideMenuItem
                to='/ewallet/ewalletcommission'
                title={t('SIDBAR_EWALLET_COMMISSION')}
                hasBullet={true}
              />
            )}
            <AsideMenuItem
              to='/ewallet/ewallettransactions'
              title={t('SIDBAR_EWALLET_SHOPPING')}
              hasBullet={true}
            />
          </>
        ) : permissionVal === 1 && insepctorMode === 0 ? (
          <>
            <AsideMenuItem
              to='/ewallet/ewalletuserbalance'
              title={t('SIDBAR_EWALLET_BALANCE')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/ewallet/ewalletwithdrawal'
              title={t('SIDBAR_EWALLET_WITHDRAWAL')}
              hasBullet={true}
            />
          </>
        ) : (
          <></>
        )}
      </AsideMenuItemWithSub>
      {permissionVal === 1 && (
        <AsideMenuItemWithSub
          to='/commission'
          title={t('SIDBAR_COMMISSION_TITLE')}
          icon='/media/icons/duotune/general/gen032.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/commission/datadisplay'
            title={t('SIDBAR_COMMISSION_DATADISPLAY')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {subscriptions.affiliate && (
        <>
          <AsideMenuItemWithSub
            to='/network'
            title={t('SIDBAR_NETWORK_TITLE')}
            fontIcon='bi-archive'
            icon='/media/icons/duotune/files/fil012.svg'
          >
            <AsideMenuItem
              to='/network/networkunilevel'
              title={t('SIDBAR_NETWORK_UNILEVEL')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/network/networkbinary'
              title={t('SIDBAR_NETWORK_BINARY')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/network/networkholdingtank'
              title={t('SIDBAR_NETWORK_HOLDINGTANK')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/network/networkplacement'
              title={t('SIDBAR_NETWORK_PLACEMENT')}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/teamlist'
            title={t('SIDBAR_TEAMLIST_TITLE')}
            icon='/media/icons/duotune/general/gen008.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/teamlist/rankup'
              title={t('TEAMLIST_NEXTRANKUP')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/teamlist/teamlistuser'
              title={t('SIDBAR_TEAMLIST_USERLIST')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/teamlist/teamlistsubscription'
              title={t('SIDBAR_PERSONALORDER_SUBSCRIPTION')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/teamlist/teamlistinvoice'
              title={t('SIDBAR_PERSONALORDER_INVOICE')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/teamlist/teamlistpayment'
              title={t('SIDBAR_ACCOUNT_PAYMENT')}
              hasBullet={true}
            />
            <AsideMenuItem
              to='/teamlist/teamlistrefund'
              title={t('SIDBAR_PERSONALORDER_REFUND')}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      )}
      {permissionVal === 1 && (
        <AsideMenuItemWithSub
          to='/system'
          title={t('SIDBAR_SYSTEMSETTING_TITLE')}
          icon='/media/icons/duotune/general/gen001.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/system/settingwebshop'
            title={t('SIDBAR_SYSTEMSETTING_WEBSHOP')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/system/methodpayment'
            title={t('SIDBAR_SYSTEMSETTING_PAYMENT')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/system/withdrawalmethods'
            title={t('SIDBAR_SYSTEMSETTING_WITHDRAWAL')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/system/languages'
            title={t('SIDBAR_SYSTEMSETTING_LANGUAGE')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/system/country'
            title={t('SIDBAR_SYSTEMSETTING_COUNTRY')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {insepctorMode === 0 && (
        <AsideMenuItemWithSub
          to='/webshop'
          title={t('SIDBAR_SYSTEMSETTING_WEBSHOP')}
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
          fontIcon='bi-person'
        >
          {permissionVal === 0 ? (
            <AsideMenuItem
              to='/webshop/webshop'
              title={t('SIDBAR_SYSTEMSETTING_WEBSHOP')}
              hasBullet={true}
            />
          ) : (
            <>
              <AsideMenuItem
                to='/webshop/custombaseplan'
                title={t('SIDBAR_WEBSHOP_BASEPLAN')}
                hasBullet={true}
              />
              <AsideMenuItem
                to='/webshop/customaddonplan'
                title={t('SIDBAR_WEBSHOP_ADDON')}
                hasBullet={true}
              />
              {/* <AsideMenuItem
                to='/webshop/customaddonaddonplan'
                title={t('SIDBAR_WEBSHOP_ADDON_ADDON')}
                hasBullet={true}
              /> */}
              <AsideMenuItem
                to='/webshop/customaffiliateplan'
                title={t('SIDBAR_WEBSHOP_AFFILATE')}
                hasBullet={true}
              />
            </>
          )}
        </AsideMenuItemWithSub>
      )}
      {permissionVal === 1 && (
        <AsideMenuItemWithSub
          to='/staffmembers/'
          title={t('SIDBAR_STAFFMEMBER_TITLE')}
          icon='/media/icons/duotune/finance/fin006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/staffmembers'
            title={t('SIDBAR_STAFFMEMBER_TITLE')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <EventTicketSidebarItem /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <RedeemGiftCode t={t} />
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  insepctorMode: state.auth.inspectorMode,
})

export default connect(mapStateToProps)(AsideMenuMain)

function RedeemGiftCode({t}: {t: TFunction<'translation', undefined>}) {
  const [giftCode, setGiftCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleRedeem = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    setSuccessMessage('')
    setErrorMessage('')

    try {
      const response = await fetch(`${BACKEND_API_V2}/webshop/coupon`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token') || '',
        },
        body: JSON.stringify({c: giftCode}),
      }).then((res) => res.json())

      if (response.status) {
        setSuccessMessage('Gift code redeemed successfully!')
      } else if (response.error) {
        setErrorMessage(response.error)
      } else {
        setErrorMessage('An error occurred. Please try again later.')
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.')
    }
    setGiftCode('')
    setLoading(false)
  }

  return (
    <Form className='mb-3' onSubmit={handleRedeem}>
      <Container>
        <Row>
          <Col md={12}>
            <InputGroup>
              <FormControl
                placeholder='Enter gift code'
                aria-label='Enter gift code'
                value={giftCode}
                onChange={(e) => setGiftCode(e.target.value)}
                style={{backgroundColor: '#1b1b28', color: 'white', borderColor: `#A1A5B7`}}
              />
              <Button
                variant='outline-secondary'
                id='button-addon2'
                onClick={handleRedeem}
                disabled={loading || !giftCode.length}
                aria-label='Redeem'
                className='custom-hover-btn'
                title='Redeem gift code'
              >
                <i className='bi bi-box-arrow-in-up fs-4' aria-hidden='true'></i>
              </Button>
            </InputGroup>
            {loading && <p style={{color: '#A1A5B7'}}>Loading...</p>}
            {successMessage && <p style={{color: '#4FCD89'}}>{successMessage}</p>}
            {errorMessage && <p style={{color: '#F1416C'}}>{errorMessage}</p>}
          </Col>
        </Row>
      </Container>
    </Form>
  )
}

function EventTicketSidebarItem() {
  return (
    <div className='menu-item'>
      <a
        className='menu-link without-sub'
        href='https://www.eventbrite.com/e/infinity-unleash-your-potential-2024-tickets-667427953977'
        target={'_blank'}
      >
        <span className='menu-icon'>
          <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_2229_16531)'>
              <path
                d='M4.33333 6.5H15.1667V13H4.33333V6.5ZM17.875 9.75C17.875 10.6475 18.6025 11.375 19.5 11.375V14.625C19.5 15.5225 18.7725 16.25 17.875 16.25H1.625C0.727526 16.25 0 15.5225 0 14.625V11.375C0.897474 11.375 1.625 10.6475 1.625 9.75C1.625 8.85253 0.897474 8.125 0 8.125V4.875C0 3.97753 0.727526 3.25 1.625 3.25H17.875C18.7725 3.25 19.5 3.97753 19.5 4.875V8.125C18.6025 8.125 17.875 8.85253 17.875 9.75ZM16.25 6.22917C16.25 5.78043 15.8862 5.41667 15.4375 5.41667H4.0625C3.61376 5.41667 3.25 5.78043 3.25 6.22917V13.2708C3.25 13.7196 3.61376 14.0833 4.0625 14.0833H15.4375C15.8862 14.0833 16.25 13.7196 16.25 13.2708V6.22917Z'
                fill='#494B71'
              />
            </g>
            <defs>
              <clipPath id='clip0_2229_16531'>
                <rect width='19.5' height='19.5' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </span>
        <span className='menu-title'>Event tickets</span>
        <svg
          stroke='currentColor'
          fill='currentColor'
          stroke-width='0'
          viewBox='0 0 512 512'
          height='1em'
          width='1em'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'></path>
        </svg>
      </a>
    </div>
  )
}
