import {Link} from 'react-router-dom'
import {useParams} from 'react-router'
import swal from 'sweetalert'

import {deactivateConfirm} from '../../auth/redux/AuthCRUD'
import {useEffect, useState} from 'react'
import {BACKEND_API_URL} from '../../../../config/config'

export function Deactivate() {
  // const location = useLocation()
  // let curURL = location.pathname
  // let curURLArray = curURL.split('/')
  // let curUser = curURLArray.pop() as string
  // curUser = Buffer.from(curUser, 'base64').toString()
  // curUser = curUser.replace('infinity', '')

  // let {username} = useParams<any>()
  // let curUser = Buffer.from(username, 'base64').toString()
  // let curUser = username
  // curUser = curUser.replace('infinity', '')
  const [input, setInput] = useState('')
  const [user, setUser] = useState('')
  useEffect(() => {
    fetch(`${BACKEND_API_URL}/getUser`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token') || '',
      },
    }).then(async (res) => setUser((await res.json()).user_name))
  }, [])
  console.log(user)
  const handleConfirm = () => {
    deactivateConfirm()
      .then(({data: {result}}: any) => {
        if (result === 'success') {
          swal({
            title: 'Deactivate',
            text: 'Your account has been successfully deactivated.',
            icon: 'success',
          })
        } else {
          swal({
            title: 'Deactivate',
            text: result,
            icon: 'warning',
          })
        }
      })
      .catch(() => {
        swal({
          title: 'Deactivate',
          text: 'Deactivate Process Failed',
          icon: 'warning',
        })
      })
  }

  return (
    <div>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Deactivate Account: {user}</h1>
        {/* end::Title */}
      </div>

      {/* begin::Form group */}
      <div className='pb-lg-0'>
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>
            Write &apos;CONFIRM&apos; to deactivate your account, this action is irreversible
          </label>
          <input
            type='text'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            placeholder='CONFIRM'
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center fv-row'>
          <button
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={handleConfirm}
            disabled={user === '' || input.toLocaleLowerCase() !== 'confirm'}
          >
            <span className='indicator-label'>Confirm</span>
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
