/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import swal from 'sweetalert'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
// import ReactTooltip from 'react-tooltip'

import {Timezone, TimezoneValue} from '../../../config/data'
import {setUserInformation} from '../../../setup/redux/actions/webshop'
import {BACKEND_API_V2} from '../../../config/config'
import {useTranslation} from 'react-i18next'

const initialValues = {
  username: '',
  firstname: '',
  secondname: '',
  email: '',
  password: '',
  changepassword: '',
  country: '',
  countryId: 0,
  streetaddress: '',
  suite: '',
  cityaddress: '',
  postalcode: '',
  timezone: '(GMT-11:00) International Date Line West',
  birth: '2022-01-01',
}

const curDate = new Date()
const minDate = new Date(curDate.getFullYear() - 18, curDate.getMonth(), curDate.getDate())
const registrationSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('User Name is required'),
  firstname: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  secondname: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Second name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(24, 'Maximum 24 symbols')
    .test(
      'Password Warning',
      "Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~",
      (password?: string | '') => {
        return /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]+).*$/.test(
          password ? password : ''
        )
      }
    )
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  country: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Country is required'),
  countryId: Yup.number()
    .min(0, 'Please select the correct country')
    .max(500, 'Please select the correct country')
    .required('Country is required'),
  streetaddress: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('Street Address is required'),
  suite: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Apt/Suite is required'),
  cityaddress: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(100, 'Maximum 100 symbols')
    .required('City is required'),
  postalcode: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('ZIP/Postal code is required'),
  birth: Yup.date()
    .required('Birthday is incorrect')
    .max(minDate, 'Please enter your exact birthday. You must be at least 18 years of age'),
})

interface Props {
  setViewStatus: React.Dispatch<React.SetStateAction<number>>
  state: {
    countrieSettings: any[]
    paymentSettings: any[]
    basePlans: any[]
    addons: any[]
    affiliatePlans: any[]
  }
}

export function Personinfo({setViewStatus, state}: Props) {
  const {t} = useTranslation()

  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [curCountries, setCurCountries] = useState<Array<any>>([])

  useEffect(() => {
    if (!state.countrieSettings) return
    setCurCountries(state.countrieSettings)
  }, [state.countrieSettings])

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setSubmitting}) => {
      checkInformation(values)
    },
  })

  const checkInformation = async (values: any) => {
    let curAddUserName = values.username as string
    curAddUserName = curAddUserName.toLowerCase()

    const valid = await fetch(BACKEND_API_V2 + '/webshop/user/check', {
      method: 'POST',
      body: JSON.stringify({
        name: values.username,
        email: values.email,
      }),
    })
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        console.log(err)
      })
    if (!valid) {
      swal({
        title: t('SHOPPINGCART_CONTENT_14'),
        text: t('SHOPPINGCART_CONTENT_15'),
        icon: 'error',
      })
      return
    }
    if (valid.word) {
      swal({
        title: t('SHOPPINGCART_CONTENT_16'),
        text: `${t('SHOPPINGCART_CONTENT_17')} '${valid.word}' ${t('SHOPPINGCART_CONTENT_18')}`,
        icon: 'error',
      })
      return
    }
    if (valid.email) {
      swal({
        title: t('SHOPPINGCART_CONTENT_16'),
        text: t('SHOPPINGCART_CONTENT_19'),
        icon: 'error',
      })
      return
    }
    if (valid.status === 'error') {
      swal({
        title: t('SHOPPINGCART_CONTENT_16'),
        text: t('SHOPPINGCART_CONTENT_20'),
        icon: 'error',
      })
      return
    }
    dispatch(setUserInformation(values))
    setViewStatus(4)
  }

  const updateTimezone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.values.timezone = Timezone[event.target.selectedIndex]
  }
  const updateCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formik.values.country = event.target.selectedOptions[0].text
    formik.values.countryId = parseInt(event.target.selectedOptions[0].value)
  }

  return (
    <div className='card p-lg-5 shadow-sm'>
      <div className='d-flex flex-column'>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='modal-header'>
            <h3 className='fw-bolder m-0'>{t('SHOPPINGCART_CONTENT_16')}</h3>
          </div>
          <div className='modal-body py-10'>
            <div className='scroll-y me-n7 pe-7'>
              {/* Username */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('TEXT_USER_NAME')}
                </label>
                <div className='col-lg-9 fv-row'>
                  <input
                    type='text'
                    placeholder={t('TEXT_USER_NAME')}
                    onKeyPress={(e) => {
                      if (new RegExp(/[a-zA-Z0-9!@#$%^&*+]/).test(e.key)) {
                      } else e.preventDefault()
                    }}
                    {...formik.getFieldProps('username')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.username && formik.errors.username,
                      },
                      {
                        'is-valid': formik.touched.username && !formik.errors.username,
                      }
                    )}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.username}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Full Name */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('ACCOUNT_OVERVIEW_DESC_FULLNAME')}
                </label>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-6 mb-3 fv-row'>
                      <input
                        placeholder={t('ACCOUNT_OVERVIEW_DESC_FULLNAME')}
                        type='text'
                        {...formik.getFieldProps('firstname')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.firstname && formik.errors.firstname,
                          },
                          {
                            'is-valid': formik.touched.firstname && !formik.errors.firstname,
                          }
                        )}
                      />
                      {formik.touched.firstname && formik.errors.firstname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.firstname}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-6 mb-3 fv-row'>
                      <input
                        placeholder={t('ACCOUNT_BILLING_ADDRESS_DLG_LASTNAME')}
                        type='text'
                        {...formik.getFieldProps('secondname')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.secondname && formik.errors.secondname,
                          },
                          {
                            'is-valid': formik.touched.secondname && !formik.errors.secondname,
                          }
                        )}
                      />
                      {formik.touched.secondname && formik.errors.secondname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.secondname}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('TEXT_EMAIL')}
                </label>
                <div className='col-lg-9'>
                  <input
                    placeholder={t('TEXT_EMAIL')}
                    type='email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Password */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('ACCOUNT_SECURITY_SIGNIN_PASSWORD_DESC1')}
                </label>
                <div className='col-lg-9 fv-row'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type={showPasswordConfirm ? 'text' : 'password'}
                      placeholder={t('ACCOUNT_SECURITY_SIGNIN_PASSWORD_DESC1')}
                      style={{marginRight: '10px'}}
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                      // data-tip="Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~ "
                    />
                    {/* <ReactTooltip
                      place='bottom'
                      type='info'
                      effect='solid'
                      className='register-tooltip'
                    /> */}
                    <i
                      className={showPasswordConfirm ? 'far fa-eye-slash' : 'far fa-eye'}
                      id='togglePassword'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Confirm password */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('ACCOUNT_SECURITY_SIGNIN_EMAIL_DESC3')}
                </label>
                <div className='col-lg-9'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('SHOPPINGCART_CONTENT_21')}
                      style={{marginRight: '10px'}}
                      {...formik.getFieldProps('changepassword')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid':
                            formik.touched.changepassword && formik.errors.changepassword,
                        },
                        {
                          'is-valid':
                            formik.touched.changepassword && !formik.errors.changepassword,
                        }
                      )}
                    />
                    <i
                      className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                      id='togglePassword'
                      style={{cursor: 'pointer'}}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.changepassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* UserAddress */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('SHOPPINGCART_CONTENT_22')}
                </label>
                <div className='col-lg-9 mb-3'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    data-control='select2'
                    data-placeholder='Select Country...'
                    onChange={updateCountry}
                  >
                    {curCountries
                      .filter((c) => c.status === 1)
                      .map((item, i) => (
                        <option value={item?.id} key={i}>
                          {item?.country_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='d-flex'>
                  <div className='col-lg-3'></div>
                  <div className='person-street d-flex'>
                    <div className='person-street-item1 mb-3'>
                      <input
                        type='text'
                        placeholder={t('ACCOUNT_OVERVIEW_DESC_STREET')}
                        {...formik.getFieldProps('streetaddress')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid':
                              formik.touched.streetaddress && formik.errors.streetaddress,
                          },
                          {
                            'is-valid':
                              formik.touched.streetaddress && !formik.errors.streetaddress,
                          }
                        )}
                      />
                      {formik.touched.streetaddress && formik.errors.streetaddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.streetaddress}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='person-street-item2 mb-3'>
                      <input
                        type='text'
                        placeholder={t('ACCOUNT_BILLING_ADDRESS_DLG_SUITE2')}
                        {...formik.getFieldProps('suite')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.suite && formik.errors.suite,
                          },
                          {
                            'is-valid': formik.touched.suite && !formik.errors.suite,
                          }
                        )}
                      />
                      {/* {formik.touched.suite && formik.errors.suite && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.suite}</span>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className='d-flex'>
                  <div className='col-lg-3'></div>
                  <div className='person-street d-flex'>
                    <div className='person-zip-item1 mb-3'>
                      <input
                        type='text'
                        placeholder={t('ACCOUNT_OVERVIEW_DESC_ZIPCODE')}
                        {...formik.getFieldProps('postalcode')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.postalcode && formik.errors.postalcode,
                          },
                          {
                            'is-valid': formik.touched.postalcode && !formik.errors.postalcode,
                          }
                        )}
                      />
                      {formik.touched.postalcode && formik.errors.postalcode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.postalcode}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='person-zip-item2 mb-3'>
                      <input
                        type='text'
                        placeholder={t('ACCOUNT_OVERVIEW_DESC_CITY')}
                        {...formik.getFieldProps('cityaddress')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.cityaddress && formik.errors.cityaddress,
                          },
                          {
                            'is-valid': formik.touched.cityaddress && !formik.errors.cityaddress,
                          }
                        )}
                      />
                      {formik.touched.cityaddress && formik.errors.cityaddress && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.cityaddress}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Time Zone */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('ACCOUNT_OVERVIEW_DESC_TIMEZONE')}
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-select-lg form-select-solid'
                    data-control='select2'
                    data-placeholder='Select Timezone...'
                    onChange={updateTimezone}
                  >
                    {Timezone.map((item, i) => (
                      <option value={TimezoneValue[i]} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Birthday */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                  {t('ACCOUNT_OVERVIEW_DESC_BIRTH')}
                </label>
                <div className='col-lg-9'>
                  <div className='col'>
                    <div className='fv-row mb-9'>
                      <input
                        type='date'
                        placeholder={t('SHOPPINGCART_CONTENT_23')}
                        id='kt_calendar_datepicker_end_date'
                        {...formik.getFieldProps('birth')}
                        required
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {'is-invalid': formik.touched.birth && formik.errors.birth},
                          {
                            'is-valid': formik.touched.birth && !formik.errors.birth,
                          }
                        )}
                      />
                      {formik.touched.birth && formik.errors.birth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.birth}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer flex-center'>
            <button
              type='submit'
              className='btn btn-primary'
              id='kt_account_profile_details_submit'
              disabled={!formik.isValid}
            >
              {t('BTN_TEXT_CONTINUE')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
