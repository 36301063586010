import {ClassValue, clsx} from 'clsx'

export function cn(...inputs: ClassValue[]) {
  return clsx(inputs)
}

export function composeEventHandlers<E>(
  originalEventHandler?: (event: E) => void,
  ourEventHandler?: (event: E) => void
) {
  return function handleEvent(event: E) {
    originalEventHandler?.(event)

    if (!(event as unknown as Event).defaultPrevented) {
      return ourEventHandler?.(event)
    }
  }
}

export function getRandomPaymentMethod(methods: string[], weights: number[], enabled: boolean[]) {
  const availableMethods = []
  const availableWeights = []

  for (let i = 0; i < methods.length; i++) {
    if (enabled[i]) {
      availableMethods.push(methods[i])
      availableWeights.push(weights[i])
    }
  }

  const totalWeight = availableWeights.reduce((a, b) => a + b, 0)
  const randomNum = Math.random() * totalWeight

  let cumulativeWeight = 0
  for (let i = 0; i < availableMethods.length; i++) {
    cumulativeWeight += availableWeights[i]
    if (randomNum < cumulativeWeight) {
      return availableMethods[i]
    }
  }
  return null // just in case no method is enabled
}

export interface PaymentMethodSettings {
  cardinity: boolean
  airwallex_eps: boolean
  airwallex_giropay: boolean
  airwallex_applepay: boolean
  airwallex_googlepay: boolean
  airwallex_cc: boolean
  advcash: boolean
  bpay: boolean
  micropayment_prepay: boolean
  micropayment_sofort: boolean
  reap_cc: boolean
  coinbase: boolean
  skrill_rapid: boolean
  skrill_cc: boolean
  skrill_neteller: boolean
  skrill: boolean
}

export type PaymentMethod =
  | 'mastercard'
  | 'creditcard'
  | 'creditcard_2'
  | 'airwallex_cc'
  | 'airwallex_googlepay'
  | 'airwallex_applepay'
  | 'airwallex_giropay'
  | 'airwallex_eps'
  | 'skrill_rapid'
  | 'skrill_neteller'
  | 'skrill'
  | 'micropayment_sofort'
  | 'micropayment_prepay'
  | 'coinbase'
  | 'bpay'
  | 'advcash'
  | 'trustly'
  | undefined
