/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import ClickAwayListener from 'react-click-away-listener'

import './header.css'
import {AppState} from '../../../../setup/redux/Store'
import {decryptPermissionStatus} from '../../../../config/utils'
import {BACKEND_UPLOAD_URL, BACKEND_API_URL, removeAllSession} from '../../../../config/config'

interface TopbarProps {
  handleClickAway: () => void
  showToggleMenu: boolean
  inspectorMode: any
  inspectorId: any
  curUserPhotoUrl: any
  curUserFirstName: any
  curUserSecondName: any
  curUserEmail: any
}

const HeaderUserMenu: FC<TopbarProps> = ({
  handleClickAway,
  showToggleMenu,
  inspectorMode,
  inspectorId,
  curUserPhotoUrl,
  curUserFirstName,
  curUserSecondName,
  curUserEmail,
}) => {
  let tokenValue = localStorage.getItem('token')

  const [curFirstName, setCurFirstName] = useState('')
  const [curSecondName, setCurSecondName] = useState('')
  const [curEmail, setCurEmail] = useState('')
  const [curPhotoURL, setCurPhotoURL] = useState('')

  let permissionStatus = localStorage.getItem('data') as string
  let permissionVal = 0
  if (permissionStatus) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }

  const {t} = useTranslation()
  const history = useHistory()

  useEffect(() => {
    getInitInformation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectorMode, curUserPhotoUrl, curUserFirstName, curUserSecondName, curUserEmail])

  const getInitInformation = async () => {
    if (permissionVal === 1 && inspectorMode === 0) {
      const userData = await axios.get(`${BACKEND_API_URL}/getStaff`, {
        headers: {'x-access-token': `${tokenValue}`},
      })

      const {auth} = userData?.data
      if (auth === false) {
        removeAllSession()
        history.push('/staff/login')
      } else if (userData?.data !== undefined && userData?.data !== null) {
        setCurFirstName(userData?.data?.first_name)
        setCurSecondName(userData?.data?.last_name)
        setCurEmail(userData?.data?.email)
        setCurPhotoURL(userData?.data?.photo_url)
      }
    } else {
      const userData = await axios.get(
        `${BACKEND_API_URL}/getUser?inspectorId=${inspectorId}&inspectorMode=${inspectorMode}`,
        {
          headers: {'x-access-token': `${tokenValue}`},
        }
      )

      const {auth} = userData?.data
      if (auth === false) {
        removeAllSession()
        history.push('/auth/login')
      } else if (userData?.data !== undefined && userData?.data !== null) {
        setCurFirstName(userData?.data?.first_name)
        setCurSecondName(userData?.data?.second_name)
        setCurEmail(userData?.data?.email)
        setCurPhotoURL(userData?.data?.photo_url)
      }
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('security_flag')
    localStorage.removeItem('restriction_flag')
    localStorage.removeItem('confirm_flag')
    localStorage.removeItem('data')
    localStorage.removeItem('hashUserFlag')
  }

  const handleClick = () => {
    handleClickAway()
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClick}>
        <div
          className={
            showToggleMenu === false
              ? 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
              : 'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px show show-user-toggleMenu'
          }
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                <img alt='Logo' src={(BACKEND_UPLOAD_URL + curPhotoURL) as string} />
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center text-break text-center pe-2 fs-5'>
                  {curFirstName}&nbsp;{curSecondName}
                  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                    {t('TOP_TOGGLEMENU_PRO')}
                  </span>
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary text-break fs-7'>
                  {curEmail}
                </a>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <Link
              to={permissionVal === 0 ? '/account/overview' : '/account/member'}
              className='menu-link px-5'
            >
              {t('TOP_TOGGLEMENU_PROFILE')}
            </Link>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <a
              onClick={logout}
              className='menu-link px-5'
              href={permissionVal === 0 ? '/auth/login' : '/staff/login'}
            >
              {t('TOP_TOGGLEMENU_LOGOUT')}
            </a>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  inspectorMode: state.auth.inspectorMode,
  inspectorId: state.auth.inspector_id,
  curUserPhotoUrl: state.auth.photo_url,
  curUserFirstName: state.auth.first_name,
  curUserSecondName: state.auth.second_name,
  curUserEmail: state.auth.email,
})

export default connect(mapStateToProps)(HeaderUserMenu)
