/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {useHistory} from 'react-router'
import swal from 'sweetalert'
import clsx from 'clsx'
import * as Yup from 'yup'

import {resetStaffPassword} from '../../redux/AuthCRUD'

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(30, 'Maximum 30 symbols')
    .test(
      'Password Warning',
      'Password is too weak. Must contain at least one uppercase and lowercase letter, a number and at least one special character.',
      (password?: string | '') => {
        return /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]+).*$/.test(
          password ? password : ''
        )
      }
    )
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function StaffResetPassword() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  let curEmail = localStorage.getItem('email') as string

  useEffect(() => {
    if (curEmail === null) {
      history.push('/staff/forgotpassword')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = {
    email: curEmail,
    password: '',
    changepassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        resetStaffPassword(values.email, values.password)
          .then(({data: {result}}: any) => {
            setLoading(false)

            if (result === 'User not found!') {
              swal({
                title: 'Reset Password',
                text: result,
                icon: 'warning',
              })
            } else {
              swal({
                title: 'Reset Password',
                text: result,
                icon: 'success',
              })
            }
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            swal({
              title: 'Reset Password',
              text: 'Reset Passsword failed.',
              icon: 'error',
            })
          })
      }, 1000)
    },
  })

  return (
    <div>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Change your Password</h1>
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {/* begin::Form group Password */}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Password</label>
            <div className='position-relative mb-3'>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  style={{marginRight: '10px'}}
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  data-tip="Must contain at least one uppercase and lowercase letter, a number and at least one special character.   Special characters: !#$%&'()*+,-./:;<=>?@[]^_`{|}~ "
                />
                <ReactTooltip
                  place='bottom'
                  type='info'
                  effect='solid'
                  className='register-tooltip'
                />
                <i
                  className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                  id='togglePassword'
                  style={{cursor: 'pointer'}}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <input
              type={showPasswordConfirm ? 'text' : 'password'}
              placeholder='Password confirmation'
              autoComplete='off'
              style={{marginRight: '10px'}}
              {...formik.getFieldProps('changepassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                },
                {
                  'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                }
              )}
            />
            <i
              className={showPasswordConfirm ? 'far fa-eye-slash' : 'far fa-eye'}
              id='togglePassword'
              style={{cursor: 'pointer'}}
              onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
            />
          </div>
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/staff/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Back to Login
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
