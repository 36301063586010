import {createStore, applyMiddleware, combineReducers, Store} from 'redux'
import {createBrowserHistory} from 'history'
import {routerMiddleware} from 'connected-react-router'
import thunk, {ThunkMiddleware} from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import * as auth from '../../app/modules/auth'
import accountReducer from './reducers/account'
import webshopReducer from './reducers/webshop'
import commissionReducer from './reducers/commission'
import {AppActionTypes} from './actions/action.types'

export const history = createBrowserHistory()
const middleware = [thunk as ThunkMiddleware<AppState, AppActionTypes>, routerMiddleware(history)]
const enhanceCompose = composeWithDevTools({shouldCatchErrors: true})

export const rootReducer = combineReducers({
  auth: auth.reducer,
  accout: accountReducer,
  webshop: webshopReducer,
  commission: commissionReducer,
})

const store: Store<AppState, any> = createStore(
  rootReducer,
  enhanceCompose(applyMiddleware(...middleware))
)

export default store
export type AppState = ReturnType<typeof rootReducer>
