/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useReducer, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import swal from 'sweetalert'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
// import {browserName} from 'react-device-detect'
import SweetAlert from 'react-bootstrap-sweetalert'
import jwt from 'jsonwebtoken'
import './style.css'
import {login} from '../redux/AuthCRUD'
import {useSubscriptionsContext} from '../../../../context/SubscriptionsContext'
import {Modal, Button, Form} from 'react-bootstrap-v5'
import {BACKEND_API_V2} from '../../../../config/config'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('User Name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

export function Login() {
  const history = useHistory()
  const {t} = useTranslation()

  const [curStatusAlert, setCurStatusAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  // useEffect(() => {
  //   if (browserName === 'Safari') {
  //     setCurStatusAlert(true)
  //   }
  // }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.username, values.password)
          .then(
            ({data: {token, security_flag, restriction_flag, confirm_flag, message, status}}) => {
              setLoading(false)
              const token_content = token && jwt.decode(token)
              if (!emailSent && token_content && token_content?.email) {
                setEmail(token_content.email)
                setShow(true)
                localStorage.setItem('token', token)
                return
              }
              if (!token_content?.email && token) {
                localStorage.setItem('token', token)
                localStorage.setItem('security_flag', security_flag)
                localStorage.setItem('restriction_flag', restriction_flag)
                localStorage.setItem('confirm_flag', confirm_flag)

                history.push('/auth/resetuserinfo')
                return
              }
              if (emailSent) return
              swal({
                title: 'Login',
                text: message,
                icon: 'warning',
              })
            }
          )
          .catch((e) => {
            console.error(e)
            setLoading(false)
            setSubmitting(false)

            swal({
              title: 'Log in',
              text: e,
              icon: 'error',
            })
          })
      }, 1000)
    },
  })

  const handleLogin = (e: {preventDefault: () => void}) => {
    // disable enter submit if email is not verified
    e.preventDefault()
    if (show) return

    formik.handleSubmit()
  }

  useEffect(() => {
    if (!emailSent) return
    const interval = setInterval(() => {
      formik.handleSubmit()
    }, 3000)
    setTimeout(() => {
      setEmailSent(false)
    }, 30000)
    return () => clearInterval(interval)
  }, [emailSent])

  const handleConfirm = () => {
    setCurStatusAlert(false)
  }

  return (
    <>
      <EmailVerificationModal
        show={show}
        email={email}
        setEmail={setNewEmail}
        setOldEmail={setEmail}
        newEmail={newEmail}
        setEmailSent={setEmailSent}
      />
      <SweetAlert
        show={curStatusAlert}
        warning
        confirmBtnText={t('LOGIN_ALERT_BTN_TEXT')}
        confirmBtnBsStyle='warning'
        title={t('LOGIN_ALERT_TITLE')}
        onConfirm={handleConfirm}
        closeOnClickOutside={true}
      >
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT1')}</span>
        <br />
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT2')}</span>
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT3')}</span>
      </SweetAlert>
      <div>
        <form className='form w-100' onSubmit={handleLogin} noValidate id='kt_login_signin_form'>
          {/* begin::Heading */}
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Welcome to Infinity</h1>
          </div>
          {/* begin::Heading */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>User Name</label>
            <input
              placeholder='User Name'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='text'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                {/* end::Label */}
                {/* begin::Link */}
                <Link
                  to='/auth/forgotpassword'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Forgot Password ?
                </Link>
                {/* end::Link */}
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                autoComplete='off'
                style={{marginRight: '10px'}}
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <i
                className={showPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                id='togglePassword'
                style={{cursor: 'pointer'}}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={show}
            >
              {!loading && <span className='indicator-label'>Continue</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </>
  )
}

const EmailVerificationModal = ({
  show,
  email,
  setEmail,
  setOldEmail,
  setEmailSent,
  newEmail,
}: {
  show: boolean
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setEmailSent: React.Dispatch<React.SetStateAction<boolean>>
  setOldEmail: React.Dispatch<React.SetStateAction<string>>
  newEmail: string
}) => {
  const [showChangeEmailForm, setShowChangeEmailForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(0)
  const handleNewEmailChange = (e: {target: {value: React.SetStateAction<string>}}) =>
    setEmail(e.target.value)
  const showError = (msg?: string | unknown) => {
    swal({
      title: 'Something went wrong',
      text: msg ? msg.toString() : 'Try refreshing the page or try again later',
      icon: 'error',
    })
  }
  const handleSendLink = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    try {
      setLoading(true)
      const res = await fetch(BACKEND_API_V2 + '/user/verify_email/sendEmailVerification', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token') || '',
        },
      }).then((res) => res.json())
      if (res.status === 'error') {
        throw new Error(res.message)
      }
      setEmailSent(true)
      setTimer(30)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      showError(err)
    }
  }
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (timer > 0) {
      timeout = setTimeout(() => {
        if (timer <= 0) clearTimeout(timeout)
        setTimer(timer - 1)
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [timer])

  useEffect(() => {
    if (!show) return
    handleSendLink({preventDefault: () => {}})
  }, [show])

  const handleChangeEmailLink = async (e: {preventDefault: () => void}) => {
    e.preventDefault()
    try {
      setLoading(true)
      setOldEmail(newEmail)
      const res = await fetch(BACKEND_API_V2 + '/user/verify_email/updateEmail', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': localStorage.getItem('token') || '',
        },
        body: JSON.stringify({new_email: newEmail}),
      }).then((res) => res.json())
      if (res.status === 'error') {
        throw new Error(res.message)
      }
      localStorage.setItem('token', res.token || '')
      setEmailSent(true)
      setShowChangeEmailForm(false)
      setTimer(30)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    } catch (err) {
      console.log(err)
      setLoading(false)
      showError(err)
    }
  }

  const handleChangeEmail = () => {
    setShowChangeEmailForm(true)
  }

  function Btn() {
    return (
      <Button
        variant='primary'
        onClick={showChangeEmailForm ? handleChangeEmailLink : handleSendLink}
        disabled={loading || timer > 0}
        style={{minWidth: '49%'}}
      >
        {loading && (
          <span
            className='spinner-border spinner-border-sm mr-3'
            role='status'
            aria-hidden='true'
          ></span>
        )}{' '}
        {!loading && timer > 0 ? `Resend in ${timer}` : !loading ? 'Send Link' : 'Sending...'}
      </Button>
    )
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => {}}
        // size='lg'
        // aria-labelledby='contained-modal-title-vcenter'
        centered
        // backdrop='static'
      >
        <Modal.Header style={{justifyContent: 'center'}}>
          <Modal.Title style={{textAlign: 'center'}}>
            Please verify your email: <br /> {showChangeEmailForm ? newEmail : email}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showChangeEmailForm ? (
            <>
              <p className='mb-3'>
                Send link to <b>{email}</b>
              </p>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Btn />

                <Button variant='secondary' onClick={handleChangeEmail} style={{minWidth: '49%'}}>
                  Change email
                </Button>
              </div>
            </>
          ) : (
            <Form>
              <Form.Group controlId='newEmail'>
                <Form.Label>New Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter new email'
                  value={newEmail}
                  onChange={handleNewEmailChange}
                  onKeyDown={(e: {key?: any; preventDefault: any}) => {
                    if (e.key === 'Enter') {
                      e.preventDefault() // Prevent page refresh
                      handleChangeEmailLink(e)
                    }
                  }}
                />
              </Form.Group>
              <div className='mt-3'>
                <Btn />
              </div>
              <br />
              <span
                className='text-primary'
                style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => setShowChangeEmailForm(false)}
              >
                Go back
              </span>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
