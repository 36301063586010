/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import Slider from 'react-slick'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import {useTranslation} from 'react-i18next'
import React, {useEffect, useState} from 'react'
// import {browserName} from 'react-device-detect'
import SweetAlert from 'react-bootstrap-sweetalert'

import '../webshop/webshop.css'
import {KTSVG} from '../../../_metronic/helpers'
import {
  BACKEND_API_URL,
  BACKEND_UPLOAD_URL,
  removeAllSession,
  DEFAULTTOKEN,
  BACKEND_API_V2,
} from '../../../config/config'
import {
  setBasePlanPeriod,
  setWithoutPlanStatus,
  addShoppingBasePlan,
  addShoppingBaseAndAddonPlan,
  addShoppingAddonPlan,
} from '../../../setup/redux/actions/webshop'

type NextProps = {
  nextSlider: () => void
}

type BeforeProps = {
  prevSlider: () => void
}

function SampleNextArrow({nextSlider}: NextProps) {
  return (
    <div onClick={nextSlider} className='slideNextBtn'>
      <img src='/media/icons/duotune/arrows/arr071.svg' alt='next' />
    </div>
  )
}

function SamplePrevArrow({prevSlider}: BeforeProps) {
  return (
    <div onClick={prevSlider} className='slidePrevBtn'>
      <img src='/media/icons/duotune/arrows/arr074.svg' alt='prev' />
    </div>
  )
}

interface Props {
  setViewStatus: React.Dispatch<React.SetStateAction<number>>
  state: {
    countrieSettings: any[]
    paymentSettings: any[]
    basePlans: any[]
    addons: any[]
    affiliatePlans: any[]
  }
}

export function Baseplan({setViewStatus, state}: Props) {
  let slider: any
  const history = useHistory()
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [totalBasePlans, setTotalBasePlans] = useState<Array<any>>([])
  const [curBasePlans, setCurBasePlans] = useState<Array<any>>([])
  const [curBasePlan, setCurBasePlan] = useState<any>()
  const [curLifeBelt, setCurLifeBelt] = useState(0)
  const [curLifeBeltStatus, setCurLifeBeltStatus] = useState(false)
  const [backStyle, setBackStyle] = useState('')
  const [editStyle, setEditStyle] = useState('modal')
  const [addcard, setAddCard] = useState<any>(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const [curStatusAlert, setCurStatusAlert] = useState(false)

  const nextSlider = () => {
    slider.slickNext()
  }
  const prevSlider = () => {
    slider.slickPrev()
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow nextSlider={nextSlider} />,
    prevArrow: <SamplePrevArrow prevSlider={prevSlider} />,
    beforeChange: (current: any, next: any) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const getData = async () => {
    const basePlans = state.basePlans
    if (!basePlans) return

    let tempDatas = basePlans.sort((a: any, b: any) => {
      return b.price - a.price
    })
    for (let i = 0; i < tempDatas.length; i++) {
      let tempCheckData = tempDatas[i]
      let tempChecked = tempCheckData.checked_attributes.split(';')
      if (tempChecked[tempChecked.length - 1] === '') {
        tempChecked.pop()
      }
      let tempUnCheckData = tempDatas[i]
      let tempUnChecked = tempUnCheckData.unchecked_attributes.split(';')
      if (tempUnChecked[tempUnChecked.length - 1] === '') {
        tempUnChecked.pop()
      }

      tempDatas[i].checked_attributes = tempChecked
      tempDatas[i].unchecked_attributes = tempUnChecked
    }

    setTotalBasePlans(tempDatas)
    // if (token?.lifebelt) setCurLifeBelt(1)
    tempDatas.find((item: any) => {
      item.lifebelt_flag === 1 && setCurLifeBelt(1)
    })
    let tempBasePlans: Array<any> = []
    for (let i = 0; i < tempDatas.length; i++) {
      let tempData = tempDatas[i]

      if (tempData.lifebelt_flag === 0) {
        tempBasePlans.push(tempData)
      }
    }
    setCurBasePlans(tempBasePlans)
  }

  useEffect(() => {
    // if (browserName === 'Safari') {
    //   setCurStatusAlert(true)
    // }

    dispatch(setWithoutPlanStatus(0))
    getData()

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.basePlans])

  const handleLifeBelt = () => {
    setCurLifeBeltStatus(!curLifeBeltStatus)

    if (curLifeBeltStatus === false) {
      setCurBasePlans(totalBasePlans)
    } else {
      let tempBasePlans: Array<any> = []

      for (let i = 0; i < totalBasePlans.length; i++) {
        let tempData = totalBasePlans[i]

        if (tempData.lifebelt_flag === 0) {
          tempBasePlans.push(tempData)
        }
      }

      setCurBasePlans(tempBasePlans)
    }
    addcard === true ? setAddCard(false) : setAddCard(true)
  }

  const handleDetail = (index: number) => {
    setBackStyle('modal-backdrop show ')
    setEditStyle('modal show billing-address-modal-show')

    let tempData = curBasePlans[index]

    let tempCheckedArray = tempData.checked_attributes
    let tempChecked = ''
    for (let i = 0; i < tempCheckedArray.length; i++) {
      tempChecked += tempCheckedArray[i]
      if (i !== tempCheckedArray.length - 1) tempChecked += ';'
    }

    let tempUnCheckedArray = tempData.unchecked_attributes
    let tempUnChecked = ''
    for (let i = 0; i < tempUnCheckedArray.length; i++) {
      tempUnChecked += tempUnCheckedArray[i]
      if (i !== tempUnCheckedArray.length - 1) tempUnChecked += ';'
    }
    let tempPeriod = tempData.subscription_length
    tempPeriod += ' months'
    let tempProduct = tempData.products
    let tempProducts = tempProduct.split(';')

    let temp: any = {}
    temp.product_image = tempData.product_image
    temp.status = tempData.status
    temp.scheduled_time = tempData.scheduled_time
    temp.card_name = tempData.card_name
    temp.card_description = tempData.card_description
    temp.long_description = tempData.long_description
    temp.checked = tempChecked
    temp.unChecked = tempUnChecked
    temp.price = tempData.price
    temp.discount_flag = tempData.discount_flag
    temp.discount = tempData.discount
    temp.subscription_length = tempPeriod
    temp.quantity = tempData.quantity
    temp.quantity_ignore_flag = tempData.quantity_ignore_flag
    temp.product_volume = tempData.product_volume
    temp.rank_volume = tempData.rank_volume
    temp.hpp_units = tempData.hpp_units
    temp.hpp_coupons = tempData.hpp_coupons
    temp.products = tempProducts
    temp.ea_flag = tempData.ea_flag
    temp.ea_license_id = tempData.ea_license_id
    temp.vps_image_name = tempData.vps_image_name
    setCurBasePlan(temp)
  }
  const handleModalClose = () => {
    setBackStyle('')
    setEditStyle('modal')
  }

  const handleSelect = (index: number) => {
    let periods: Array<number> = []
    for (let i = 0; i < parseInt(curBasePlans[index]?.subscription_length); i++)
      periods = [...periods, i + 1]

    dispatch(setBasePlanPeriod(periods))
    dispatch(addShoppingBasePlan(curBasePlans[index]))
    setViewStatus(1)
  }

  const handleWithoutPlan = () => {
    dispatch(setWithoutPlanStatus(1))
    dispatch(addShoppingBaseAndAddonPlan({}, []))
    dispatch(addShoppingAddonPlan([]))
    setViewStatus(2)
  }

  useEffect(() => {
    if (addcard === true) {
      slider.slickGoTo(slideIndex + 2)
    } else if (addcard === false) {
      slider.slickGoTo(slideIndex - 2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addcard, curBasePlans])

  const handleConfirm = () => {
    setCurStatusAlert(false)
  }

  return (
    <>
      <SweetAlert
        show={curStatusAlert}
        warning
        confirmBtnText={t('LOGIN_ALERT_BTN_TEXT')}
        confirmBtnBsStyle='warning'
        title={t('LOGIN_ALERT_TITLE')}
        onConfirm={handleConfirm}
        closeOnClickOutside={true}
      >
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT1')}</span>
        <br />
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT2')}</span>
        <br />
        <span className='login-alert-content'>{t('LOGIN_ALERT_CONTENT3')}</span>
      </SweetAlert>

      <div className='card p-lg-5 shadow-sm'>
        <div className='d-flex flex-column'>
          <div className='d-flex position-relative'>
            <div className='mb-13 w-100 text-center' style={{marginTop: '80px'}}>
              <img alt='Logo' src='/media/logos/logo.svg' className='h-60px plan-avatar' />
              <h1 className='fs-2hx fw-bolder mb-5'>Choose Your Plan</h1>
              <div className='basic-plan-desc fw-bold fs-5'>
                If you need more info about our pricing, please check&nbsp;
                <a
                  className='menu-link without-sub'
                  href='https://be-infinity.com/pricing_policy'
                  target={'_blank'}
                  rel='noopener noreferrer'
                >
                  <span className='link-primary fw-bolder'>Pricing Guidelines</span>.
                </a>
              </div>
            </div>
            {curLifeBelt === 1 ? (
              <div className='life-belt-main'>
                <button className='life-belt' onClick={handleLifeBelt}>
                  <span className='svg-icon svg-icon-1'>
                    <KTSVG
                      path={
                        curLifeBeltStatus === true
                          ? '/media/icons/duotune/lifebelt/life_belt_active.svg'
                          : '/media/icons/duotune/lifebelt/life_belt.svg'
                      }
                    />
                  </span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className='row g-10'>
            <Slider ref={(c) => (slider = c)} {...settings}>
              {curBasePlans && curBasePlans.length > 0 ? (
                curBasePlans.map((item, key) => (
                  <div className='slide-item' key={key}>
                    <div className='d-flex h-100 align-items-center'>
                      <div className='w-100 d-flex align-items-center position-relative flex-column rounded-3 bg-light bg-opacity-75 plan-item'>
                        <button
                          className='card-detail'
                          style={{background: 'transparent'}}
                          onClick={() => handleDetail(key)}
                        >
                          <span className='svg-icon svg-icon-1'>
                            <KTSVG path={'/media/icons/duotune/general/gen046.svg'} />
                          </span>
                        </button>
                        <div className='mb-7 text-center webshop-header'>
                          <h1 className='text-dark mb-5 h-50px d-flex justify-content-center align-items-center fw-boldest'>
                            {item?.card_name}
                          </h1>
                          <div className='text-type1 fw-bold'>{item?.card_description}</div>
                          {item?.discount_flag === 1 ? (
                            <div className='plan-item-price'>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span
                                  className='fs-2x fw-bolder text-primary'
                                  style={{textDecoration: 'line-through'}}
                                >
                                  {parseFloat(item?.price).toFixed(2)}
                                </span>
                              </div>
                              <div className='text-center'>
                                <span className='mb-2 text-primary'>$</span>
                                <span className='fs-3x fw-bolder text-primary'>
                                  {parseFloat(item?.discount).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className='text-center plan-item-discount'>
                              <span className='mb-2 text-primary'>$</span>
                              <span className='fs-3x fw-bolder text-primary'>
                                {parseFloat(item?.price).toFixed(2)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='w-100 mb-10 webshop-content'>
                          {item?.checked_attributes.map((itemCheck: any, i: number) => (
                            <div className='d-flex align-items-center' key={i}>
                              <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                {itemCheck}
                              </span>
                              <span className='svg-icon svg-icon-1 svg-icon-success'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='10'
                                    fill='currentColor'
                                  />
                                  <path
                                    d='M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            </div>
                          ))}
                          {item?.unchecked_attributes.map((itemUnCheck: any, i: number) => (
                            <div className='d-flex align-items-center' key={i}>
                              <span className='fw-bold fs-6 text-type2 flex-grow-1 pe-3'>
                                {itemUnCheck}
                              </span>
                              <span className='svg-icon svg-icon-1'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                >
                                  <rect
                                    opacity='0.3'
                                    x='2'
                                    y='2'
                                    width='20'
                                    height='20'
                                    rx='10'
                                    className='unchecked-style'
                                  />
                                  <rect
                                    x='7'
                                    y='15.3137'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(-45 7 15.3137)'
                                    className='unchecked-style'
                                  />
                                  <rect
                                    x='8.41422'
                                    y='7'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(45 8.41422 7)'
                                    className='unchecked-style'
                                  />
                                </svg>
                              </span>
                            </div>
                          ))}
                        </div>
                        <button
                          className='btn btn-sm btn-primary'
                          onClick={() => handleSelect(key)}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
            <div className='continue-basic'>
              <button className='btn btn-light me-3 continue-basic-btn' onClick={handleWithoutPlan}>
                Continue without a Basic Plan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={editStyle} tabIndex={-1} id='kt_modal_edit_user' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-800px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='fw-bolder m-0'>Product Details</h3>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
                onClick={handleModalClose}
              >
                <span className='svg-icon svg-icon-1'>
                  <KTSVG path='/media/icons/duotune/general/gen040.svg' />
                </span>
              </div>
            </div>
            <div className='modal-body py-10 px-lg-17'>
              <div
                className='scroll-y me-n7 pe-7'
                id='kt_modal_new_address_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_new_address_header'
                data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
                data-kt-scroll-offset='300px'
              >
                {/* Avatar */}
                <div className='row mb-6 mt-6'>
                  <div className='col-lg-4 image-avatar d-flex align-items-center justify-content-center '>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <div className='image-input-wrapper w-125px h-125px'>
                        <img
                          src={
                            curBasePlan?.product_image && curBasePlan?.product_image.length > 0
                              ? BACKEND_UPLOAD_URL + curBasePlan?.product_image
                              : '/media/files/blank-image.svg'
                          }
                          alt='avatar'
                          style={{width: '100%', height: '100%'}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8 fv-row'>
                    <span className='form-control border-0' style={{fontSize: '20px'}}>
                      {curBasePlan?.card_name}
                    </span>
                    <span className='form-control border-0'>{curBasePlan?.card_description}</span>
                  </div>
                </div>
                {/* Long Description */}
                <div className='row mb-6'>
                  <div className='col-lg-11 fv-row'>
                    <span
                      className='form-control border-0'
                      dangerouslySetInnerHTML={{__html: curBasePlan?.long_description}}
                    />
                  </div>
                </div>
                {/* Period */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    Subscription Length
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Subscription Length'
                      defaultValue={curBasePlan?.subscription_length}
                      readOnly
                    />
                  </div>
                </div>
                {/* Quantity */}
                {curBasePlan?.quantity_ignore_flag === 0 ? (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Quantity</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Quantity'
                        defaultValue={curBasePlan?.quantity}
                        readOnly
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* Product Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Product Volume</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Product Volume'
                      defaultValue={curBasePlan?.product_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Rank Volume */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Rank Volume</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Rank Volume'
                      defaultValue={curBasePlan?.rank_volume}
                      readOnly
                    />
                  </div>
                </div>
                {/* Units */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Units</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Units'
                      defaultValue={curBasePlan?.hpp_units}
                      readOnly
                    />
                  </div>
                </div>
                {/* High Performance Pool Coupons */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    High Performance Pool Coupons
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='High Performance Pool Coupons'
                      defaultValue={curBasePlan?.hpp_coupons}
                      readOnly
                    />
                  </div>
                </div>
                {/* Products */}
                <div className='row mb-6 justify-content-end'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Products</label>
                  {curBasePlan?.products && curBasePlan?.products.length > 0 ? (
                    curBasePlan?.products.map((product: string, i: number) => (
                      <div className='col-lg-8 fv-row mb-5' key={i}>
                        <input
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Products'
                          defaultValue={product}
                          readOnly
                        />
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                id='kt_modal_new_address_cancel'
                className='btn btn-primary'
                onClick={handleModalClose}
              >
                {t('BTN_TEXT_CLOSE')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={backStyle}></div>
    </>
  )
}
