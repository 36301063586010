import axios from 'axios'
import {AuthModel} from '../../../models/AuthModel'
import {
  BACKEND_API_URL,
  removeAllSession,
  DEFAULTTOKEN,
  BACKEND_API_V2,
  RUST_BACKEND_API,
} from '../../../../config/config'

export const GET_USER_BY_ACCESSTOKEN_URL = `${BACKEND_API_URL}/verify_token`
export const LOGIN_URL = `${BACKEND_API_V2}/user/login`
export const REGISTER_URL = `${BACKEND_API_URL}/createUser`
export const REQUEST_PASSWORD_URL = `${BACKEND_API_V2}/user/password/request_reset`
export const REQUEST_RESET_PASSWORD_URL = `${BACKEND_API_V2}/user/password/confirm_reset`
export const REQUEST_All_BILLINGADDRESS_URL = `${BACKEND_API_URL}/getBillingAddress`
export const REQUEST_ADD_BILLINGADDRESS_URL = `${BACKEND_API_URL}/addBillingAddress`
export const REQUEST_COMPARE_SECURECODE_URL = `${BACKEND_API_URL}/smsConfirm`
export const STAFF_LOGIN_URL = `${BACKEND_API_URL}/staff/login`
export const REQUEST_STAFF_COMPARE_SECURECODE_URL = `${BACKEND_API_URL}/staff/smsConfirm`
export const REQUEST_STAFF_PASSWORD_URL = `${BACKEND_API_URL}/staff/forgotPassword`
export const REQUEST_STAFF_RESET_PASSWORD_URL = `${BACKEND_API_URL}/staff/resetPassword`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {
    user_name: username,
    password: password,
  })
}

// Server should return AuthModel
export function register(
  username: string,
  email: string,
  firstname: string,
  secondname: string,
  password: string,
  country: string,
  streetaddress: string,
  suite: string,
  cityaddress: string,
  postalcode: string,
  timezone: string,
  birth: string
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    user_name: username,
    password: password,
    email: email,
    country: country,
    streetaddress: streetaddress,
    suite: suite,
    cityaddress: cityaddress,
    postalcode: postalcode,
    first_name: firstname,
    second_name: secondname,
    timezone: timezone,
    birth: birth,
  })
}

// Server should return request result
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email: email,
  })
}

// Server should return Reset password result
export async function resetPassword(email: string, password: string, token: string) {
  const resultData = await axios.post(
    REQUEST_RESET_PASSWORD_URL,
    {
      new_password: password,
    },
    {
      headers: {'x-access-token': token},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Update User Information result
export async function UpdateUserInfo(data: object) {
  let tokenValue = localStorage.getItem('token')
  return await axios.post(`${BACKEND_API_V2}/user/updateUserDetails`, data, {
    headers: {'x-access-token': `${tokenValue}`},
  })
}

// Server should return Reset email result
export async function resetEmail(email: string, password: string) {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    `${BACKEND_API_URL}/updateEmail`,
    {
      email: email,
      password: password,
    },
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Update password result
export async function updatePassword(password: string, new_password: string) {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    `${BACKEND_API_URL}/updatePassword`,
    {
      password: password,
      new_password: new_password,
    },
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Deactivate result
export async function deactivate() {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    `${BACKEND_API_URL}/deactivateAccount`,
    {},
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Deactivate Confirm result
export async function deactivateConfirm() {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.get(`${BACKEND_API_URL}/deactivateAccountConfirm`, {
    headers: {'x-access-token': `${tokenValue}`},
  })

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Security Enable result
export async function security() {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    `${BACKEND_API_URL}/enableSecurity`,
    {},
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return Security Disable result
export async function disableSecurity() {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    `${BACKEND_API_URL}/disableSecurity`,
    {},
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Server should return All Billing address result
export function allbillingaddress(id: string) {
  return axios.post(REQUEST_All_BILLINGADDRESS_URL, {
    userId: id,
  })
}

// Server should return Add Billing address result
export function addbillingaddress(
  id: string,
  companyName: string,
  house: string,
  street: string,
  zipCode: string,
  city: string,
  county: string
) {
  return axios.post(REQUEST_ADD_BILLINGADDRESS_URL, {
    userId: id,
    company_name: companyName,
    house: house,
    street: street,
    zip: zipCode,
    city: city,
    country: county,
  })
}

// Server should return compare secure code result
export async function compareSecureCode(secureCode: string) {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    REQUEST_COMPARE_SECURECODE_URL,
    {
      auth_code: secureCode,
    },
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}

// Staff User Login
export function staffLogin(username: string, password: string) {
  console.log(STAFF_LOGIN_URL)
  return axios.post(STAFF_LOGIN_URL, {
    user_name: username,
    password: password,
  })
}
// Server should return compare staff secure code result
export async function compareStaffSecureCode(secureCode: string) {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.post(
    REQUEST_STAFF_COMPARE_SECURECODE_URL,
    {
      auth_code: secureCode,
    },
    {
      headers: {'x-access-token': `${tokenValue}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/staff/login', '_self')
  } else {
    return resultData
  }
}
// Server should return Staff request result
export function requestStaffPassword(email: string) {
  return axios.post(REQUEST_STAFF_PASSWORD_URL, {
    email: email,
  })
}
// Server should return Staff Reset password result
export async function resetStaffPassword(email: string, password: string) {
  const resultData = await axios.post(
    REQUEST_STAFF_RESET_PASSWORD_URL,
    {
      email: email,
      password: password,
    },
    {
      headers: {'x-access-token': `${DEFAULTTOKEN}`},
    }
  )

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/staff/login', '_self')
  } else {
    return resultData
  }
}
// Server should return Deactivate Staff Confirm result
export async function deactivateStaffConfirm(id: string) {
  let tokenValue = localStorage.getItem('token')
  const resultData = await axios.get(`${BACKEND_API_URL}/deactivateStaffAccountConfirm/${id}`, {
    headers: {'x-access-token': `${tokenValue}`},
  })

  const {auth} = resultData?.data
  if (auth === false) {
    removeAllSession()
    window.open('/auth/login', '_self')
  } else {
    return resultData
  }
}
