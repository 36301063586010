/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'

import {RootState} from '../../setup'
import Secure from '../modules/Secure'
import {AuthPage} from '../modules/auth'
import {PrivateRoutes} from './PrivateRoutes'
import {decryptPermissionStatus} from '../../config/utils'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {ShoppingCart} from '../modules/shoppingcart/shopping'
import {FinalPage} from '../modules/shoppingcart/finalpage'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import SecureStaff from '../../app/modules/auth/components/staff/Secure'
import {VerifyEmailPage} from '../pages/VerifyEmail'

const Routes: FC = () => {
  let secureActive = useSelector<RootState>(({auth}) => auth.secure_active, shallowEqual) as string
  let confirmStatus = useSelector<RootState>(({auth}) => auth.confirm_flag, shallowEqual) as string
  let restrictionStatus = useSelector<RootState>(
    ({auth}) => auth.restriction_flag,
    shallowEqual
  ) as string

  if (secureActive === undefined && localStorage.getItem('security_flag'))
    secureActive = localStorage.getItem('security_flag') as string
  if (confirmStatus === undefined && localStorage.getItem('confirm_flag'))
    confirmStatus = localStorage.getItem('confirm_flag') as string
  if (restrictionStatus === undefined && localStorage.getItem('restriction_flag'))
    restrictionStatus = localStorage.getItem('restriction_flag') as string

  // Redirect to the infinity-backofffice.com
  // let current_url = document.location.href
  // let desired_domain1 = 'https://infinity-backoffice.com'
  // let desired_domain2 = 'http://infinity-backoffice.com'

  // if (
  //   (!current_url.includes(desired_domain1) || !current_url.includes(desired_domain2)) &&
  //   current_url.includes('amazonaws.com:4000')
  // ) {
  //   let start_of_path = 'amazonaws.com:4000'
  //   let path = current_url.substring(current_url.indexOf(start_of_path) + start_of_path.length)
  //   document.location.replace(desired_domain1 + path)
  // }

  // Deactivate URL Check
  const curURL = useLocation()
  // const curURlArray: Array<string> = curURL.pathname.split('/')
  let deactivateFlag = false
  if (curURL.pathname.includes('deactivate')) {
    deactivateFlag = true
    //   localStorage.removeItem('token')
    //   localStorage.removeItem('user')
    //   localStorage.removeItem('id')
    //   localStorage.removeItem('email')
    //   localStorage.removeItem('firstName')
    //   localStorage.removeItem('secondName')
    //   localStorage.removeItem('birth')
    //   localStorage.removeItem('streetaddress')
    //   localStorage.removeItem('suite')
    //   localStorage.removeItem('postalcode')
    //   localStorage.removeItem('cityaddress')
    //   localStorage.removeItem('country')
    //   localStorage.removeItem('timezone')
    //   localStorage.removeItem('photo_url')
    //   localStorage.removeItem('security_flag')
    //   localStorage.removeItem('restriction_flag')
    //   localStorage.removeItem('confirm_flag')
    //   localStorage.removeItem('cryptowallet')
    //   localStorage.removeItem('data')
    //   localStorage.removeItem('hashUserFlag')
  }

  // WebShop flow URL Check
  let userregister = false
  if (curURL.pathname.includes('/replica/')) userregister = true

  let permissionStatus = localStorage.getItem('data') as string
  let permissionVal = 0
  if (permissionStatus) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }
  let hashUserInfoFlag = parseInt(localStorage.getItem('hashUserFlag') as string)

  return (
    <>
      <Switch>
        <Route path='/verify_email' component={VerifyEmailPage} />
        {userregister === true ? (
          <>
            {!curURL.pathname.includes('finalpage') && (
              <Route path='/replica/:username' component={ShoppingCart} />
            )}
            <Route path='/replica/finalpage' component={FinalPage} />
          </>
        ) : secureActive === undefined ||
          deactivateFlag ||
          hashUserInfoFlag === 0 ||
          (restrictionStatus === '1' && confirmStatus === '0') ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (secureActive === '1' || confirmStatus === '0') && permissionVal === 0 ? (
          <Secure />
        ) : (secureActive === '1' || confirmStatus === '0') && permissionVal === 1 ? (
          <SecureStaff />
        ) : (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
