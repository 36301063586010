import React, {useState, useEffect} from 'react'
import {Spinner} from 'react-bootstrap-v5'
import {BACKEND_API_V2} from '../../config/config'
import {useTranslation} from 'react-i18next'

export function VerifyEmailPage() {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const token = window.location.search.split('=')[1]
  useEffect(() => {
    if (!token) {
      setError(true)
      return
    }
    // Simulating API call for verification
    ;(async () => {
      const res = await fetch(BACKEND_API_V2 + '/user/verify_email/verifyEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      })
      setLoading(false)
      if (res.status !== 200) {
        setError(true)
        return
      }
      setSuccess(true)
    })()
  }, [])

  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      {loading && !error && (
        <div className='text-center'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>{t('VERIFYEMAIL_CONTENT_1')}</span>
          </Spinner>
          <p className='mt-3'>{t('VERIFYEMAIL_CONTENT_1')}...</p>
        </div>
      )}
      {!loading && success && !error && (
        <div className='text-center'>
          <svg
            stroke='green'
            fill='green'
            stroke-width='0'
            viewBox='0 0 16 16'
            height='45'
            width='45'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z'></path>
          </svg>
          <p className='mt-3'>
            {t('VERIFYEMAIL_CONTENT_2')} <br /> {t('VERIFYEMAIL_CONTENT_3')}
          </p>
          <a href='/auth/login' className='btn btn-primary mt-3'>
            {t('BTN_TEXT_LOGIN')}
          </a>
        </div>
      )}
      {error && (
        <div className='text-center'>
          <svg
            stroke='red'
            fill='red'
            stroke-width='0'
            viewBox='0 0 16 16'
            height='45'
            width='45'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
          </svg>

          <p className='mt-3'>
            {t('ERRORS_CONTENT_6')}. <br /> {t('VERIFYEMAIL_CONTENT_4')}
          </p>
          <a href='/auth/login' className='btn btn-primary mt-3'>
            {t('BTN_TEXT_LOGIN')}
          </a>
        </div>
      )}
    </div>
  )
}
