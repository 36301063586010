/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

export function ErrorUser() {
  const history = useHistory()

  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const handleLogin = () => {
    history.push('/auth/login')
  }

  return (
    <div className='card shadow-sm h-100'>
      <div className='card-body p-0'>
        <div className='card-px text-center py-20'>
          <div className='h-80px d-flex justify-content-center mb-5'>
            <img alt='Logo' src='/media/logos/logo.svg' className='h-80px' />
          </div>
          <div>
            <h2 className='fs-2x fw-bolder mb-10'>{t('SHOPPINGCART_CONTENT_5')}</h2>
            <p className='text-gray-400 fs-4 fw-bold mb-10'>{t('SHOPPINGCART_CONTENT_6')}</p>
            <button className='btn btn-primary' onClick={handleLogin}>
              {t('BTN_TEXT_LOGIN')}
            </button>
          </div>
        </div>
        <div className='text-center px-4'>
          <img src='/media/images/image2.png' className='mw-100 mh-300px' alt='checkimage' />
        </div>
      </div>
    </div>
  )
}
