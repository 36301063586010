/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {Login} from './components/Login'
import {Deactivate} from './components/Deactivate'
import {StaffLogin} from './components/staff/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ResetPassword} from './components/ResetPassword'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetUserInfo} from './components/ResetUserInfo'
import {StaffDeactivate} from './components/staff/Deactivate'
import {decryptPermissionStatus} from '../../../config/utils'
import {StaffForgotPassword} from './components/staff/ForgotPassword'
import {StaffResetPassword} from './components/staff/ResetPassword'

export function AuthPage() {
  let permissionStatus = localStorage.getItem('data') as string
  let permissionVal = 0
  if (permissionStatus) {
    permissionVal = decryptPermissionStatus(permissionStatus)
  }
  let hashUserInfoFlag = parseInt(localStorage.getItem('hashUserFlag') as string)

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.svg')} className='mb-12 h-80px' />
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {permissionVal === 0 && hashUserInfoFlag === 0 ? (
          <Switch>
            <Route path='/auth/resetuserinfo' component={ResetUserInfo} />

            <Redirect from='/auth' exact={true} to='/auth/resetuserinfo' />
            <Redirect to='/auth/resetuserinfo' />
          </Switch>
        ) : (
          <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <Switch>
              <Route path='/auth/login' component={Login} />
              {/* <Route path='/auth/registration' component={Registration} /> */}
              <Route path='/auth/forgotpassword' component={ForgotPassword} />
              <Route path='/s/reset-password' component={ResetPassword} />
              <Route path='/auth/deactivate' component={Deactivate} />
              <Route path='/staff/login' component={StaffLogin} />
              <Route path='/staff/forgotpassword' component={StaffForgotPassword} />
              <Route path='/staff/resetpassword' component={StaffResetPassword} />
              <Route path='/staff/deactivateStaff/:username' component={StaffDeactivate} />

              <Redirect from='/auth' exact={true} to='/auth/login' />
              <Redirect to='/auth/login' />
            </Switch>
          </div>
        )}
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}
