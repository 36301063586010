export const TimezoneValue = [
  'International Date Line West',
  'Midway Island',
  'Samoa',
  'Hawaii',
  'Alaska',
  'Pacific Time (US &amp; Canada)',
  'Tijuana',
  'Arizona',
  'Mountain Time (US &amp; Canada)',
  'Chihuahua',
  'Mazatlan',
  'Saskatchewan',
  'Central America',
  'Central Time (US &amp; Canada)',
  'Guadalajara',
  'Mexico City',
  'Monterrey',
  'Bogota',
  'Lima',
  'Quito',
  'Eastern Time (US &amp; Canada)',
  'Indiana (East)',
  'Caracas',
  'La Paz',
  'Georgetown',
  'Atlantic Time (Canada)',
  'Santiago',
  'Brasilia',
  'Buenos Aires',
  'Newfoundland',
  'Greenland',
  'Mid-Atlantic',
  'Cape Verde Is.',
  'Azores',
  'Monrovia',
  'UTC',
  'Dublin',
  'Edinburgh',
  'Lisbon',
  'London',
  'Casablanca',
  'West Central Africa',
  'Belgrade',
  'Bratislava',
  'Budapest',
  'Ljubljana',
  'Prague',
  'Sarajevo',
  'Skopje',
  'Warsaw',
  'Zagreb',
  'Brussels',
  'Copenhagen',
  'Madrid',
  'Paris',
  'Amsterdam',
  'Berlin',
  'Bern',
  'Rome',
  'duotone',
  'Vienna',
  'Cairo',
  'Harare',
  'Pretoria',
  'Bucharest',
  'Helsinki',
  'Kiev',
  'Kyiv',
  'Riga',
  'Sofia',
  'Tallinn',
  'Vilnius',
  'Athens',
  'Istanbul',
  'Minsk',
  'Jerusalem',
  'Moscow',
  'St. Petersburg',
  'Volgograd',
  'Kuwait',
  'Riyadh',
  'Nairobi',
  'Baghdad',
  'Abu Dhabi',
  'Muscat',
  'Baku',
  'Tbilisi',
  'Yerevan',
  'Tehran',
  'Kabul',
  'Ekaterinburg',
  'Islamabad',
  'Karachi',
  'Tashkent',
  'Chennai',
  'Kolkata',
  'Mumbai',
  'New Delhi',
  'Sri Jayawardenepura',
  'Kathmandu',
  'Astana',
  'Dhaka',
  'Almaty',
  'Urumqi',
  'Rangoon',
  'Novosibirsk',
  'Bangkok',
  'Hanoi',
  'Jakarta',
  'Krasnoyarsk',
  'Beijing',
  'Chongqing',
  'Hong Kong',
  'Kuala Lumpur',
  'Singapore',
  'Taipei',
  'Perth',
  'Irkutsk',
  'Ulaan Bataar',
  'Seoul',
  'Osaka',
  'Sapporo',
  'Tokyo',
  'Yakutsk',
  'Darwin',
  'Adelaide',
  'Canberra',
  'Melbourne',
  'Sydney',
  'Brisbane',
  'Hobart',
  'Vladivostok',
  'Guam',
  'Port Moresby',
  'Solomon Is.',
  'Magadan',
  'New Caledonia',
  'Fiji',
  'Kamchatka',
  'Marshall Is.',
  'Auckland',
  'Wellington',
  "Nuku'alofa",
]

export const Timezone = [
  '(GMT-11:00) International Date Line West',
  '(GMT-11:00) Midway Island',
  '(GMT-11:00) Samoa',
  '(GMT-10:00) Hawaii',
  '(GMT-08:00) Alaska',
  '(GMT-07:00) Pacific Time (US &amp; Canada)',
  '(GMT-07:00) Tijuana',
  '(GMT-07:00) Arizona',
  '(GMT-06:00) Mountain Time (US &amp; Canada)',
  '(GMT-06:00) Chihuahua',
  '(GMT-06:00) Mazatlan',
  '(GMT-06:00) Saskatchewan',
  '(GMT-06:00) Central America',
  '(GMT-05:00) Central Time (US &amp; Canada)',
  '(GMT-05:00) Guadalajara',
  '(GMT-05:00) Mexico City',
  '(GMT-05:00) Monterrey',
  '(GMT-05:00) Bogota',
  '(GMT-05:00) Lima',
  '(GMT-05:00) Quito',
  '(GMT-04:00) Eastern Time (US &amp; Canada)',
  '(GMT-04:00) Indiana (East)',
  '(GMT-04:00) Caracas',
  '(GMT-04:00) La Paz',
  '(GMT-04:00) Georgetown',
  '(GMT-03:00) Atlantic Time (Canada)',
  '(GMT-03:00) Santiago',
  '(GMT-03:00) Brasilia',
  '(GMT-03:00) Buenos Aires',
  '(GMT-02:30) Newfoundland',
  '(GMT-02:00) Greenland',
  '(GMT-02:00) Mid-Atlantic',
  '(GMT-01:00) Cape Verde Is.',
  '(GMT) Azores',
  '(GMT) Monrovia',
  '(GMT) UTC',
  '(GMT+01:00) Dublin',
  '(GMT+01:00) Edinburgh',
  '(GMT+01:00) Lisbon',
  '(GMT+01:00) London',
  '(GMT+01:00) Casablanca',
  '(GMT+01:00) West Central Africa',
  '(GMT+02:00) Belgrade',
  '(GMT+02:00) Bratislava',
  '(GMT+02:00) Budapest',
  '(GMT+02:00) Ljubljana',
  '(GMT+02:00) Prague',
  '(GMT+02:00) Sarajevo',
  '(GMT+02:00) Skopje',
  '(GMT+02:00) Warsaw',
  '(GMT+02:00) Zagreb',
  '(GMT+02:00) Brussels',
  '(GMT+02:00) Copenhagen',
  '(GMT+02:00) Madrid',
  '(GMT+02:00) Paris',
  '(GMT+02:00) Amsterdam',
  '(GMT+02:00) Berlin',
  '(GMT+02:00) Bern',
  '(GMT+02:00) Rome',
  '(GMT+02:00) duotone',
  '(GMT+02:00) Vienna',
  '(GMT+02:00) Cairo',
  '(GMT+02:00) Harare',
  '(GMT+02:00) Pretoria',
  '(GMT+03:00) Bucharest',
  '(GMT+03:00) Helsinki',
  '(GMT+03:00) Kiev',
  '(GMT+03:00) Kyiv',
  '(GMT+03:00) Riga',
  '(GMT+03:00) Sofia',
  '(GMT+03:00) Tallinn',
  '(GMT+03:00) Vilnius',
  '(GMT+03:00) Athens',
  '(GMT+03:00) Istanbul',
  '(GMT+03:00) Minsk',
  '(GMT+03:00) Jerusalem',
  '(GMT+03:00) Moscow',
  '(GMT+03:00) St. Petersburg',
  '(GMT+03:00) Volgograd',
  '(GMT+03:00) Kuwait',
  '(GMT+03:00) Riyadh',
  '(GMT+03:00) Nairobi',
  '(GMT+03:00) Baghdad',
  '(GMT+04:00) Abu Dhabi',
  '(GMT+04:00) Muscat',
  '(GMT+04:00) Baku',
  '(GMT+04:00) Tbilisi',
  '(GMT+04:00) Yerevan',
  '(GMT+04:30) Tehran',
  '(GMT+04:30) Kabul',
  '(GMT+05:00) Ekaterinburg',
  '(GMT+05:00) Islamabad',
  '(GMT+05:00) Karachi',
  '(GMT+05:00) Tashkent',
  '(GMT+05:30) Chennai',
  '(GMT+05:30) Kolkata',
  '(GMT+05:30) Mumbai',
  '(GMT+05:30) New Delhi',
  '(GMT+05:30) Sri Jayawardenepura',
  '(GMT+05:45) Kathmandu',
  '(GMT+06:00) Astana',
  '(GMT+06:00) Dhaka',
  '(GMT+06:00) Almaty',
  '(GMT+06:00) Urumqi',
  '(GMT+06:30) Rangoon',
  'GMT+07:00) Novosibirsk',
  '(GMT+07:00) Bangkok',
  '(GMT+07:00) Hanoi',
  '(GMT+07:00) Jakarta',
  '(GMT+07:00) Krasnoyarsk',
  '(GMT+08:00) Beijing',
  '(GMT+08:00) Chongqing',
  '(GMT+08:00) Hong Kong',
  '(GMT+08:00) Kuala Lumpur',
  '(GMT+08:00) Singapore',
  '(GMT+08:00) Taipei',
  '(GMT+08:00) Perth',
  '(GMT+08:00) Irkutsk',
  '(GMT+08:00) Ulaan Bataar',
  '(GMT+09:00) Seoul',
  '(GMT+09:00) Osaka',
  '(GMT+09:00) Sapporo',
  '(GMT+09:00) Tokyo',
  '(GMT+09:00) Yakutsk',
  '(GMT+09:30) Darwin',
  '(GMT+09:30) Adelaide',
  '(GMT+10:00) Canberra',
  '(GMT+10:00) Melbourne',
  '(GMT+10:00) Sydney',
  '(GMT+10:00) Brisbane',
  '(GMT+10:00) Hobart',
  '(GMT+10:00) Vladivostok',
  '(GMT+10:00) Guam',
  '(GMT+10:00) Port Moresby',
  '(GMT+10:00) Solomon Is.',
  '(GMT+11:00) Magadan',
  '(GMT+11:00) New Caledonia',
  '(GMT+12:00) Fiji',
  '(GMT+12:00) Kamchatka',
  '(GMT+12:00) Marshall Is.',
  '(GMT+12:00) Auckland',
  '(GMT+12:00) Wellington',
  "(GMT+13:00) Nuku'alofa",
]
