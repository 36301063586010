import { BACKEND_API_V2 } from "../../../../../config/config"


export async function getPaymentSettings(
) {
  const data = await fetch(BACKEND_API_V2 + '/webshop/settings', {
    method: 'GET',
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      console.log(err)
    })
  return data
}

export async function getCountries(
) {
  const data = await fetch(BACKEND_API_V2 + '/webshop/operating_countries', {
    method: 'GET',
  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      console.log(err)
    })
  return data
}

export async function getPlans(
  type: string,
  name: string,

) {
  
  const data = await fetch(BACKEND_API_V2 + '/webshop/plans/' + type, {
    method: 'GET',
    headers: {
      'x-access-token': `${localStorage.getItem('token')}`,
    }  })
    .then((res) => {
      return res.json()
    })
    .catch((err) => {
      console.log(err)
    })
  return data
}

export async function getBillingAddress() {
  const data = await fetch(BACKEND_API_V2 + '/webshop/user/address', {
    method: 'get',
    headers: {
      'x-access-token': `${localStorage.getItem('token')}`,
    }
  }
  ).then((res) => {
    return res.json()
  }
  ).catch((err) => {
    console.log(err)
  }
  )
  if (!(data?.address.find((item: any) => item.useStatus === 1))) {
    data.address[0].useStatus = 1
  }
  return data?.address
}

export async function getCommissionWalletBalance() {
  const data = await fetch(BACKEND_API_V2 + '/user/commission_wallet/balance', {
    method: 'get',
    headers: {
      'x-access-token': `${localStorage.getItem('token')}`,
    }
  }
  ).then((res) => {
    return res.json()
  }
  ).catch((err) => {
    console.log(err)
  }
  )
  return data?.balance || 0
}
export async function getExpiration(): Promise<{ expiration_date: number, extra_months: number }> {
  const data = await fetch(BACKEND_API_V2 + '/webshop/user/expiration', {
    method: 'get',
    headers: {
      'x-access-token': `${localStorage.getItem('token')}`,
    }
  }
  ).then((res) => {
    return res.json()
  }
  ).catch((err) => {
    console.log(err)
  }
  )
  return data || { expiration_date: 0, extra_months: 0 }
}